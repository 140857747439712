import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output, SimpleChanges, ViewChild } from '@angular/core';
import { MatPaginator, MatPaginatorModule } from '@angular/material/paginator';
import { MatSort, MatSortModule, SortDirection } from '@angular/material/sort';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatCardModule } from '@angular/material/card';
import {MatIconModule} from '@angular/material/icon';
import { MatButtonModule } from "@angular/material/button";
import { MatDialog } from '@angular/material/dialog';
import { MatTooltipModule } from '@angular/material/tooltip';
import { ActivatedRoute, Router } from '@angular/router';
import { actions, actionTypes, themeType } from '../models/actions.model';
import { IInfiniteScrollEvent, InfiniteScrollModule } from 'ngx-infinite-scroll';

@Component({
  selector: 'app-table',
  standalone: true,
  imports: [CommonModule, MatTableModule, MatPaginatorModule, MatSortModule, MatProgressSpinnerModule, MatCardModule, MatIconModule, MatButtonModule, MatTooltipModule, InfiniteScrollModule],
  templateUrl: './table.component.html',
  styleUrl: './table.component.scss'
})

export class TableComponent {
   @Input() tableData: any[] = [];
   @Input() displayedColumns: string[] = [];
   @Input() dataColumns: string[] = [];
   @Input() disableSort: boolean = false;
   @Input() sortField: string = '';
   @Input() sortDirection: SortDirection = '';
   @Input() displayActions: boolean = false;
   @Input() actionsList: actions[] = [];

   dataColumnsWithAction: string[] = [];
 
   dataSource = new MatTableDataSource<any>;
 
   @Input() isPageLoading: boolean = false;
   @Input() isNextPageLoading: boolean = false;
   @Output() isPageLoadingChange: EventEmitter<boolean> = new EventEmitter<boolean>();
   @Output() deleteIconClicked: EventEmitter<any> = new EventEmitter<any>();
   @Output() editIconClicked: EventEmitter<any> = new EventEmitter<any>();

   @Input() onScrollEvent: CallableFunction;
   @Input() infiniteScrollDistance: number;
   @Input() infiniteScrollThrottle: number;
 
   // Bindings
   setPageLoadingState(isPageLoading: boolean): void {
     this.isPageLoading = isPageLoading;
     this.isPageLoadingChange.emit(this.isPageLoading);
   }
       
   // Sort and Pagintor are set in Angular's view query system and thus must be declared optional here. They are not created on constuction.
   @ViewChild(MatPaginator, {static: false}) paginator: MatPaginator  | undefined;
   @ViewChild(MatSort, {static: false}) sort: MatSort | undefined;
 
   ngOnChanges(changes: SimpleChanges): void {
     if(changes['tableData'])
     {
      if (this.displayActions) {
        this.dataColumnsWithAction = ['actions', ...this.dataColumns];
      }
       this.UpdateDataSource();
     }
   }
 
   ngOnInit() {
     if (this.displayActions && !this.actionsList) {
       this.dataColumnsWithAction = ['actions', ...this.dataColumns];
     }
   }
 
   ngAfterViewInit(){
     this.UpdateDataSource();
   }
 
   UpdateDataSource(){
     this.dataSource = new MatTableDataSource(this.tableData);
     if(this.paginator){
       this.dataSource.paginator = this.paginator;
     }
 
     if(this.sort){
       // If sorting fails, make sure you are not removing the table from the DOM via ngIf or similar. Use [hidden] instead.
       // https://github.com/angular/components/issues/15888
       // For the initial sort, this needs to be set first.
       this.dataSource.sortingDataAccessor = (item: any, property) => {
         if (!isNaN(Date.parse(item[property])))
         {
           return new Date(item[property]);
         }
         else {
           return item[property];
         }
       }
       this.dataSource.sort = this.sort;
     }
   }

  returnActionDetails(type: actionTypes, row: any) {
    switch(type) {
      case actionTypes.add:
      case actionTypes.edit: {
        this.editIconClicked.emit(row);
        break;
      }
      case actionTypes.delete: {
        this.deleteIconClicked.emit(row);
        break;
      }
      default: {
        break;
      }
    }
  }

   getIconColorClass(icon: themeType): string {
    switch(icon) {
      case themeType.success: {
        return "table-icon-color-success";
      }
      case themeType.error: {
        return "table-icon-color-error";
      }
      default: {
        return "table-icon-color-primary";
      }
    }
   } 
}