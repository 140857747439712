<ng-container *ngIf="useIcon; else fallback">
    <!-- <img [src]="iconUrl" (error)="loadOffering()" alt="icon" class="company-icon" /> -->
     <div class="company-icon" (error)="loadOffering()" onerror="loadOffering()" [ngStyle]="{
        '-webkit-mask': 'url(' + iconData + ') no-repeat center/contain',
        'mask': 'url(' + iconData + ') no-repeat center/contain'}"></div>
</ng-container>
<ng-template #fallback>
    <div class="company-avatar-wrapper">
        <div class="avatar company-avatar">
            <div *ngIf="loaded" [matTooltip]="name">{{this.initials}}</div>
            <mat-spinner [diameter]="20" id="initials-spinner" *ngIf="!loaded"></mat-spinner>
        </div>
    </div>
</ng-template>
