import { Component, ElementRef, Inject, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MAT_DIALOG_DATA, MatDialogActions, MatDialogContent, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { flowDefinitionSetting } from '../../Pages/jobs/models/flowDefinitionSetting';
import { CommonModule } from '@angular/common';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatCardModule } from '@angular/material/card';
import { MatFormFieldModule } from '@angular/material/form-field';
import { FormBuilder, FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { ShowRecordDialogData } from './models/ShowRecordDialogData';
import { MIPNotificationType } from '../Notifications/mip-notification-type.enum';
import { constants } from '../constants';
import { NotificationsService } from '../Notifications/notifications.service';
import { actionTypes } from '../models/actions.model';
import { ConfirmationDialogComponent } from "../confirmation-dialog/confirmation-dialog.component";

@Component({
    selector: 'tablesettings-record-dialog',
    standalone: true,
    imports: [CommonModule, MatCardModule, MatDialogActions, MatDialogContent, MatDialogModule, MatButtonModule, MatIconModule, MatProgressSpinnerModule, MatTooltipModule, MatFormFieldModule, FormsModule, ReactiveFormsModule, MatInputModule, MatSelectModule, ConfirmationDialogComponent],
    templateUrl: './tablesettings-record-dialog.component.html',
    styleUrl: './tablesettings-record-dialog.component.scss'
})

export class TableSettingsRecordComponent {
    tableSettingForm: FormGroup = this.fb.group({});
    selectedTableSettingColumns: flowDefinitionSetting[] = [];
    selectedTableSettingData: any;
    updatedTableSettingData: any;
    title: string = "";
    successMessage: string = "";
    errorMessage: string = "";
    onSaveAction: CallableFunction;
    submittingForm: boolean = false;
    actionType: actionTypes;
    actionTypes = actionTypes;
    @ViewChild('dialogContent') dialogContent!: ElementRef;
    @ViewChildren('inputContainers') inputContainers!: QueryList<ElementRef>;

    constructor(
        @Inject(MAT_DIALOG_DATA)
        public data: ShowRecordDialogData,
        public dialogRef: MatDialogRef<TableSettingsRecordComponent>,
        private fb: FormBuilder,
        private uiNotificationService: NotificationsService
    ) {
        if (data) {
            this.selectedTableSettingColumns = data.columns;
            this.title = data.title;
            this.selectedTableSettingData = data.data;
            this.onSaveAction = data.onSaveAction;
            this.actionType = data.actionType;
            this.successMessage = data.successMessage ?? constants.common.updated;
            this.errorMessage = data.errorMessage ?? constants.common.error;
        }
    }

    ngOnInit(): void {
        //clear forms for new data
        this.tableSettingForm.clearValidators();
        this.tableSettingForm.reset();

        this.selectedTableSettingColumns.forEach((column) => {
            const validators = column.required ? [Validators.required] : [];
            if (this.selectedTableSettingData) {
                var fieldValue = this.selectedTableSettingData[column.settingName];
                this.tableSettingForm.addControl(column.settingName, new FormControl(fieldValue, validators));
            }
            else {
                this.tableSettingForm.addControl(column.settingName, new FormControl('', validators));
            }
        });
    }

    ngAfterViewInit(): void {
        const dialogContentElement = this.dialogContent.nativeElement;
        if (dialogContentElement.scrollHeight > dialogContentElement.clientHeight) {
            dialogContentElement.classList.add('has-scrollbar');
            
            this.inputContainers.forEach((container: ElementRef) => {
                const inputContainerElement = container.nativeElement;
                inputContainerElement.classList.add('scroll-padding');
            });
        }
    }

    cancel(): void {
        this.dialogRef.close();
    }

    async onSubmit(): Promise<void> {
        var success = false;
        this.submittingForm = true;

        if (!this.tableSettingForm.valid) {
            return;
        }

        this.tableSettingForm.disable();
        this.updatedTableSettingData = { ...this.selectedTableSettingData, ...this.tableSettingForm.value };
        success = await this.onSaveAction(this.updatedTableSettingData);

        this.tableSettingForm.enable();
        if (success) {
            this.uiNotificationService.queueNotification({
                type: MIPNotificationType.Success,
                title: constants.common.updated,
                message: this.successMessage
            });

            this.dialogRef.close();
        }

        this.submittingForm = false;
    }

    cannotSubmit = (): boolean => {
        return !this.tableSettingForm || this.tableSettingForm.invalid || this.submittingForm;
    }

}
