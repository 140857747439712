export interface actions {
    name: string,
    type: actionTypes,
    icon: string,
    themeType: themeType
}

export class actions {
    constructor (public name: string, public type: actionTypes, public icon: string, public themeType: themeType)
     {}
}

export enum themeType {
    primary, success, error
}

export enum actionTypes {
    add, edit, delete
}