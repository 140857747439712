import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatGridListModule } from '@angular/material/grid-list';
import { TenantAPIService } from '../../Services/api.tenantApi.service';
import { transferType } from './models/transferType.model';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatSelectModule } from '@angular/material/select';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatIconModule } from '@angular/material/icon';
import { Observable, Subject } from 'rxjs';
import { initiateTransferResponse } from './models/initiateTransferResponse.model';
import { S3ApiService } from '../../Services/s3ApiService';
import { JobInfoCardComponent } from '../jobs/job-info-card/job-info-card.component';
import { processTransferResult } from './models/processTransferResult.model';
import { MatDividerModule } from '@angular/material/divider';
import { cancelTransferResponse } from './models/cancelTransferResponse.model';

@Component({
    selector: 'app-transfer',
    standalone: true,
    imports: [CommonModule, MatCardModule, MatGridListModule, MatSelectModule, MatFormFieldModule, MatButtonModule, MatProgressSpinnerModule, MatIconModule, JobInfoCardComponent, MatDividerModule],
    templateUrl: './transfer.component.html',
    styleUrl: './transfer.component.scss',
})
export class TransferComponent {
    transferTypesLoaded: boolean = false;
    transferTypes: transferType[] = [];
    selectedTransferType: transferType | null = null;
    fileName: string = "";
    selectedFile: File | null = null;
    activeTransferJobId: string | null = null;
    transferProcessing: boolean = false;
    noItemSelectedText: string = "No Transfer Selected";
    noItemSelectedMessage: string = "Once you start the Transfer, the Job ID and status will display here";
    activeJobEvent: Subject<string> = new Subject<string>();

    constructor(private tenantAPI: TenantAPIService, private s3APIService: S3ApiService) { }

    ngOnInit() {
        this.tenantAPI.getTransferTypes().subscribe({
            next: (data: transferType[]) => {
                this.transferTypes = data;
            },
            error: (result: any) => { console.log(result) },
            complete: () => this.transferTypesLoaded = true
        });
    }

    onFileSelected = (event: any): void => {

        this.selectedFile = event.target.files[0];

        if (this.selectedFile) {

            this.fileName = this.selectedFile.name;
            const formData = new FormData();
            formData.append("file", this.selectedFile);
        }
    }

    canSubmit = (): boolean => {
        return this.selectedTransferType != null && this.selectedTransferType !== undefined
            && this.selectedFile !== null && this.selectedFile !== undefined
            && !this.transferProcessing;
    }

    uploadFile = (): void => {
        const fileUpload$ = this.tenantAPI.intitateTransfer;
        const uploadToS3$ = this.s3APIService.uploadFileToS3;
        const processTransfer$ = this.tenantAPI.processTransfer;

        const formData = new FormData();
        if (this.selectedFile && this.selectedTransferType) {
            formData.append("file", this.selectedFile);
            this.transferProcessing = true;
            fileUpload$(this.selectedTransferType?.transferCode, this.selectedFile.name, this.selectedFile.type).subscribe({
                next: (response: initiateTransferResponse) => {
                    this.activeTransferJobId = response.jobId;
                    this.activeJobEvent.next(this.activeTransferJobId);
                    uploadToS3$(response.uploadUrl, this.selectedFile!).subscribe({
                        next: () => {
                            processTransfer$(this.activeTransferJobId!).subscribe({
                                next: (result: processTransferResult) => {
                                    this.transferCompleted();
                                },
                                error: this.transferErrored,
                                complete: this.transferCompleted
                            })
                        },
                        error: this.transferErrored,

                    },
                    );
                }, error: this.transferErrored
            })
        }
        else {
            console.log("Unable to upload file");
        }
    }

    transferErrored = () => {
        console.log("Transfer Errored");

        if (!this.activeTransferJobId) {
            this.transferCompleted();
            return;
        }

        console.log("Cancelling Transfer ", this.activeTransferJobId);

        const cancelTransfer$ = this.tenantAPI.cancelTransfer;

        cancelTransfer$(this.activeTransferJobId!).subscribe({
            next: (result: cancelTransferResponse) => {
                console.log("Cancelled Transfer ", result.jobId);
                this.transferCompleted();
            },
            error: () => {
                console.log("Unable to cancel transfer");
                this.transferCompleted;
            },
            complete: this.transferCompleted
        })
    }
    transferCompleted = () => {
        console.log("Transfer Completed");
        this.transferProcessing = false;
    }
    getActiveJobId = () => {
        return this.activeTransferJobId ?? "";
    }
}
