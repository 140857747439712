<div class="mat-elevation-z8">
    <mat-card>
        <mat-card-content>
            <div *ngIf="isLoading"
                style="display: flex; justify-content: center; align-items: center; background: white;">
                <mat-progress-spinner color="primary" mode="indeterminate">
                </mat-progress-spinner>
            </div>
            <mat-table [ngClass]="{'hide-element': isLoading}" [dataSource]="dataSource" matSort>

                <ng-container matColumnDef="actions">
                    <mat-header-cell *matHeaderCellDef>Actions</mat-header-cell>
                    <mat-cell *matCellDef="let element">
                        <mat-icon class="showDetailsIcon" aria-hidden="false" aria-label="Show Details" 
                            matTooltip="Job Item Details" fontIcon="open_in_new" (click)="showJobItemErrorDetails(element)"></mat-icon>
                    </mat-cell>
                </ng-container>
                
                <ng-container *ngFor="let column of dataColumns; index as i" [matColumnDef]="column">
                    <mat-header-cell *matHeaderCellDef mat-sort-header> {{ displayedColumns[i] ?? column }} </mat-header-cell>
                    <mat-cell *matCellDef="let element"> {{element[column]}} </mat-cell>
                </ng-container>

                <mat-header-row *matHeaderRowDef="displayActions ? dataColumnsWithAction : dataColumns"></mat-header-row>
                <mat-row *matRowDef="let row; columns: displayActions ? dataColumnsWithAction : dataColumns;"></mat-row>
            </mat-table>
            <mat-paginator [ngClass]="{'hide-element': isLoading}" [pageSize]="20" [pageSizeOptions]="[5, 10, 20]"
                [showFirstLastButtons]="true"></mat-paginator>
        </mat-card-content>
    </mat-card>
</div>