import { Component } from '@angular/core';
import { CognitoService } from '../../Services/cognito.service';
import { CommonModule } from '@angular/common';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

@Component({
  selector: 'app-account-avatar',
  standalone: true,
  imports: [CommonModule, MatTooltipModule, MatProgressSpinnerModule],
  templateUrl: './account-avatar.component.html',
  styleUrl: './account-avatar.component.scss'
})
export class AccountAvatarComponent{
  initials: string = "";
  public userInfoLoaded: boolean = false;

  constructor(
      private cognitoService: CognitoService,
  ){}

  async ngAfterViewInit(){
    this.initials = await this.getFirstAndLastNameIntialOrFirstUsernameInitial();
  }

  async getFirstAndLastNameIntialOrFirstUsernameInitial(): Promise<string> {
      try {
        const { name, family_name } = await this.cognitoService.getUserAttributes();

        if(name == undefined && family_name == undefined){
          this.userInfoLoaded = true;
          return this.getCurrentUserName().charAt(0).toUpperCase();
        }
  
        const firstNameInitial = name?.charAt(0) || '';
        const lastNameInitial = family_name?.charAt(0) || '';
        const initials = `${firstNameInitial}${lastNameInitial}`;
        
        // Set the info loaded to true for the spinner.
        this.userInfoLoaded = true;

        return initials;
      } catch (error) {
        console.error('Error fetching user attributes:', error);
        throw error;
      }
    }

  getCurrentUserName(): string {
      return this.cognitoService.currentUserName;
  }
}