import { HttpErrorResponse, HttpEvent, HttpHandlerFn, HttpInterceptorFn, HttpRequest, HttpResponse } from '@angular/common/http';
import { MIPNotification, NotificationsService } from '../Shared/Notifications/notifications.service';

import { EMPTY, Observable, catchError, tap, throwError } from 'rxjs';
import { MIPNotificationType } from '../Shared/Notifications/mip-notification-type.enum';
import { inject } from '@angular/core';
import { CognitoService } from '../Services/cognito.service';

interface ProblemDetails {
  title: string;
  detail: string;
  type: string;
  status: number;
  extendedInfo: { [key: string]: any };
}

export const httpResponseNotificationInteceptor: HttpInterceptorFn = (req: HttpRequest<any>, next: HttpHandlerFn): Observable<HttpEvent<any>> => {
  const notificationsService = inject(NotificationsService);
  const cognitoService = inject(CognitoService);
  //console.log("HTTP Response Notification Interceptor Triggered.");
  return next(req).pipe(
    tap(event => { next:(req:any)=>{
      if(event instanceof HttpResponse) {
        if(event.status === 200 || event.status === 201){
          // TODO Discuss how we want to handle successes.
          // notificationsService.queueNotification(
          //   {
          //     type: MIPNotificationType.Success,
          //     message: 'Operation Successful.'
          //   });
        }
      }
    }}
  ),
    catchError((error: HttpErrorResponse) => {
      let problemDetails: ProblemDetails | null = null;
      if (error.status === 401 || error.status === 401 ){
        //assume our token is bad and logout
        cognitoService.handleSignOut();
        return EMPTY;
      }
      try{
        problemDetails = error.error as ProblemDetails;
      }
      catch (e) {
        console.error("Cannot parse response as ProblemDetails.");
      }
      const title = problemDetails?.title ?? "System Error";
      let errorMessage = problemDetails?.detail || error?.error?.message || "An unknown error has occurred";
      
      notificationsService.queueNotification({
        type: MIPNotificationType.Error,
        title: title ?? "System Error",
        message: errorMessage ?? "A system error has occurred",
        jsonDetails: problemDetails?.extendedInfo
      } as MIPNotification);

      return throwError(() => new Error(errorMessage));
    })
  );
};
