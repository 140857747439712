import { Injectable } from "@angular/core";
import { MipHttpService } from "./api.mipHttpService";
import { CognitoService } from "./cognito.service";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { flowDefinitionSetting } from "../Pages/jobs/models/flowDefinitionSetting";
import { createUserDefinedScheduleRequest, updateUserDefinedScheduleRequest } from "../Pages/payroll-schedule/models/createUserDefinedSchedule";

@Injectable({
    providedIn: 'root',
})
export class FlowSettingsService extends MipHttpService {
    constructor(cognitoService: CognitoService, httpClient: HttpClient) {
        super(cognitoService, httpClient);
    }

    public getRuntimeFlowDefinitionSettings(flowCode: string, version: number | null = null, type: string = "job"): Observable<flowDefinitionSetting[]> {
        return this.get<flowDefinitionSetting[]>(`flowdefinition/job/${flowCode}/version/${version}`, { 'settingType': 'Runtime' });
    }

    public getStepFlowDefinitionSettings(flowCode: string, version: number | null = null, type: string = "job"): Observable<flowDefinitionSetting[]> {
        return this.get<flowDefinitionSetting[]>(`flowdefinition/job/${flowCode}/version/${version}`, { 'settingType': 'Step' });
    }

    public getTenantFlowDefinitionSettings(flowCode: string, version: number | null = null, type: string = "job"): Observable<flowDefinitionSetting[]> {
        return this.get<flowDefinitionSetting[]>(`flowdefinition/job/${flowCode}/version/${version}`, { 'settingType': 'Tenant' });
    }

    public getFlowSchedule(type: string, code: string) {
        return this.get<createUserDefinedScheduleRequest[]>(`flow/${type}/${code}/schedules`);
    }

    public createFlowSchedule(type: string, code: string, requestData: createUserDefinedScheduleRequest): Observable<void> {
        return this.post(`flow/${type}/${code}/schedule`, JSON.stringify(requestData));
    }

    public updateFlowSchedule(type: string, code: string, scheduleName: string, requestData: updateUserDefinedScheduleRequest): Observable<void> {
        return this.post(`flow/${type}/${code}/schedule/${scheduleName}`, JSON.stringify(requestData));
    }
}