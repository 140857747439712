<div class="app-dialog">
    <div class="app-dialog-content">
        <h2 mat-dialog-title>{{ data.title }}</h2>
        <mat-dialog-content>
            <p>{{ data.message }}</p>
        </mat-dialog-content>
    </div>
    <div class="app-dialog-jsonDetails">
        <mat-card>
            <mat-card-content>
                <mat-accordion *ngIf="data.jsonDetails" multi>
                    <mat-expansion-panel>
                        <mat-expansion-panel-header>
                            <mat-panel-title>Additional Details</mat-panel-title>
                        </mat-expansion-panel-header>
                        <pre>{{ data.jsonDetails | json }}</pre>
                    </mat-expansion-panel>
                </mat-accordion>
            </mat-card-content>
        </mat-card>
    </div>
</div>