import { Component } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { ConfirmationDialogComponent } from '../../Shared/confirmation-dialog/confirmation-dialog.component';
import { TenantAPIService } from '../../Services/api.tenantApi.service';
import { flow } from '../../Models/Class-Interfaces/flow.model';
import { MatDialog } from '@angular/material/dialog';
import { MatTabsModule } from '@angular/material/tabs';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { CommonModule } from '@angular/common';
import { TransferComponent } from '../transfer/transfer.component';
import { EmailNotificationSettingsComponent } from '../email-notification-settings/email-notification-settings.component';
import { tenantFlowResult } from './models/integration-models';
import { JobsComponent } from '../jobs/jobs.component';
import { TenantConfigurationComponent } from '../tenant-configuration/tenant-configuration.component';
import { TableSettingsComponent } from '../table-settings/table-settings.component';
import { PayrollScheduleComponent } from '../payroll-schedule/payroll-schedule.component';
import { tenantFlowSetting } from '../../Models/Class-Interfaces/tenantFlowSetting.model';
import { jobType } from '../jobs/models/jobtypes.model';

@Component({
  selector: 'app-integration',
  standalone: true,
  imports: [EmailNotificationSettingsComponent, CommonModule, JobsComponent, MatProgressSpinnerModule, MatTabsModule, MatSlideToggleModule, TransferComponent, TenantConfigurationComponent,
    TableSettingsComponent, PayrollScheduleComponent
   ],
  templateUrl: './integration.component.html',
  styleUrl: './integration.component.scss'
})
export class IntegrationComponent {
  flowCode: string | null = null;
  flowType: string = "Job";
  integration: tenantFlowResult | null = null;
  loading: boolean = false;
  flowNotFound: boolean = false;
  tenantFlowSettings: tenantFlowSetting[] = [];
  tenantFlowSettingsLoading: boolean = false;
  shouldShowScheduleTab: boolean = false;
  isEnabled: boolean = false;
  isEnabledChanging: boolean = false;
  tenantJobTypeLoading: boolean = false;
  constructor(
    private route: ActivatedRoute,
    private tenantAPIService: TenantAPIService,
    private dialog: MatDialog)
  { }

  ngOnInit() {
    this.route.params.subscribe((param: Params) => {
      this.flowCode = param['flowCode']; 
      this.loading = true;
      this.tenantFlowSettingsLoading = true;
      this.tenantJobTypeLoading = true;
      this.tenantAPIService.getFlowInstance(this.flowType, this.flowCode ?? "").subscribe({
        next: (result: tenantFlowResult) => {
          let flow: flow = { name: result.name, code: result.flowCode, version: result.version };
          this.integration = result ?? null;
          return flow;
        },
        error: (result: any) => { console.log(result) },
        complete: () => { this.loading = false; }
      });

      this.tenantAPIService.getTenantFlowSettings(this.flowCode ?? "").subscribe({
        next: (data: tenantFlowSetting[]) => {
          this.tenantFlowSettings = data;
          this.shouldShowScheduleTab = data.some(d => d.subType === "user-configurable-schedule");
        },
        error: (result: any) => { console.log(result) },
        complete: () => { this.tenantFlowSettingsLoading = false; }
      });

      this.tenantAPIService.getJobType(this.flowCode ?? "").subscribe({
        next: (result: jobType) => {
          this.isEnabled = result.isEnabled;
        },
        error: (result: any) => { console.log(result) },
        complete: () => { this.tenantJobTypeLoading = false; }
      });

    });
  }

  onToggleChange(event: any) {
    // Prevent the immediate toggle
    event.source.checked = this.isEnabled;

    if (this.loading || this.flowNotFound) {
      return;
    }

    if (this.isEnabled) {
      const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
        data: {
          title: 'Pause Configuration',
          subtitle: 'Are you sure you want to pause this configuration?',
          text: `Pausing will disable any configured schedules, prevent any new integration runs, and halt any in-progress runs.\n\nThis can be undone by re-connecting.`,
          width: '50%',
          height: 'auto',
          okText: 'Pause'
        }
      });
  
      dialogRef.afterClosed().subscribe(result => {
        if (result === true) {
          this.disableIntegration();
        }
      });
    }
    else {
      const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
        data: {
          title: 'Connect Configuration',
          subtitle: 'Are you sure you want to connect this configuration?',
          text: `Connecting will allow hte integration to run which will start any configured schedules and process any required updates to your system.`,
          width: '50%',
          height: 'auto',
          okText: 'Connect'
        }
      });
  
      dialogRef.afterClosed().subscribe(result => {
        if (result === true) {
          this.enableIntegration();
        }
      });
    }
  }

  disableIntegration() {
    this.isEnabledChanging = true;
    this.tenantAPIService.disable(this.flowType, this.flowCode ?? "").subscribe({
      error: (result: any) => { 
        console.log(result);
        this.isEnabledChanging = false;
      },
      complete: () => { 
        this.isEnabled = false;
        this.isEnabledChanging = false;
      }
      
    });
  }

  enableIntegration() {
    this.isEnabledChanging = true;
    this.tenantAPIService.enable(this.flowType, this.flowCode ?? "").subscribe({
      error: (result: any) => {
        console.log(result);
        this.isEnabledChanging = false;
      },
      complete: () => {
        this.isEnabled = true;
        this.isEnabledChanging = false;
      }
    });
  }

}
