import { Component, Input, SimpleChanges } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { OfferingApiService } from '../../Services/api.offering.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Component({
  selector: 'app-company-icon',
  standalone: true,
  imports: [CommonModule, MatTooltipModule, MatProgressSpinnerModule],
  templateUrl: './company-icon.component.html',
  styleUrl: './company-icon.component.scss'
})
export class CompanyIconComponent {
  @Input() offeringCode: string;

  iconUrl: string = "";
  iconData: string | null = null;
  useIcon: boolean = true;
  initials: string = "";
  name: string = "";
  public loaded: boolean = false;

  constructor(
    private http: HttpClient,
    private offeringApiService: OfferingApiService
  ) { }

  ngOnChanges(changes: SimpleChanges) {
    if (changes["offeringCode"]) {
      this.useIcon = true;
      this.iconUrl = `assets/images/offering/${this.offeringCode}.svg`;
      this.loadIcon();
    }
  }

  loadIcon() {
    this.loaded = false;
    var headers = new HttpHeaders();
    headers = headers.set('X-Suppress-Error-Dialog', 'true');
    this.http.get(this.iconUrl, { responseType: 'text', headers: headers}).subscribe({
      next: (svgContent: string) => {
        let modifiedSvg = svgContent;
        modifiedSvg = modifiedSvg.replace(/<g [^>]*>/g, '').replace(/<\/g>/g, '');
        
        // Replace any fill attributes to white
        modifiedSvg = modifiedSvg.replace(/fill="[^"]*"/g, 'fill="white"');
        // Optionally remove clipPath definitions if they’re causing issues
        modifiedSvg = modifiedSvg.replace(/<clipPath[^>]*>[\s\S]*?<\/clipPath>/g, '');
        // Create a data URL from the SVG content
        this.iconData =
          'data:image/svg+xml;charset=utf-8,' + encodeURIComponent(modifiedSvg);
        this.loaded = true;
        this.useIcon = true;
      },
      error: (err) => {
        console.error(`Error loading logo for '${this.offeringCode}':`, err);
        this.loadOffering();
      }
    });
  }

  loadOffering() {
    this.loaded = false;
    this.useIcon = false;
    this.initials = "?";
    this.name = "Unknown";
    this.offeringApiService.getTenantOfferingLink({ offeringCode: this.offeringCode })
      .subscribe({
        next: (result) => {
          this.initials = result.offeringName.substring(0, 1).toUpperCase();
          this.name = result.offeringName;
          this.loaded = true;
        },
        error: (error: any) => {
          this.initials = "?";
          this.name = "Unknown";
          this.loaded = true;
          console.error(`Error fetching Tenant Offering Link '${this.offeringCode}': ${error}`)
        }
      });
  }
}