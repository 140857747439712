export interface userDefinedSchedule {
    settingName: string;
    name: string;
    type: string;
    timeOfDay: string; 
    timeZone: string;
    repeatCadence: string;
    repeatCadenceType: string;
    daysOfWeek: string;
    enabled: boolean;
    detailType?: string;
    startDate?: Date;
}

export class updateUserDefinedScheduleRequest {
    constructor (public type: string, public timeOfDay: string, public timeZone: string,
        public repeatCadence: string, public repeatCadenceType: string, public daysOfWeek: string, public enabled: boolean, public detailType?: string,
        public startDate?: Date
    ) {}
}

export class createUserDefinedScheduleRequest {
    constructor (public settingName: string, public name: string, public type: string, public timeOfDay: string, public timeZone: string,
        public repeatCadence: string, public repeatCadenceType: string, public daysOfWeek: string, public enabled: boolean, public detailType?: string,
        public startDate?: Date
    ) {}
}

export enum scheduleTypesEnum {
    advanced = 'advanced',
    basic = 'basic'
}