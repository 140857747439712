<div class="app-confirmation-dialog">
    <div class="app-confirmation-dialog-content">
        <h2 mat-dialog-title>{{ title }}</h2>
        <mat-dialog-content>
            <p class="mip-mat-dialog-subtitle">{{subtitle}}</p>
            <p class="mip-mat-dialog-text">{{ text }}</p>
        </mat-dialog-content>
        <mat-dialog-actions>
            <button mat-button mat-dialog-close>{{cancelText}}</button>
            <button mat-flat-button color="primary" cdkFocusInitial (click)="accept()">{{okText}}</button>
        </mat-dialog-actions>
    </div>
</div>
