<div class="app-confirmation-dialog">
    <div class="app-confirmation-dialog-content">
        <div class="dialog-header">
            <h2 mat-dialog-title>{{ title }}</h2>
            <button mat-icon-button mat-dialog-close aria-label="Close" (click)="cancel()">
                <mat-icon class="disable-dialog-close-icon">close</mat-icon>
            </button>
        </div>
        <mat-dialog-content class="disable-dialog-content">
            <h3 class="disable-dialog-subtitle">
                {{subTitle}}
            </h3>
            <p>
                {{ revertSchedulingWarningMessage }}
                <br/>
                <br/>
                {{ saveSettingsReminderMessage }}
            </p>
        </mat-dialog-content>
        <mat-dialog-actions>
            <button mat-button (click)="cancel()">Cancel</button>
            <button mat-flat-button color="primary" cdkFocusInitial (click)="accept()">Disable</button>
        </mat-dialog-actions>
    </div>
</div>