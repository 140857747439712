import { Injectable } from "@angular/core";
import { MipHttpService } from "./api.mipHttpService";
import { CognitoService } from "./cognito.service";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { RecordAuditStartRequest } from "../Models/Class-Interfaces/RecordAuditStartRequest.model"
import { RecordAuditStartResponse } from "../Models/Class-Interfaces/RecordAuditStartResponse.model";
import { TenantRecordAuditGetResult } from "../Models/Class-Interfaces/TenantRecordAuditGetResult.model";


@Injectable({
    providedIn: 'root',
})
export class RecordAuditApiService extends MipHttpService {
    constructor(cognitoService: CognitoService, httpClient: HttpClient) {
        super(cognitoService, httpClient);
    }

    public start(request: RecordAuditStartRequest): Observable<RecordAuditStartResponse> {
        const requestJson = JSON.stringify(request);
        return this.post<RecordAuditStartResponse>('recordaudit/start', requestJson);
    }

    public getAuditsLinkedToFlow(type: string, code: string): Observable<TenantRecordAuditGetResult[]> {
        return this.get<TenantRecordAuditGetResult[]>(`flow/${type}/${code}/audits`);
    }
}