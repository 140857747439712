import { Injectable } from "@angular/core";
import { EmailSettingsService } from "./email-settings.service";
import { constants } from "../Shared/constants";
import { MatDialog } from "@angular/material/dialog";
import { Observable } from "rxjs";
import { EmailNotificationService } from "./api.emailNotification.service";
import { NotificationsService } from "../Shared/Notifications/notifications.service";
import { MIPNotificationType } from "../Shared/Notifications/mip-notification-type.enum";
import { ConfirmationDialogComponent } from "../Shared/confirmation-dialog/confirmation-dialog.component";
import { ConfirmationDialogData } from "../Shared/confirmation-dialog/Models/ConfirmationDialogData";

@Injectable({
    providedIn: 'root',
})
export class ResetNotificationsService {
    constructor(
        private emailSettingsService: EmailSettingsService,
        private emailAPIService: EmailNotificationService,
        private dialog: MatDialog,
        private uiNotificationService: NotificationsService
    ) { 
    }

    resetNotifications(category: string): Observable<void> {
        return this.emailAPIService.postGenerateNewNotificationSeed(category);
    }

    onSuccess(): void {
        this.uiNotificationService.queueNotification({
            type: MIPNotificationType.Success,
            title: constants.common.updated,
            message: constants.notification.resetSuccess,
        });
    }

    onFailure(): void {
        this.uiNotificationService.queueNotification({
            type: MIPNotificationType.Error,
            title: constants.common.error,
            message: constants.notification.resetFailure,
        });        
    }    

    promptResetNotificationOnAction(category: string, callback: (performReset: boolean) => void) {
        let settings = this.emailSettingsService.getCurrentEmailSetting(category);
        if(settings?.notifcationsEnabled) {
            const dialogRequest: ConfirmationDialogData = {
                text: constants.notification.dialog.onAction.message,
                title: constants.notification.dialog.onAction.title,
                okText: constants.common.buttons.reset
            };

            const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
                data: dialogRequest,
                width: '50%',
                height: 'auto'
            });
            dialogRef.afterClosed().subscribe(result => {
                callback(result === true);
            });
        } else {
            callback(false);
        }
    }

    promptResetNotificationsOnReset(callback: (performReset: boolean) => void) {
        const dialogRequest: ConfirmationDialogData = {
            text: constants.notification.dialog.onReset.message,
            title: constants.notification.dialog.onReset.title,
            okText: constants.common.buttons.reset
          };

        const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
            data: dialogRequest,
            width: '50%',
            height: 'auto'
        });
        dialogRef.afterClosed().subscribe(result => {
            callback(result === true);
        });
    }
}