import { Component, Inject } from '@angular/core';
import { MatDialog, MatDialogConfig, MatDialogContent, MatDialogRef, MAT_DIALOG_DATA, MatDialogTitle } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatProgressSpinner, MatProgressSpinnerModule } from '@angular/material/progress-spinner';

@Component({
  selector: 'app-blocker-dialog',
    standalone: true,
    imports: [MatProgressSpinnerModule, MatDialogContent, MatDialogTitle, MatProgressSpinner, MatDividerModule],
  templateUrl: './blocker-dialog.component.html',
  styleUrl: './blocker-dialog.component.scss'
})
export class BlockerDialogComponent {
    title: string = "Processing";
    text: string = "Please Wait";

    constructor(
        public dialogRef: MatDialogRef<BlockerDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: { title: string, text: string }
    ) {
        dialogRef.updateSize('250px');
        if (data) {
            this.title = data.title ?? this.title;
            this.text = data.text ?? this.text;
        }
    }
}

export function openBlockerDialog(dialog: MatDialog, data?: { title: string, text: string }): MatDialogRef<BlockerDialogComponent> {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.width = '250px';
    dialogConfig.height = '250px';
    dialogConfig.disableClose = true;

    const ref = dialog.open(BlockerDialogComponent, dialogConfig);
    if (data) {
        ref.componentInstance.text = data.text;
        ref.componentInstance.title = data.title;
    }

    return ref;
}
