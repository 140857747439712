<div *ngIf="isPageLoading" class="table-settings-spinner loading-spinner">
    <mat-progress-spinner color="primary" mode="indeterminate">
    </mat-progress-spinner>
</div>

<div class="mat-elevation-z8" *ngIf="!isPageLoading && tableData.length">
    <mat-card class="mip-table-mat-card">
        <mat-table class='mip-table' [dataSource]="dataSource" matSort [matSortActive]="sortField" [matSortDirection]="sortDirection" matSortDisableClear="true" infiniteScroll
        [infiniteScrollDistance]="infiniteScrollDistance"
        [infiniteScrollThrottle]="infiniteScrollThrottle"
        [scrollWindow]="false"
        (scrolled)="onScrollEvent()">
            
            <ng-container *ngIf="displayActions" matColumnDef="actions">
                <mat-header-cell *matHeaderCellDef>Actions</mat-header-cell>
                <mat-cell *matCellDef="let element">
                    <mat-icon *ngFor="let action of actionsList" [class]="getIconColorClass(action.themeType)" aria-hidden="false" aria-label="action.name"
                        [matTooltip]="action.name" [fontIcon]="action.icon" (click)="returnActionDetails(action.type, element)"></mat-icon>
                </mat-cell>
            </ng-container>

            <ng-container *ngFor="let column of dataColumns; index as i" [matColumnDef]="column">
                <mat-header-cell *matHeaderCellDef mat-sort-header [disabled]="disableSort"> {{ displayedColumns[i] ?? column }}
                </mat-header-cell>
                <mat-cell *matCellDef="let element"> {{element[column]}} </mat-cell>
            </ng-container>

            <mat-header-row
                *matHeaderRowDef="displayActions ? dataColumnsWithAction : dataColumns"></mat-header-row>
            <mat-row *matRowDef="let row; columns: displayActions ? dataColumnsWithAction : dataColumns;"></mat-row>
        </mat-table>
        <div *ngIf="isNextPageLoading" class="table-settings-spinner loading-spinner">
            <mat-progress-spinner color="primary" mode="indeterminate">
            </mat-progress-spinner>
        </div>        
    </mat-card>
</div>