import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { RouterLink, RouterLinkActive } from '@angular/router';
import { MatTooltipModule } from '@angular/material/tooltip';
import { CommonModule } from '@angular/common';
import { MatListModule } from '@angular/material/list';
@Component({
  selector: 'app-nav-bar-item',
  standalone: true,
  imports: [MatDividerModule, MatIconModule, RouterLink, MatTooltipModule, CommonModule, RouterLinkActive, MatListModule],
  templateUrl: './nav-bar-item.component.html',
  styleUrl: './nav-bar-item.component.scss'
})
export class NavBarItemComponent {

  rla:boolean=false;
  
  @Input('route') route: string = ''
  @Input('icon') icon: string = '';
  @Input('name') name: string = '';
  @Output('onClick') onClick = new EventEmitter();
  navItemClickFN() { this.onClick.emit() };
}


