<div>
    <mat-card class="">
        <mat-card-header>
            <mat-card-title>Payroll Schedule</mat-card-title>
        </mat-card-header>
        <mat-card-content>
            <form [formGroup]="tenantScheduleSettingsOptionsForm" (ngSubmit)="saveConfig()">
                <mat-card-content class="standard-gap-vertical">
                    <div class="gridContent">
                        <mat-spinner *ngIf="integration !== null && hideForm()" [diameter]="20"></mat-spinner>
                        <div *ngIf="tenantScheduleSettingsLoaded">
                            <div class="margin-bottom-10">
                                <div class="form-group">
                                    <b>Enable or Disable Payroll Scheduling</b>
                                    <div>Select whether you'd like to turn payroll scheduling on or off.</div>
                                    <mat-radio-group matInput formControlName="enableSchedule" class="mat-radio-group"
                                        (change)="setSchedule($event.value)">
                                        <mat-radio-button class="mat-radio-group" value="true">Enable
                                            Scheduling</mat-radio-button>
                                        <mat-radio-button class="mat-radio-group" value="false">Disable
                                            Scheduling</mat-radio-button>
                                    </mat-radio-group>
                                </div>
                            </div>
                            <div class="margin-bottom-10" *ngIf="showForms">
                                <div class="form-group">
                                    <b>Schedule Types</b>
                                    <div class="margin-bottom-10">Select which schedule type you'd like enabled for
                                        scheduling.</div>
                                    <mat-form-field class="col-3 margin-bottom-10">
                                        <mat-label>Schedule Type</mat-label>
                                        <mat-select matInput formControlName="scheduleType"
                                            (selectionChange)="switchScheduleType($event.value)" placeholder="--">
                                            <mat-option value="advanced">Advanced Rate Schedule</mat-option>
                                            <mat-option value="basic">Basic Weekly Schedule</mat-option>
                                        </mat-select>
                                    </mat-form-field>

                                    <div *ngIf="showAdvancedForm">
                                        <b>Advanced Rate Schedule</b>
                                        <div class="margin-bottom-10">Enter the scheduling information below.</div>
                                        <mat-label>Repeat Every:</mat-label>
                                        <div>
                                            <mat-form-field class="col-3">
                                                <input formControlName="interval" matInput placeholder="Interval"
                                                    type="number" required />
                                            </mat-form-field>
                                            <mat-form-field class="col-3 margin-left-10">
                                                <mat-select formControlName="cadenceType" placeholder="Days">
                                                    <mat-option *ngFor="let type of cadenceType"
                                                        [value]="type">{{type}}</mat-option>
                                                </mat-select>
                                            </mat-form-field>
                                        </div>
                                        <div>
                                            <mat-form-field class="col-3">
                                                <mat-label>Time of Day:</mat-label>
                                                <input formControlName="timeOfDayAdv" matInput type="time" step="1"
                                                    required />
                                            </mat-form-field>
                                        </div>
                                        <div>
                                            <mat-form-field class="col-3">
                                                <mat-label>Timezone:</mat-label>
                                                <mat-select formControlName="timezoneAdv">
                                                    <mat-option *ngFor="let tz of timezones"
                                                        [value]="tz">{{tz}}</mat-option>
                                                </mat-select>
                                            </mat-form-field>
                                        </div>
                                    </div>

                                    <div *ngIf="showBasicForm">
                                        <b>Basic Weekly Schedule</b>
                                        <div class="margin-bottom-10">Enter the scheduling information below.</div>
                                        <mat-label>Day(s) of the Week:</mat-label>
                                        <div class="margin-bottom-10">
                                            <mat-selection-list formControlName="daysOfWeek" class="col-3">
                                                <mat-list-option togglePosition="before" *ngFor="let day of daysOfWeek"
                                                    [value]="day">{{day}}</mat-list-option>
                                            </mat-selection-list>
                                        </div>
                                        <div>
                                            <mat-form-field class="col-3">
                                                <mat-label>Time of Day:</mat-label>
                                                <input formControlName="timeOfDayBasic" matInput type="time" step="1"
                                                    required />
                                            </mat-form-field>
                                        </div>
                                        <div>
                                            <mat-form-field class="col-3">
                                                <mat-label>Timezone:</mat-label>
                                                <mat-select formControlName="timezoneBasic">
                                                    <mat-option *ngFor="let tz of timezones"
                                                        [value]="tz">{{tz}}</mat-option>
                                                </mat-select>
                                            </mat-form-field>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </mat-card-content>
                <mat-card-actions class="align-right-down standard-gap-vertical">
                    <div>
                        <button mat-flat-button color="primary" id="save-schedule" type="submit" class="upload-btn"
                            [disabled]="canSubmit()">
                            Save
                        </button>
                        <div>
                            <mat-spinner *ngIf="" [diameter]="36"></mat-spinner>
                        </div>
                    </div>
                </mat-card-actions>
            </form>
        </mat-card-content>
    </mat-card>
</div>