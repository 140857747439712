import { Component, EventEmitter, Inject, Input, Output } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MAT_DIALOG_DATA, MatDialogActions, MatDialogContent, MatDialogModule, MatDialogRef, MatDialog, MatDialogConfig, MatDialogTitle } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { TenantAPIService } from '../../Services/api.tenantApi.service';
import { FlowSettingsService } from '../../Services/api.flowSettings.service';
import { flowDefinitionSetting } from '../../Pages/jobs/models/flowDefinitionSetting';
import { tenantFlowResult } from '../../Pages/integration/models/integration-models';
import { tenantSetting } from '../../Models/Class-Interfaces/tenantSetting.model';
import { S3ApiService } from '../../Services/s3ApiService';
import { initiateTableSettingsTransferResponse } from '../../Pages/table-settings/models/initiateTransferResponse.model';
import { interval, Observable, Subject, Subscription, takeUntil, timer } from 'rxjs';
import { processTransferResult } from '../../Pages/transfer/models/processTransferResult.model';
import { cancelTransferResponse } from '../../Pages/transfer/models/cancelTransferResponse.model';
import { CommonModule } from '@angular/common';
import { job } from '../../Pages/jobs/models/job.model';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatCardModule } from '@angular/material/card';
import { BulkUploadDialogData } from './Models/BulkUploadDialogData';

@Component({
    selector: 'bulk-upload-dialog',
    standalone: true,
    imports: [CommonModule, MatCardModule, MatDialogActions, MatDialogContent, MatDialogModule, MatButtonModule, MatIconModule, MatProgressSpinnerModule, MatTooltipModule],
    templateUrl: './bulk-upload-dialog.component.html',
    styleUrl: './bulk-upload-dialog.component.scss'
})
export class BulkUploadComponent {
    @Input() integration: tenantFlowResult | null;
    flowDefinitionSettings: flowDefinitionSetting[] = [];
    tableSettingsLoaded: boolean = false;
    tableSettings: tenantSetting[] = [];
    fileName: string = "";
    selectedFile: File | null = null;
    transferProcessing: boolean = false;
    activeTransferJobId: string | null = null;
    activeJobEvent: Subject<string> = new Subject<string>();
    activeJob: job | null = null;
    pollActivity: boolean = true;

    timer: Subscription = new Subscription();
    loaded: boolean = false;

    selectedTableSettings: tenantSetting;
    afterUploadFunction: CallableFunction;

    constructor(
        @Inject(MAT_DIALOG_DATA) 
        public data: BulkUploadDialogData, 
        public dialogRef: MatDialogRef<BulkUploadComponent>, 
        private flowSettingsAPI: FlowSettingsService, 
        private tenantAPI: TenantAPIService, 
        private s3APIService: S3ApiService, 
        private jobsAPI: TenantAPIService
    ) {
        dialogRef.disableClose = true;
        if (data)
        {
            this.selectedTableSettings = data.selectedTableSettings;
            this.afterUploadFunction = data.afterUploadFunction;
        }
    }

    ngOnInit() {
        // Cross reference our flowDefinitionSettings for the tenant flow with our tenant settings to scope the table settings.
        this.activeJobEvent.subscribe((id) => id && this.beginGetJobId(id));
        if (this.integration != null) {
            this.flowSettingsAPI.getTenantFlowDefinitionSettings(this.integration.flowCode, this.integration.version).subscribe({
                next: (data: flowDefinitionSetting[]) => {
                    this.flowDefinitionSettings = data.filter((item) => item.type === 'table');
        
                    // Extract names from the filtered flowDefinitionSettings
                    const flowNames = this.flowDefinitionSettings.map(item => item.settingName);
        
                    // Filter tableSettings based on the valid flowDefinitionSettings
                    this.tenantAPI.getTenantSetting().subscribe({
                        next: (result: tenantSetting[]) => {
                            this.tableSettings = result.filter((item) => item.type === 'table');
        
                            // Further filter tableSettings to include only those whose names are in flowDefinitionSettings
                            this.tableSettings = this.tableSettings.filter((item) => flowNames.includes(item.settingName));
                        },
                        error: (result: any) => { console.log(result); },
                        complete: () => { this.tableSettingsLoaded = true; }
                    });
                },
                error: (result: any) => { console.log(result); },
                complete: () => { this.tableSettingsLoaded = true; }
            });
        }
    }

    accept(): void {
        this.dialogRef.close(true);
    }

    cancel(): void {
        this.dialogRef.close(false);
    }

    pollForJobStatus = (id: string): void => {

        if (!this.activeTransferJobId || !this.activeJob || (this.activeJob.jobStatus != "New" && this.activeJob.jobStatus != "Active")) {
          this.timer.unsubscribe();
        }
        else {
          this.getJobById(this.activeTransferJobId);
        }
      }
    
    ngOnDestroy() {
        this.timer.unsubscribe();
    }
      
    beginGetJobId = (id: string) => {
        this.activeTransferJobId = id;
        this.getJobById(id);
        if (this.pollActivity) {
            this.timer = interval(15000).pipe(takeUntil(timer(300000))).subscribe((x => {
            this.pollForJobStatus(this.activeTransferJobId ?? "-1");
            }));
        }
    }
    
    getJobById = (id: string): void => {
    this.loaded = false;
    this.jobsAPI.getJobById(id).subscribe({
        next: (data: job) => {
            this.activeJob = data;
        },
        error: (result: any) => { console.log(result) },
        complete: () => this.loaded = true
        });
    }  
    
    onFileSelected = (event: any): void => {
        if (event.target.files[0]) {
            this.selectedFile = event.target.files[0];
        }

        if (this.selectedFile) {
            this.fileName = this.selectedFile.name;
            const formData = new FormData();
            formData.append("file", this.selectedFile);
        }
    }

    canUpload = (): boolean => {
        return this.selectedTableSettings != null && this.selectedTableSettings !== undefined
            && this.selectedFile !== null && this.selectedFile !== undefined
            && !this.isJobActive();
    }

    canSelectFile = (): boolean => {
        return this.selectedTableSettings != null && this.selectedTableSettings !== undefined
            && !this.isJobActive();
    }

    canCancel = (): boolean => {
        return !this.transferProcessing && (this.activeJob?.jobStatus != "New" && this.activeJob?.jobStatus != "Active");
    }

    uploadFile = (): void => {
        const fileUpload$ = this.tenantAPI.intitateTableSettingsTransfer;
        const uploadToS3$ = this.s3APIService.uploadFileToS3;
        const processTransfer$ = this.tenantAPI.processTransfer;

        const formData = new FormData();
        console.log(`Inside uploadFile ${this.selectedFile?.name} ::::: ${this.selectedTableSettings.displayName}`)
        if (this.selectedFile && this.selectedTableSettings) {
            formData.append("file", this.selectedFile);
            this.transferProcessing = true;
            fileUpload$(this.selectedTableSettings?.settingName, this.selectedFile.name, this.selectedFile.type).subscribe({
                next: (response: initiateTableSettingsTransferResponse) => {
                    this.activeTransferJobId = response.jobId;
                    this.activeJobEvent.next(this.activeTransferJobId);
                    uploadToS3$(response.uploadUrl, this.selectedFile!).subscribe({
                        next: () => {
                            processTransfer$(this.activeTransferJobId!).subscribe({
                                next: (result: processTransferResult) => {
                                    this.transferCompleted();
                                },
                                error: this.transferErrored,
                                complete: this.transferCompleted
                            })
                        },
                        error: this.transferErrored
                    },
                    );
                }, error: this.transferErrored
            })
        }
        else {
            console.log("Unable to upload file");
        }
    }

    transferErrored = () => {
        console.log("Transfer Errored");

        if (!this.activeTransferJobId) {
            this.transferCompleted();
            return;
        }

        console.log("Cancelling Transfer ", this.activeTransferJobId);

        const cancelTransfer$ = this.tenantAPI.cancelTransfer;

        cancelTransfer$(this.activeTransferJobId!).subscribe({
            next: (result: cancelTransferResponse) => {
                this.transferCompleted();
            },
            error: () => {
                console.log("Unable to cancel transfer");
                this.transferCompleted;
            },
            complete: this.transferCompleted
        })
    }

    getIconFont = (job?: job): string => {
        switch (job?.jobStatus) {
            case "New":
            case "Active":
            return 'progress_activity';
            case "Complete":
            return 'check_circle'
            case "Error":
            return 'error'
            default:
            return 'pause'
        }
    }

    getIconSrc = (job?: job): string => {
        switch (job?.jobStatus) {
            case "Complete":
                return 'assets/images/Success-Upload.svg'
            case "Error":
                return 'assets/images/Error-Upload.svg'
            default:
                return '';
        }
    }
    
    isJobActive():boolean{
        return this.activeJob != null && (this.activeJob.jobStatus === "New" || this.activeJob.jobStatus === "Active" )
    }

    transferCompleted = () => {
        this.transferProcessing = false;
    }
}