<div class="container-fluid p-0">
    <div class="nav-bar-spinner"><mat-spinner *ngIf="!offeringsLoaded" [diameter]="40"></mat-spinner></div>
    <mat-nav-list role="navigation" *ngIf="offeringsLoaded" class="nav-bar" [disableRipple]="true">
        <a mat-list-item id="offering-{{offering.offeringCode}}" routerLink="offering/{{offering.offeringCode}}"
            [name]="offering.offeringName" class="nav-item" [routerLinkActive]="['nav-item-is-active']"
            *ngFor="let offering of offerings">
                <app-company-icon [offeringCode]="offering.offeringCode" matListItemIcon></app-company-icon>
                <span
                    matListItemTitle>{{offering.offeringName}}
                </span>
        </a>
        <a mat-list-item routerLink="jobs/search" class="nav-item" [routerLinkActive]="['nav-item-is-active']"><mat-icon
                matListItemIcon>search</mat-icon><span matListItemTitle>Search Logs</span></a>
        <a mat-list-item routerLink="settings" class="nav-item" [routerLinkActive]="['nav-item-is-active']"><mat-icon
                matListItemIcon>settings</mat-icon><span matListItemTitle>Settings</span></a>
        <a mat-list-item [name]="'Sign Out'" class="nav-item" routerLink="'signOut'" (click)='signOut($event)'><mat-icon
                matListItemIcon>logout</mat-icon><span matListItemTitle>Sign Out</span></a>
    </mat-nav-list>
</div>