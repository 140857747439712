<div class="">
    <div class="">
        <h2 mat-dialog-title>Job Item Details</h2>
        <mat-dialog-content>
            <div class="job-details">
                <p class="label left-align"><strong>Job ID: </strong>{{ jobAndJobItem.job.jobId }}</p>
                <p class="label left-align"><strong>Job Status: </strong>{{ jobAndJobItem.job.jobStatus }}</p>
                <p class="label left-align"><strong>Job Item Status: </strong>{{ jobAndJobItem.jobItem.jobItemStatus}}</p>
                <p class="label left-align"><strong>Job Item Error Code: </strong>{{ jobAndJobItem.jobItem.errorCode}}</p>
                <p class="label left-align"><strong>Job Item Error Message: </strong>{{ jobAndJobItem.jobItem.errorMessage}}</p>
            </div>
            <mat-card>
                <mat-card-content>
                    <mat-table [dataSource]="getKeyValuePairs()">
                        <ng-container matColumnDef="field">
                            <mat-header-cell *matHeaderCellDef mat-sort-header> Fields </mat-header-cell>
                            <mat-cell *matCellDef="let fieldValuePair"> {{ fieldValuePair.key }} </mat-cell>
                        </ng-container>
                        <ng-container matColumnDef="value">
                            <mat-header-cell *matHeaderCellDef mat-sort-header> Values </mat-header-cell>
                            <mat-cell *matCellDef="let fieldValuePair"> {{ fieldValuePair.value }} </mat-cell>
                        </ng-container>
                        <mat-header-row *matHeaderRowDef="['field', 'value']"></mat-header-row>
                        <mat-row *matRowDef="let fieldValuePair; columns: ['field', 'value']"></mat-row>
                    </mat-table>
                </mat-card-content>
            </mat-card>
        </mat-dialog-content>
    </div>
</div>