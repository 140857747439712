<div class="app-confirmation-dialog">
    <div class="app-confirmation-dialog-content">
        <h2 mat-dialog-title>{{ title }}</h2>
        <mat-dialog-content>
            <p>{{ text }}</p>
        </mat-dialog-content>
        <mat-dialog-actions>
            <button mat-button mat-dialog-close>Cancel</button>
            <button mat-flat-button color="primary" cdkFocusInitial (click)="accept()">Ok</button>
        </mat-dialog-actions>
    </div>
</div>
