import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Inject, Output } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MAT_DIALOG_DATA, MatDialogActions, MatDialogContent, MatDialogModule, MatDialogRef, MatDialog, MatDialogConfig, MatDialogTitle } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { ConfirmationDialogData } from './Models/ConfirmationDialogData';


@Component({
    selector: 'app-confirmation-dialog',
    standalone: true,
    imports: [CommonModule, MatDialogActions, MatDialogContent, MatDialogModule, MatButtonModule, MatDialogTitle, MatIconModule, MatDividerModule],
    templateUrl: './confirmation-dialog.component.html',
    styleUrl: './confirmation-dialog.component.scss'
})
export class ConfirmationDialogComponent {
    title: string = "Confirm";
    text: string = "Do you want to proceed?";
    subtitle: string | null = null;
    okText: string = "Ok";
    cancelText: string = "Cancel";

    @Output() acceptEvent = new EventEmitter<boolean>();

    constructor(
        public dialogRef: MatDialogRef<ConfirmationDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: ConfirmationDialogData
    ) {
        var width : string | undefined = undefined;
        var height: string | undefined = undefined;

        if (data) {
            this.title = data.title ?? this.title;
            this.subtitle = data.subtitle ?? this.subtitle;
            this.text = data.text ?? this.text;
            this.okText = data.okText ?? this.okText;
            this.cancelText = data.cancelText ?? this.cancelText;
            width = data.width ?? width;
            height = data.height ?? height;
        }

        dialogRef.updateSize(width, height);
    }

    accept(): void {
        this.acceptEvent.emit(true);
        this.dialogRef.close(true);
    }

    close(): void {
        this.dialogRef.close();
    }
}

export function openDialog(dialog: MatDialog, data?: ConfirmationDialogData): MatDialogRef<ConfirmationDialogComponent> {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.width = '250px';
    dialogConfig.height = '250px';

    const ref = dialog.open(ConfirmationDialogComponent, {
        data: data,
    });

    return ref;
}