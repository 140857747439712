import { Component } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { TenantAPIService } from '../../Services/api.tenantApi.service';
import { flow } from '../../Models/Class-Interfaces/flow.model';
import { MatTabsModule } from '@angular/material/tabs';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { CommonModule } from '@angular/common';
import { TransferComponent } from '../transfer/transfer.component';
import { EmailNotificationSettingsComponent } from '../email-notification-settings/email-notification-settings.component';
import { tenantFlowResult } from './models/integration-models';
import { JobsComponent } from '../jobs/jobs.component';
import { TenantConfigurationComponent } from '../tenant-configuration/tenant-configuration.component';
import { TableSettingsComponent } from '../table-settings/table-settings.component';
import { PayrollScheduleComponent } from '../payroll-schedule/payroll-schedule.component';
import { tenantFlowSetting } from '../../Models/Class-Interfaces/tenantFlowSetting.model';

@Component({
  selector: 'app-integration',
  standalone: true,
  imports: [EmailNotificationSettingsComponent, CommonModule, JobsComponent, MatProgressSpinnerModule, MatTabsModule, TransferComponent, TenantConfigurationComponent,
    TableSettingsComponent, PayrollScheduleComponent
   ],
  templateUrl: './integration.component.html',
  styleUrl: './integration.component.scss'
})
export class IntegrationComponent {
  flowCode: string | null = null;
  flowType: string = "Job";
  integration: tenantFlowResult | null = null;
  loading: boolean = false;
  flowNotFound: boolean = false;
  tenantFlowSettings: tenantFlowSetting[] = [];
  tenantFlowSettingsLoading: boolean = false;
  shouldShowScheduleTab: boolean = false;
  constructor(private route: ActivatedRoute, private tenantAPIService: TenantAPIService) { }

  ngOnInit() {
    this.route.params.subscribe((param: Params) => {
      this.flowCode = param['flowCode']; 
      this.loading = true;
      this.tenantFlowSettingsLoading = true;
      this.tenantAPIService.getFlowInstance(this.flowType, this.flowCode ?? "").subscribe({
        next: (result: tenantFlowResult) => {
          let flow: flow = { name: result.name, code: result.flowCode, version: result.version };
          this.integration = result ?? null;
          return flow;
        },
        error: (result: any) => { console.log(result) },
        complete: () => { this.loading = false; }
      });

      this.tenantAPIService.getTenantFlowSettings(this.flowCode ?? "").subscribe({
        next: (data: tenantFlowSetting[]) => {
          this.tenantFlowSettings = data;
          this.shouldShowScheduleTab = data.some(d => d.subType === "user-configurable-schedule");
        },
        error: (result: any) => { console.log(result) },
        complete: () => { this.tenantFlowSettingsLoading = false; }
      });
    });
  }
}
