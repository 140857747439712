<mat-card class="container-fluid p-0">
    <mat-card-header>
        <mat-card-title>E-mail Notifications</mat-card-title>
    </mat-card-header>
    <form [formGroup]="emailSettingsForm">
        <mat-card-content>
            <div class="row">
                <div class="col-6">
                    <div class="form-group">
                        <mat-checkbox id="notifications-enabled-ck" formControlName="notificationsEnabled"
                            [labelPosition]="'before'" class="enable-checkbox"
                            (change)="enableNotificationsCheckChanged($event.checked)">Enable Error E-mail
                            Alerts</mat-checkbox>
                    </div>
                    <div class="form-group">
                        <mat-form-field appearance="outline">
                            <mat-label>Recipients </mat-label>

                            <input id="recipients" formControlName="recipients" matInput>
                        </mat-form-field>
                    </div>
                    <div class="form-group">

                        <mat-form-field appearance="outline">
                            <mat-label [ngClass]="!disableForm()? 'field-disabled' : ''">Subject </mat-label>
                            <input id="subject" formControlName="subject" matInput>
                        </mat-form-field>
                    </div>
                    <div class="form-group">

                        <mat-form-field appearance="outline">
                            <mat-label [ngClass]="!disableForm()? 'field-disabled' : ''">Message </mat-label>
                            <textarea id="message" formControlName="message" matInput rows="5" cols="40"
                                style="resize:none"></textarea>
                        </mat-form-field>
                    </div>
                </div>
            </div>

        </mat-card-content>
        <mat-card-actions>
            <div class="flex-start">
                <button mat-stroked-button color="primary" id="cancel-btn" type="cancel" [disabled]="!this.canSave()" (click)="this.onCancel()" class="cancel-button">
                    Cancel
                </button>
                <span class="button-spacer"></span>
                <button mat-flat-button color="primary" id="save-btn" type="submit"  [disabled]="!this.canSave()" (click)="this.saveEmailSettings()">
                    Save
                </button>
                <mat-spinner class="mdc-card__actions-spinner" [diameter]="20" id="initials-spinner" *ngIf="this.isSaving"></mat-spinner>

            </div>

        </mat-card-actions>
    </form>
</mat-card>