import { ChangeDetectorRef, Component, Input, signal } from '@angular/core';
import { MatTabsModule } from '@angular/material/tabs';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { CommonModule } from '@angular/common';
import { FormBuilder, FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { tenantFlowResult } from '../integration/models/integration-models';
import { FlowSettingsService } from '../../Services/api.flowSettings.service';
import { TenantAPIService } from '../../Services/api.tenantApi.service';
import { tenantFlowSetting } from '../../Models/Class-Interfaces/tenantFlowSetting.model';
import { Router } from '@angular/router';
import { MatIconModule } from '@angular/material/icon';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { ConfirmationDialogComponent } from '../../Shared/confirmation-dialog/confirmation-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { MatListModule } from '@angular/material/list';
import { createUserDefinedScheduleRequest, scheduleTypesEnum, updateUserDefinedScheduleRequest } from './models/createUserDefinedSchedule';
import { DisableSchedulingComponent } from '../../Shared/disable-scheduling-dialog/disable-scheduling-dialog.component';

@Component({
  selector: 'app-payroll-schedule',
  standalone: true,
  imports: [CommonModule, MatButtonToggleModule, MatChipsModule, MatDatepickerModule, MatIconModule, MatProgressSpinnerModule, MatTabsModule, MatCardModule, MatFormFieldModule, MatInputModule, MatButtonModule, FormsModule, ReactiveFormsModule, MatCheckboxModule, MatRadioModule, MatSelectModule, MatListModule],
  templateUrl: './payroll-schedule.component.html',
  styleUrl: './payroll-schedule.component.scss'
})
export class PayrollScheduleComponent {
  constructor(public router: Router, private flowSettingsAPI: FlowSettingsService, private tenantAPI: TenantAPIService, private fb: FormBuilder, private dl: MatDialog, private cdr: ChangeDetectorRef) {
    this.tenantScheduleSettingsOptionsForm = this.fb.group({
      enableSchedule: new FormControl(),
      scheduleType: '',
      interval: '',
      cadenceType: '',
      timeOfDayAdv: '',
      timezoneAdv: '',
      daysOfWeek: '',
      timeOfDayBasic: '',
      timezoneBasic: ''
    });
    this.dialog = this.dl;
  }
  @Input() integration: tenantFlowResult | null = null;
  @Input() tenantFlowSettings: tenantFlowSetting[] = [];
  private initialScheduleEnabled: string = '';
  tenantFlowScheduleSetting: tenantFlowSetting | undefined;
  tenantScheduleSettings: createUserDefinedScheduleRequest;
  tenantScheduleSettingsOptionsForm: FormGroup;
  tenantScheduleSettingsLoaded: boolean = false;
  submittingForm: boolean = false;
  showForms: boolean = false;
  showAdvancedForm: boolean = false;
  showBasicForm: boolean = false;
  timezones: string[] = Intl.supportedValuesOf('timeZone');
  daysOfWeek: string[] = ['SUN', 'MON', 'TUES', 'WED', 'THUR', 'FRI', 'SAT'];
  cadenceType: string[] = ['Minutes', 'Hours', 'Days'];
  dialog: MatDialog;

  ngOnInit() {
    this.loadConfigSettings();
  }

  loadConfigSettings = (): void => {
    //load settings here and update form if settings already exist
    this.tenantFlowScheduleSetting = this.tenantFlowSettings.find(d => d.subType === "user-configurable-schedule");
    this.flowSettingsAPI.getFlowSchedule(this.integration?.type ?? "", this.integration?.flowCode ?? "").subscribe({
      next: (data: createUserDefinedScheduleRequest[]) => {
        this.tenantScheduleSettings = data[0];
      },
      error: (result: any) => { console.log(result) },
      complete: () => {
        if (this.tenantScheduleSettings != null) {
          this.updateFormData();
        }
        this.tenantScheduleSettingsLoaded = true;
        this.cdr.detectChanges();
      }
    });
  }

  updateFormData = (): void => {
    //update control fields with values loaded from config
    this.initialScheduleEnabled = this.tenantScheduleSettings.enabled.toString();
    this.tenantScheduleSettingsOptionsForm.controls['enableSchedule']?.setValue(this.initialScheduleEnabled);
    this.tenantScheduleSettingsOptionsForm.controls['scheduleType']?.setValue(this.tenantScheduleSettings.type);
    const timeOfDay = this.tenantScheduleSettings.timeOfDay ? this.tenantScheduleSettings.timeOfDay.split(':').slice(0, 2).join(':') : ""

    if (this.tenantScheduleSettings.type === scheduleTypesEnum.advanced) {
      this.tenantScheduleSettingsOptionsForm.controls['timezoneAdv']?.setValue(this.tenantScheduleSettings.timeZone);
      this.tenantScheduleSettingsOptionsForm.controls['timeOfDayAdv']?.setValue(timeOfDay);
      this.tenantScheduleSettingsOptionsForm.controls['interval']?.setValue(this.tenantScheduleSettings.repeatCadence);
      this.tenantScheduleSettingsOptionsForm.controls['cadenceType']?.setValue(this.tenantScheduleSettings.repeatCadenceType);
    }
    else if (this.tenantScheduleSettings.type === scheduleTypesEnum.basic) {
      this.tenantScheduleSettingsOptionsForm.controls['timezoneBasic']?.setValue(this.tenantScheduleSettings.timeZone);
      this.tenantScheduleSettingsOptionsForm.controls['timeOfDayBasic']?.setValue(timeOfDay);
      this.tenantScheduleSettingsOptionsForm.controls['daysOfWeek']?.setValue(this.tenantScheduleSettings.daysOfWeek.split(","));
    }

    this.tenantScheduleSettingsOptionsForm.updateValueAndValidity();

    this.setSchedule(this.tenantScheduleSettings.enabled.toString());
    this.switchScheduleType(this.tenantScheduleSettings.type);
    this.cdr.detectChanges();
    this.tenantScheduleSettingsOptionsForm.markAsPristine()
  }

  logFormErrors = (): void => {
    const controls = this.tenantScheduleSettingsOptionsForm.controls;
    for (const name in controls) {
      if (controls[name].invalid) {
        console.log(`Control: ${name}, Errors:`, controls[name].errors);
      }
    }
  }

  saveConfig = (): void => {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      data: { title: "Update Integration Schedule", text: "Are you sure you want to proceed?" },
    });

    dialogRef.afterClosed().subscribe({
      next: (confirmed: boolean) => {
        if (confirmed) {
          var formFields;
          var isAdvancedField = this.tenantScheduleSettingsOptionsForm.controls['scheduleType']?.value === scheduleTypesEnum.advanced;
          
          const timeOfDayAdvWithSeconds = this.tenantScheduleSettingsOptionsForm.controls['timeOfDayAdv']?.value
              ? `${this.tenantScheduleSettingsOptionsForm.controls['timeOfDayAdv']?.value}:00`
              : "";

              const timeOfDayBasicWithSeconds = this.tenantScheduleSettingsOptionsForm.controls['timeOfDayBasic']?.value
              ? `${this.tenantScheduleSettingsOptionsForm.controls['timeOfDayBasic']?.value}:00`
              : "";

          if (this.tenantScheduleSettings != null) {
            formFields = new updateUserDefinedScheduleRequest(
              this.tenantScheduleSettingsOptionsForm.controls['scheduleType']?.value,
              isAdvancedField ? timeOfDayAdvWithSeconds : timeOfDayBasicWithSeconds,
              isAdvancedField ? this.tenantScheduleSettingsOptionsForm.controls['timezoneAdv']?.value :
                this.tenantScheduleSettingsOptionsForm.controls['timezoneBasic']?.value,
              this.tenantScheduleSettingsOptionsForm.controls['cadenceType']?.value,
              isAdvancedField ? "" :
                this.tenantScheduleSettingsOptionsForm.controls['daysOfWeek']?.value.join(","),
              this.tenantScheduleSettingsOptionsForm.controls['enableSchedule']?.value === 'true',
              this.tenantFlowScheduleSetting?.stepScheduleDetailType,
              parseInt(this.tenantScheduleSettingsOptionsForm.controls['interval']?.value),
              this.tenantScheduleSettings.startDate
            );

            this.flowSettingsAPI.updateFlowSchedule(this.integration?.type ?? "", this.integration?.flowCode ?? "", this.tenantScheduleSettings.name, formFields).subscribe({
              error: (result: any) => { console.log(result) },
              complete: () => {
                this.resetFormLoading();
                this.loadConfigSettings();
              }
            });
          }
          else {

            formFields = new createUserDefinedScheduleRequest(
              this.tenantFlowScheduleSetting?.settingName ?? "",
              this.tenantFlowScheduleSetting?.stepScheduleName ?? "",
              this.tenantScheduleSettingsOptionsForm.controls['scheduleType']?.value,
              isAdvancedField ? timeOfDayAdvWithSeconds : timeOfDayBasicWithSeconds,
              isAdvancedField ? this.tenantScheduleSettingsOptionsForm.controls['timezoneAdv']?.value :
                this.tenantScheduleSettingsOptionsForm.controls['timezoneBasic']?.value,
              this.tenantScheduleSettingsOptionsForm.controls['cadenceType']?.value,
              isAdvancedField ? "" :
                this.tenantScheduleSettingsOptionsForm.controls['daysOfWeek']?.value.join(","),
              this.tenantScheduleSettingsOptionsForm.controls['enableSchedule']?.value === 'true',
              this.tenantFlowScheduleSetting?.stepScheduleDetailType,
              parseInt(this.tenantScheduleSettingsOptionsForm.controls['interval']?.value),
              new Date()
            );

            this.flowSettingsAPI.createFlowSchedule(this.integration?.type ?? "", this.integration?.flowCode ?? "", formFields).subscribe({
              error: (result: any) => { console.log(result) },
              complete: () => {
                this.resetFormLoading();
                this.loadConfigSettings();
              }
            });
          }
        }
      }
    });
  }

  setSchedule = (scheduleEnabled: string): void => {
    this.showForms = scheduleEnabled === "true" ? true : false;
    this.tenantScheduleSettingsOptionsForm.controls['enableSchedule'].setValue(scheduleEnabled);

    if(scheduleEnabled === this.initialScheduleEnabled)
    {
      this.tenantScheduleSettingsOptionsForm.controls['enableSchedule'].markAsPristine();
      this.tenantScheduleSettingsOptionsForm.controls['enableSchedule'].markAsUntouched();
    }
  }

  onDisableScheduling = (): void => {
    if (!this.tenantScheduleSettingsOptionsForm.valid) {
      const dialogRef = this.dialog.open(DisableSchedulingComponent)

      dialogRef.afterClosed().subscribe((result) => {
        if(result){
          if (this.tenantScheduleSettings != null) {
            this.updateFormData();
            this.setSchedule("false");
          }
        }
        else{
          this.tenantScheduleSettingsOptionsForm.controls['enableSchedule'].setValue("true");
          this.setSchedule("true")
        }
      });
    }
  }

  switchScheduleType = (scheduleType: string): void => {
    if (scheduleType === scheduleTypesEnum.advanced) {
      this.showAdvancedForm = true;
      this.showBasicForm = false;
    }
    else if (scheduleType === scheduleTypesEnum.basic) {
      this.showAdvancedForm = false;
      this.showBasicForm = true;
    }
    else {
      this.showAdvancedForm = false;
      this.showBasicForm = false;
    }

    this.setValidatorsForScheduleType(scheduleType);
    this.cdr.detectChanges();
  }

  resetFormLoading = (): void => {
    this.tenantScheduleSettingsLoaded = false;
    this.submittingForm = false;
    this.showForms = false;
    this.showAdvancedForm = false;
    this.showBasicForm = false;
  }

  hideForm = (): boolean => {
    return this.integration?.flowCode == null || !this.tenantScheduleSettingsLoaded;
  }

setValidatorsForScheduleType = (scheduleType: string): void => {
  this.tenantScheduleSettingsOptionsForm.controls['timezoneAdv'].disable();
  this.tenantScheduleSettingsOptionsForm.controls['timeOfDayAdv'].disable();
  this.tenantScheduleSettingsOptionsForm.controls['interval'].disable();
  this.tenantScheduleSettingsOptionsForm.controls['cadenceType'].disable();

  this.tenantScheduleSettingsOptionsForm.controls['timezoneBasic'].disable();
  this.tenantScheduleSettingsOptionsForm.controls['timeOfDayBasic'].disable();
  this.tenantScheduleSettingsOptionsForm.controls['daysOfWeek'].disable();

  this.tenantScheduleSettingsOptionsForm.updateValueAndValidity();

  if (scheduleType === scheduleTypesEnum.advanced) {
      this.tenantScheduleSettingsOptionsForm.controls['timezoneAdv'].enable();
      this.tenantScheduleSettingsOptionsForm.controls['timeOfDayAdv'].enable();
      this.tenantScheduleSettingsOptionsForm.controls['interval'].enable();
      this.tenantScheduleSettingsOptionsForm.controls['cadenceType'].enable();
      this.tenantScheduleSettingsOptionsForm.controls['timezoneAdv'].setValidators([Validators.required]);
      this.tenantScheduleSettingsOptionsForm.controls['timeOfDayAdv'].setValidators([Validators.required]);
      this.tenantScheduleSettingsOptionsForm.controls['interval'].setValidators([Validators.required, Validators.min(1)]);
      this.tenantScheduleSettingsOptionsForm.controls['cadenceType'].setValidators([Validators.required]);
  } else if (scheduleType === scheduleTypesEnum.basic) {
    this.tenantScheduleSettingsOptionsForm.controls['timezoneBasic'].enable();
    this.tenantScheduleSettingsOptionsForm.controls['timeOfDayBasic'].enable();
    this.tenantScheduleSettingsOptionsForm.controls['daysOfWeek'].enable();
      this.tenantScheduleSettingsOptionsForm.controls['timezoneBasic'].setValidators([Validators.required]);
      this.tenantScheduleSettingsOptionsForm.controls['timeOfDayBasic'].setValidators([Validators.required]);
      this.tenantScheduleSettingsOptionsForm.controls['daysOfWeek'].setValidators([Validators.required]);
  }

  this.tenantScheduleSettingsOptionsForm.updateValueAndValidity();
  this.cdr.detectChanges();
  this.logFormErrors();
}

cannotSubmit = (): boolean => {
  const currentScheduleEnabled = this.tenantScheduleSettingsOptionsForm.controls['enableSchedule'].value;
  return !this.integration || !this.tenantScheduleSettingsOptionsForm.valid || (!this.tenantScheduleSettingsOptionsForm.dirty && currentScheduleEnabled === this.initialScheduleEnabled)
}

}