import { Component } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { MatTabsModule } from '@angular/material/tabs';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { CommonModule } from '@angular/common';
import { CredentialsComponent } from './credentials/credentials.component';

@Component({
  selector: 'app-settings',
  standalone: true,
  imports: [CommonModule, MatProgressSpinnerModule, MatTabsModule, CredentialsComponent ],
  templateUrl: './settings.component.html',
  styleUrl: './settings.component.scss'
})
export class SettingsComponent {
  loading: boolean = false;

  ngOnInit() {
  }
}
