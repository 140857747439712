<form class="app-searchable-dropdown">
    <mat-form-field>
        <mat-icon matSuffix>arrow_drop_down</mat-icon>
        <input #searchInput type="text" placeholder="Search" [formControl]="searchableDropdownControl" matInput [matAutocomplete]="auto" (input)="filter()" (focus)="filter()">
        <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn" (optionSelected)="selectionChangeFunction($event)" [requireSelection]="setAsRequired">
            @for (option of filteredOptions; track option) {
                <mat-option [value]="option">{{option.name}}</mat-option>
            }
        </mat-autocomplete>
    </mat-form-field>
</form>
