<div class="mat-elevation-z8">
    <mat-card>
        <mat-card-content class="card-content-fill">
            <div *ngIf="isPageLoading"
                style="display: flex; justify-content: center; align-items: center; background: white;">
                <mat-progress-spinner color="primary" mode="indeterminate">
                </mat-progress-spinner>
            </div>
            <mat-table [ngClass]="{'hide-element': isPageLoading}" [dataSource]="dataSource" matSort  [matSortActive]="'createdDate'" matSortDirection="desc" matSortDisableClear="true">

                <ng-container matColumnDef="actions">
                    <mat-header-cell *matHeaderCellDef>Actions</mat-header-cell>
                    <mat-cell *matCellDef="let element">
                        <mat-icon class="showDetailsIcon" aria-hidden="false" aria-label="Show Details"
                            matTooltip="Show Details" fontIcon="open_in_new"
                            (click)="showJobDetails(element)"></mat-icon>
                        <mat-icon class="showDetailsIcon" aria-hidden="false" aria-label="Show Errors"
                            matTooltip="Show Errors" fontIcon="error" (click)="showJobErrors(element)"></mat-icon>
                        <button mat-icon-button [disabled]="!shouldShowJobRestart(element)" matTooltip="Restart Failed Job" (click)="showJobRestartDialog(element)">
                            <mat-icon class="showDetailsIcon" aria-hidden="false" aria-label="Restart Failed Job"
                                 fontIcon="restart_alt"></mat-icon>
                        </button>
                        <!-- <mat-icon class="showDetailsIcon" aria-hidden="false" aria-label="Show Errors"
                            matTooltip="Restart Failed Job" fontIcon="restart_alt"
                            (click)="element.status === 'error' ? showJobRestartDialog(element) : null"></mat-icon> -->
                    </mat-cell>
                </ng-container>

                <ng-container *ngFor="let column of dataColumns; index as i" [matColumnDef]="column">
                    <mat-header-cell *matHeaderCellDef mat-sort-header> {{ displayedColumns[i] ?? column }}
                    </mat-header-cell>
                    <mat-cell *matCellDef="let element"> {{element[column]}} </mat-cell>
                </ng-container>

                <mat-header-row
                    *matHeaderRowDef="displayActions ? dataColumnsWithAction : dataColumns"></mat-header-row>
                <mat-row *matRowDef="let row; columns: displayActions ? dataColumnsWithAction : dataColumns;"></mat-row>
            </mat-table>
        </mat-card-content>
        <div *ngIf="isNextPageLoading" style="display: flex; justify-content: center; align-items: center; background: white;">
            <mat-progress-spinner color="primary" mode="indeterminate">
            </mat-progress-spinner>
        </div>        
    </mat-card>
</div>