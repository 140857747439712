import { Injectable } from "@angular/core";
import { MipHttpService, SET_AWS_TOKEN } from "./api.mipHttpService";
import { HttpClient, HttpContext, HttpContextToken, HttpHeaders } from "@angular/common/http";
import { CognitoService } from "./cognito.service";
import { Observable } from "rxjs";

@Injectable({
    providedIn: 'root',
})
export class S3ApiService  {
    constructor(private httpClient: HttpClient) {
    }

    public uploadFileToS3 = (presignedUrl: string, fileData: File): Observable<Object>=>{
        const header =   new HttpHeaders().set("x-amz-server-side-encryption", "AES256");
        return this.httpClient.put(presignedUrl, fileData, { headers: header, context: new HttpContext().set(SET_AWS_TOKEN, false) });
    }
}