import { Component } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogContent, MatDialogModule, MatDialogRef, MatDialog } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { CommonModule } from '@angular/common';
import { MatCardModule } from '@angular/material/card';
import { CognitoService } from '../../Services/cognito.service';
import { Router } from '@angular/router';
import { environment } from '../../../environments/environment';

@Component({
    selector: 'no-tenant-dialog',
    standalone: true,
    imports: [CommonModule, MatCardModule, MatDialogContent, MatDialogModule, MatButtonModule, MatIconModule],
    templateUrl: './no-tenant-dialog.component.html',
    styleUrl: './no-tenant-dialog.component.scss'
})
export class NoTenantDialogComponent {

    mfaHelpDisplayURL: string = environment.MFAHelpDisplayUrl;
    noTenantText: string = environment.NoTenantText;
    noTenantContact: string = environment.NoTenantContact;

    constructor(
        private router: Router, public dialogRef: MatDialogRef<NoTenantDialogComponent>, private cognitoService: CognitoService
    ) {}

    cancel(): void {
        this.dialogRef.close(false);
        this.router.navigate(['login']);
    }

    ngOnDestroy() {
        this.cognitoService.handleSignOut();
    }
}

export function openNoTenantDialog(dialog: MatDialog): MatDialogRef<NoTenantDialogComponent> {
     return dialog.open(NoTenantDialogComponent, {
    });
}

