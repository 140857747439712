export interface tenantFlowSetting {
     settingName: string;
     displayName: string;
     flowType: string;
     stepName: string;
     type: string;
     value: string;
     isReadOnly: boolean;
     tenantId: string;
     flowCode: string;
     subType?: string;
     stepScheduleName?: string;
     stepScheduleDetailType?: string;
     runCadence?: string;
     enableSchedule?: boolean;
     lastModifiedDate: Date;
     lastModifiedUser: string;
}


export interface stringFiterSetting {
     value: string|null;
     comparison: string | null;
}
export interface dateFiterSetting {
     value: string | null;
     startDate: Date | null,
     endDate: Date | null,
     comparison: string | null;
}
export class upsertTenantFlowSetting {
     constructor(public settingName: string, public displayName: string, public flowType: string, public stepName: string,
          public type: string, public value: string, public isReadOnly: boolean, public subType?: string, public stepScheduleName?: string,
          public stepScheduleDetailType?: string, public enableSchedule?: boolean
     ) { }
}