import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogActions, MatDialogContent, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MatExpansionModule } from '@angular/material/expansion';
import { CommonModule } from '@angular/common';
import { MatCardModule } from '@angular/material/card';
import { job } from '../../jobs/models/job.model';
import { TenantAPIService } from '../../../Services/api.tenantApi.service';
import { ExportableFilesComponent } from '../../../Shared/exportable-files/exportable-files.component';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { JobItemErrorTableComponent } from '../job-item-error-table/job-item-error-table.component';
import { jobItem } from '../../jobs/models/jobitem.model';
import { PaginatedResponse } from '../../../Shared/models/paginatedResponse.model';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';

@Component({
  selector: 'app-job-search-error-dialog',
  standalone: true,
  imports: [
    CommonModule,
    MatCardModule,
    MatDialogModule,
    MatDialogContent,
    MatDialogActions,
    MatExpansionModule,
    ExportableFilesComponent,
    MatGridListModule,
    MatProgressSpinnerModule,
    JobItemErrorTableComponent,
    MatIconModule,
    MatButtonModule],
  templateUrl: './job-search-error-dialog.component.html',
  styleUrl: './job-search-error-dialog.component.scss'
})
export class JobSearchErrorDialogComponent {

  job: job;
  jobItemsLoaded: boolean = false;
  isJobCompleted: boolean = false;

  // Table data
  jobItems: jobItem[] = [];
  dataColumns: string[] = ['jobItemStatus', 'errorCode', 'errorMessage']; //data properties to display
  displayedColumns: string[] = ['Item Status', 'Error Code', 'Error Message']; //pretty job item names to display

  constructor(
    public dialogRef: MatDialogRef<JobSearchErrorDialogComponent>,
    private jobsAPI: TenantAPIService,
    @Inject(MAT_DIALOG_DATA) public data: job
  ) {
    this.job = data;
  }

  ngOnInit() {
    if (!this.isJobActive(this.job) && this.job.jobId != undefined) {
      this.isJobCompleted = true;
    }
  }

  ngAfterViewInit() {
    this.getJobItemsNeedsReviewFromAPI(this.job.jobId);
  }

  getJobItemsNeedsReviewFromAPI(jobId?: string){
    this.jobsAPI.getJobItemsNeedsReview(jobId).subscribe({
      next: (data: PaginatedResponse<jobItem>) => {
        data.data.forEach(obj => {
          if(obj.errorCode === null){
            obj.errorCode = "Unspecified"
          }
          if(obj.errorMessage === null) {
            obj.errorMessage = "Unspecified Error Occured"
          }
        })
        this.jobItems = data.data;
      },
      error: (result: any) => { console.log(result) },
      complete: () => {
        this.jobItemsLoaded = true;
      }
    });
  }

  isJobActive(job?:job):boolean{
    return job != null && (job.jobStatus === "New" || job.jobStatus === "Active" )
  }
}