import { Component } from '@angular/core';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { CommonModule, JsonPipe } from '@angular/common';
import { MatCardModule } from '@angular/material/card';
import { tenantSetting } from '../../../Models/Class-Interfaces/tenantSetting.model';
import { TenantAPIService } from '../../../Services/api.tenantApi.service';
import { CredentialComponent } from '../credential/credential.component';
import { MatListModule } from '@angular/material/list';
import { MatDividerModule } from '@angular/material/divider';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-credentials',
  standalone: true,
  imports: [CommonModule, CredentialComponent, MatListModule,MatProgressSpinnerModule, MatCardModule,MatDividerModule],
  templateUrl: './credentials.component.html',
  styleUrl: './credentials.component.scss'
})
export class CredentialsComponent {
  loading: boolean = false;
  credentials: tenantSetting[]=[]

  constructor(private activatedRoute: ActivatedRoute, private tenantAPIService: TenantAPIService) { }

  ngOnInit() {
 
      this.loading = true;
      let accessToken = this.activatedRoute.snapshot.queryParams['accessToken'];
      this.tenantAPIService.getTenantSetting().subscribe({
        next: (result: tenantSetting[]) => {
          this.credentials = result.filter((item) => item.type === 'secret');
        },
        error: (result: any) => { console.log(result) },
        complete: () => { this.loading = false; }
      });
    
  }

}
