import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterOutlet } from '@angular/router';
import { AmplifyAuthenticatorModule } from '@aws-amplify/ui-angular';
import { HeaderComponent } from './Header/header.component';
import { CognitoService } from './Services/cognito.service';
import { MatSidenavModule } from '@angular/material/sidenav';
import { NavBarComponent } from './Navigation/Nav-bar/nav-bar.component';
import { AuthenticatorService } from '@aws-amplify/ui-angular';
import { LoginComponent } from './Pages/login/login.component';
import { TenantInfoComponent } from './Header/tenant-info/tenant-info.component';
import { FooterComponent } from './footer/footer.component';

@Component({
    selector: 'app-root',
    standalone: true,
    imports: [FooterComponent, HeaderComponent, CommonModule, RouterOutlet, AmplifyAuthenticatorModule, MatSidenavModule, NavBarComponent, AmplifyAuthenticatorModule, LoginComponent, TenantInfoComponent],
    templateUrl: './app.component.html',
    styleUrl: './app.component.scss',
})
export class AppComponent {
    title = 'MindexIntegrationsClient';
    constructor(private cognitoService: CognitoService, public authenticator: AuthenticatorService) {
        const bodyElement = document.body;
        bodyElement.classList.add('payx-setting');
    }
}
