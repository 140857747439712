export const constants = {
    notification: {
        dialog: {
            onAction: {
                title: "Other Notifications to Reset",
                message: "Based on the changes you saved, you may want to reset the currently configured E-mail Notification statuses.<br/><br/>By resetting, all messages that are marked as already sent will be sent again under the same conditions."
            },
            onReset: {
                title: "Reset E-mail Notification Statuses",
                message: "Are you sure you want to reset the currently configured E-mail Notification statuses?<br/><br/>By resetting, all messages that are marked as already sent will be sent again under the same conditions."
            }
        },
        resetSuccess: "Reset Notification Statuses.",
        resetFailure: "Failed to Reset Notification Statuses."
    },
    settings: {
        saveSuccess: "Updated Configuration",
        saveSuccessWithNotifications: "Updated Configuration and Reset Notifications",
        saveFailure: "Failed to Update Configuration"
    },
    common: {
        buttons: {
            reset: "Reset",
            ok: "OK",
            cancel: "Cancel"
        },
        updated: "Updated",
        error: "Error"
    }
}