import { Component, Inject } from '@angular/core';
import { ConfirmationDialogComponent } from '../../../Shared/confirmation-dialog/confirmation-dialog.component';
import { TenantAPIService } from '../../../Services/api.tenantApi.service';
import { JobStatus, job, retryJobRequest, retryJobResponse } from '../../jobs/models/job.model';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { JobDetailsDialogComponent } from '../job-details-dialog/job-details-dialog.component';

export interface JobRetryDialogData {
  title: string;
  text: string;
  job: job;
}

@Component({
  selector: 'app-job-retry-dialog',
  standalone: true,
  imports: [ConfirmationDialogComponent],
  templateUrl: './job-retry-dialog.component.html',
  styleUrl: './job-retry-dialog.component.scss'
})

export class JobRetryDialogComponent {
  private retryJobResponseJobId: string | undefined;
  activeJobRetryEvent: Subject<string> = new Subject<string>();

  constructor(private jobsAPI: TenantAPIService, private dialog: MatDialog, private router: Router, @Inject(MAT_DIALOG_DATA) public data: JobRetryDialogData) {
  };

  handleConfirmationAcceptEvent(accepted: boolean): void {
    if (accepted) {
      this.retryJobById(this.data.job.jobId ?? "");
    }
  }

  retryJobById = (id: string): void => {
    const request: retryJobRequest = {
      jobId: id
    };

    // this.loaded = false;
    this.jobsAPI.retryJob(request).subscribe({
      next: (response: retryJobResponse) => {
        this.retryJobResponseJobId = response.jobId;
        this.data.job.jobStatus = JobStatus.Active;
        this.showJobDetails(this.data.job)
      },
      error: (result: any) => {
        console.log(result);
      },
      complete: () => {
        if(this.retryJobResponseJobId !== undefined){
        }
      }
    });
  }

  showJobDetails(element?: job) {
    const dialogRef = this.dialog.open(JobDetailsDialogComponent, {
      data: element,
    });
  }
}