import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatGridListModule } from '@angular/material/grid-list';
import { TenantAPIService } from '../../Services/api.tenantApi.service';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatSelectModule } from '@angular/material/select';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatIconModule } from '@angular/material/icon';
import { forkJoin, Subject, tap } from 'rxjs';
import { initiateTableSettingsTransferResponse } from './models/initiateTransferResponse.model';
import { S3ApiService } from '../../Services/s3ApiService';
import { JobInfoCardComponent } from '../jobs/job-info-card/job-info-card.component';
import { MatDividerModule } from '@angular/material/divider';
import { tenantSetting } from '../../Models/Class-Interfaces/tenantSetting.model';
import { tenantFlowResult } from '../integration/models/integration-models';
import { tenantFlowSetting } from '../../Models/Class-Interfaces/tenantFlowSetting.model';
import { FlowSettingsService } from '../../Services/api.flowSettings.service';
import { flowDefinitionSetting } from '../jobs/models/flowDefinitionSetting';
import { cancelTransferResponse } from '../transfer/models/cancelTransferResponse.model';
import { ResetNotificationsService } from '../../Services/reset-notifications.service';

@Component({
    selector: 'app-table-settings',
    standalone: true,
    imports: [CommonModule, MatCardModule, MatGridListModule, MatSelectModule, MatFormFieldModule, MatButtonModule, MatProgressSpinnerModule, MatIconModule, JobInfoCardComponent, MatDividerModule],
    templateUrl: './table-settings.component.html',
    styleUrl: './table-settings.component.scss',
})
export class TableSettingsComponent {
    @Input() integration: tenantFlowResult | null;
    tenantFlowSettings: tenantFlowSetting[] = [];
    flowDefinitionSettings: flowDefinitionSetting[] = [];
    tableSettingsLoaded: boolean = false;
    tableSettings: tenantSetting[] = [];
    selectedTableSettings: tenantSetting | null = null;
    fileName: string = "";
    selectedFile: File | null = null;
    activeTransferJobId: string | null = null;
    transferProcessing: boolean = false;
    noItemSelectedText: string = "No Table Settings Selected";
    noItemSelectedMessage: string = "Once you start the Table Settings Upload, the Job ID and status will display here";
    activeJobEvent: Subject<string> = new Subject<string>();

    constructor(
        private flowSettingsAPI: FlowSettingsService, 
        private tenantAPI: TenantAPIService, 
        private s3APIService: S3ApiService,
        private resetNotificationsService: ResetNotificationsService
    ) { }

    ngOnInit() {
        // Cross reference our flowDefinitionSettings for the tenant flow with our tenant settings to scope the table settings.
        if (this.integration != null) {
            forkJoin([
                this.flowSettingsAPI.getTenantFlowDefinitionSettings(this.integration.flowCode, this.integration.version),
                this.tenantAPI.getTenantSetting()
            ])
            .subscribe({
                next: (obsResults) => {
                    this.flowDefinitionSettings = obsResults[0].filter((item) => item.type === 'table');  

                    // Extract names from the filtered flowDefinitionSettings
                    const flowNames = this.flowDefinitionSettings.map(item => item.settingName);
                    this.tableSettings = obsResults[1].filter((item) => item.type === 'table');

                    // Further filter tableSettings to include only those whose names are in flowDefinitionSettings
                    this.tableSettings = this.tableSettings.filter((item) => flowNames.includes(item.settingName));    
                },
                error: (result: any) => { console.log(result); },
                complete: () => { this.tableSettingsLoaded = true; }
            });
        }
    }

    onFileSelected = (event: any): void => {

        this.selectedFile = event.target.files[0];

        if (this.selectedFile) {
            this.fileName = this.selectedFile.name;
            const formData = new FormData();
            formData.append("file", this.selectedFile);
        }
    }

    canSubmit = (): boolean => {
        return this.selectedTableSettings != null && this.selectedTableSettings !== undefined
            && this.selectedFile !== null && this.selectedFile !== undefined
            && !this.transferProcessing;
    }

    uploadFile = (): void => {
        const fileUpload$ = this.tenantAPI.intitateTableSettingsTransfer;
        const uploadToS3$ = this.s3APIService.uploadFileToS3;
        const processTransfer$ = this.tenantAPI.processTransfer;

        const formData = new FormData();
        if (this.selectedFile && this.selectedTableSettings) {
            formData.append("file", this.selectedFile);
            this.transferProcessing = true;
            fileUpload$(this.selectedTableSettings?.settingName, this.selectedFile.name, this.selectedFile.type).subscribe({
                next: (response: initiateTableSettingsTransferResponse) => {
                    this.activeTransferJobId = response.jobId;
                    this.activeJobEvent.next(this.activeTransferJobId);
                    uploadToS3$(response.uploadUrl, this.selectedFile!).subscribe({
                        next: () => {
                            processTransfer$(this.activeTransferJobId!).subscribe({
                                error: this.transferErrored,
                                complete: () => {
                                    this.resetNotificationsService.promptResetNotificationOnAction(this.integration!.flowCode, (resetNotifications) => {
                                        if (resetNotifications === true) {
                                            this.resetNotificationStatuses();
                                        }
                                        this.transferCompleted();
                                    });
                                }
                            })
                        },
                        error: this.transferErrored
                    },
                    );
                }, error: this.transferErrored
            })
        }
        else {
            console.log("Unable to upload file");
        }
    }

    transferErrored = () => {
        console.log("Transfer Errored");

        if (!this.activeTransferJobId) {
            this.transferCompleted();
            return;
        }

        console.log("Cancelling Transfer ", this.activeTransferJobId);

        const cancelTransfer$ = this.tenantAPI.cancelTransfer;

        cancelTransfer$(this.activeTransferJobId!).subscribe({
            next: (result: cancelTransferResponse) => {
                console.log("Cancelled Transfer ", result.jobId);
                this.transferCompleted();
            },
            error: () => {
                console.log("Unable to cancel transfer");
                this.transferCompleted;
            },
            complete: this.transferCompleted
        })
    }
    transferCompleted = () => {
        this.transferProcessing = false;
    }
    getActiveJobId = () => {
        return this.activeTransferJobId ?? "";
    }

    resetNotificationStatuses = () => {
        this.resetNotificationsService.resetNotifications(this.integration!.flowCode)
        .subscribe({
          complete: () => { 
            this.resetNotificationsService.onSuccess();
           },
          error: (err) => {
            console.log(err);
            this.resetNotificationsService.onFailure();
          }
        });    
      }
}
