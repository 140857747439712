import { HttpClient, HttpContextToken, HttpHeaders, HttpParams } from '@angular/common/http';
import { CognitoService } from './cognito.service';
import { environment } from '../../environments/environment'
export const SET_AWS_TOKEN = new HttpContextToken(() => true);
export abstract class MipHttpService {
  private baseURI: string;
  constructor(private cognitoService: CognitoService, private httpClient: HttpClient) {
    console.log(environment);
    let e = environment;
    this.baseURI = environment.BaseAPIUrl;
  }

  // Handles api calls that return a string instead of a json object  
  public getResultAsString(path: string, parameters?: { [param: string]: string | number | boolean | readonly (string | number | boolean)[]; }) {
    let uri: string = this.buildURI(path);
    let header: HttpHeaders = this.buildHeaders();
    var params = new HttpParams()
    return this.httpClient.get(uri, { headers: header, params: params,reportProgress: false, responseType: 'text' });
  }

  public get<type>(path: string, parameters?: { [param: string]: string | number | boolean | readonly (string | number | boolean)[]; }) {
    let uri: string = this.buildURI(path);
    let header: HttpHeaders = this.buildHeaders();
    let params = new HttpParams();

    // For each defined query parameter, append it to the paramaters array.
    if(parameters){
      Object.keys(parameters).forEach(key => {
        if(parameters[key] !== null && parameters[key] !== undefined){
          params = params.append(key, parameters[key].toString())
        }
      })
    }

    return this.httpClient.get<type>(uri, { headers: header, params: params });
  }
  public post<type>(path: string, body: string) {
    let uri: string = this.buildURI(path);
    let header: HttpHeaders = this.buildHeaders();
    var params = new HttpParams()
    return this.httpClient.post<type>(uri, body, { headers: header });
  }

  public put<type>(path: string, body: string) {
    let uri: string = this.buildURI(path);
    let header: HttpHeaders = this.buildHeaders();
    var params = new HttpParams()
    return this.httpClient.put<type>(uri, body, { headers: header });
  }

  private buildHeaders(): HttpHeaders {  
    let headers = new HttpHeaders().set("Content-Type","application/json");
    return headers;
  }
  
  private buildURI(path: string): string {
    return this.baseURI + path;
  }
}