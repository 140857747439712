<app-header class="payx-setting"></app-header>
<mat-sidenav-container matSidenav class="main payx-setting" *ngIf="authenticator.route === 'authenticated'">
    <mat-sidenav class="nav-bar" mode="side" opened [disableClose]="true">
        <app-nav-bar></app-nav-bar>
    </mat-sidenav>
    <mat-sidenav-content class="nav-content">
        <app-tenant-info></app-tenant-info>
        <router-outlet></router-outlet>
    </mat-sidenav-content>
</mat-sidenav-container>
<app-footer></app-footer>
<app-login *ngIf="authenticator.route !== 'authenticated'"></app-login>
