import { Injectable } from '@angular/core';
import {
    getCurrentUser,
    GetCurrentUserOutput,
    fetchAuthSession,
    fetchUserAttributes,
    FetchUserAttributesOutput,
    AuthSession
} from 'aws-amplify/auth';
import { SessionService } from './session.service';
import { AuthenticatorService } from '@aws-amplify/ui-angular';
import { Router } from '@angular/router';

@Injectable({
    providedIn: 'root',
})

export class CognitoService {
    constructor(private sessionService: SessionService, private authenticatorService: AuthenticatorService,private router:Router) {
        this.isUserLoggedIn=false;
        this.currentUserName = "";
        this.currentUserFirstName = "";
        this.currentUserLastName = "";
        this.token ="";      

    }
    isUserLoggedIn: boolean;
    currentUserName: string;
    currentUserFirstName: string;
    currentUserLastName: string;
    token:string;
    handleSignOut(): void {
        this.authenticatorService.signOut();
    }

    isUserAuthenticated(): boolean {
        return this.isUserLoggedIn && this.token !== undefined && this.currentUserName !== undefined;
    }

    async getUserAttributes(): Promise<FetchUserAttributesOutput> {
        try {
            const { name, family_name } = await fetchUserAttributes();
            return { name, family_name };
        } catch (error) {
          console.error('Error fetching user attributes:', error);
          throw error;
        }
      }
      

    refreshUserToken(): Promise<void> {
        return new Promise<void>((resolve, reject) => {
            fetchAuthSession({ forceRefresh: true }).then(() => {
                resolve();
            }).catch((e) => {
                reject(e);
            });
        });
    }

    currentAuthenticatedUser(): Promise<GetCurrentUserOutput> {
        return new Promise<GetCurrentUserOutput>((resolve, reject) => {
            getCurrentUser()
                .then(({ username, userId, signInDetails }) => {

                    if (!signInDetails) {
                        this.sessionService.getSessionUserName().then((userName) => {
                            this.currentUserName = userName;
                        });
                    } else {
                        this.currentUserName = signInDetails?.loginId ?? "";
                        this.sessionService.setSessionUserName(this.currentUserName);
                    }

                    if(!this.token){
                        this.getToken().then((token) => {

                            this.isUserLoggedIn = true;
                            resolve({ username, userId, signInDetails });
                        });
                    }
                    resolve({ username, userId, signInDetails });               
                })
                .catch((e) => {
                    reject(e);
                });
        });
    }
   
    async getToken():Promise<string>
    {
        var result = await fetchAuthSession();
        if (result.tokens?.idToken != null ){
            return result.tokens?.idToken?.toString() 
        }
        else {
            this.authenticatorService.signOut();
          throw new Error("Session is invalid");
        }
    }

    wasUserSignedIn(): Promise<boolean> {
        return new Promise<boolean>((resolve, reject) => {
            if (this.isUserLoggedIn || this.currentUserName != "") {
                resolve(true);
            } else {
                resolve(false);
            }
        });
    }
    onAuthEvent = (payload:any)=>{
            switch (payload.event) {
                case 'signedIn':
                    console.log('user have been signedIn successfully.');
                    this.currentUserName = payload.data.signInDetails?.loginId ?? "";
                    this.sessionService.setSessionUserName(this.currentUserName);
                    this.getToken().then((token) => {
                        this.token = token;
                        this.isUserLoggedIn = true;                 

                    });
                    break;
                case 'sessionExpired':
                case 'signedOut':
                    console.log('user have been signedOut successfully.');
                    this.isUserLoggedIn = false;
                    this.currentUserName = "";
                    this.token = "";
                   // this.router.navigate(['login']);
                    break;
                case 'tokenRefresh':
                    console.log('auth tokens have been refreshed.');
                    this.getToken().then((token) => {
                        this.token = token;
                        this.isUserLoggedIn = true;
                    });
                    break;
            }
    }
}
