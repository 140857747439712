import { Injectable } from "@angular/core";
import { MipHttpService } from "./api.mipHttpService";
import { CognitoService } from "./cognito.service";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { emailSettings, emailSettingsRequest } from "../Pages/email-notification-settings/models/emailSettings.model";

@Injectable({
    providedIn: 'root',
})
export class EmailNotificationService extends MipHttpService {
    constructor(cognitoService: CognitoService, httpClient: HttpClient) {
        super(cognitoService, httpClient);
    }

    public getEmailNotificationSettings(category: string): Observable<emailSettings> {
        return this.get<emailSettings>(`notifications/settings/${category}`);
    }

    public postEmailNotificationSettings(category: string, emailSettingsRequest: emailSettingsRequest): Observable<void> {
        return this.put<void>(`notifications/settings/${category}`, JSON.stringify(emailSettingsRequest));
    }
}