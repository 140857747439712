import { ChangeDetectorRef, Component, HostListener } from '@angular/core';
import { TenantAPIService } from '../../Services/api.tenantApi.service';
import { JobStatus, job } from '../jobs/models/job.model';
import { CommonModule, formatDate } from '@angular/common';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatDividerModule } from '@angular/material/divider';
import { FormBuilder, FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatCardModule } from '@angular/material/card';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatIconModule } from '@angular/material/icon';
import { PaginatedResponse } from '../../Shared/models/paginatedResponse.model';
import { MatButtonModule } from '@angular/material/button';
import { MatExpansionModule } from '@angular/material/expansion';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';
import { jobType } from '../jobs/models/jobtypes.model';
import { JobSearchTableComponent } from './job-search-table/job-search-table.component';
import { MatDialog } from '@angular/material/dialog';
import { JobDetailsDialogComponent } from './job-details-dialog/job-details-dialog.component';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';

@Component({
  selector: 'app-job-search',
  standalone: true,
  imports: [CommonModule, ReactiveFormsModule, JobSearchTableComponent, MatProgressSpinnerModule, MatExpansionModule, MatDividerModule, FormsModule, MatInputModule, MatSelectModule, MatFormFieldModule, MatProgressSpinnerModule, MatGridListModule, MatButtonModule, MatIconModule, MatDatepickerModule, MatNativeDateModule, MatDividerModule, MatCardModule, InfiniteScrollModule],
  templateUrl: './job-search.component.html',
  styleUrl: './job-search.component.scss'
})
export class JobSearchComponent {
  jobSubTypes: jobType[] = [];
  jobStatuses = Object.values(JobStatus); // Get all the valid statuses for a job.

  // Filter Panel
  isFilterPanelExpanded: boolean = false;

  // Table data
  jobs: job[] = [];
  paginationToken: string | undefined = undefined;
  dataColumns: string[] = ['jobId', 'jobType', 'jobName', 'jobStatus', 'createdDate']; //data properties to display
  displayedColumns: string[] = ['Job Id', 'Job Type', 'Job Name', 'Job Status', 'Created Date']; //pretty job names to display
  displayActions: boolean = true; //determines if the action column will show up 
  maxPageSize: number = 200;
  hasMoreResults: boolean = true;

  lastJobId: string | undefined = undefined;
  lastJobType: string | undefined = undefined;
  lastJobSubType: string | undefined = undefined;
  lastJobStatus: JobStatus | undefined = undefined;
  lastPageSize: number | undefined = undefined;

  // State Tracking Variables
  isJobSubTypesLoading: boolean = true;
  isJobTypesLoading: boolean = true; // TODO when we have backend endpoint hookup.
  isNextPageLoading: boolean = false; // Used for loading the next page of results after the initial search
  isPageLoading: boolean = false;// Used for the table spinner. Spinner exists on table component two-way binding.
  hasSearched = false; // Used for the splash screen message when the table is empty.

  // Form Group Filter Fields
  searchJobsFormGroup: FormGroup;
  selectedJobIdToFilter: string | null = null;
  selectedJobStatusToFilter: JobStatus | null = null;
  selectedJobTypeToFilter: string | null = null;
  selectedJobSubTypeToFilter: string | null = null;

  constructor(private router: Router, private route: ActivatedRoute, private dialog: MatDialog, private formBuilder: FormBuilder, private jobsAPI: TenantAPIService, private cdr: ChangeDetectorRef) {
    this.searchJobsFormGroup = this.formBuilder.group({
      selectedJobIdToFilter: new FormControl(),
      selectedJobStatusToFilter: new FormControl(),
      selectedJobTypeToFilter: new FormControl(),
      selectedJobSubTypeToFilter: new FormControl()
    })
    this.router = router;
    this.setSearchJobsFormValidators();
  }

  // ===============
  // LIFECYCLE HOOKS
  // ===============

  ngOnInit() {
    this.GetTenantJobTypes();

    // Handle any queryparams
    this.route.queryParams.subscribe(params => {
      if (params['jobID'] && this.searchJobsFormGroup.get('selectedJobIdToFilter') !== null) {
        // Set the form values to the queryParam values
        this.searchJobsFormGroup.get('selectedJobIdToFilter')?.setValue(params['jobID'], { emitEvent: false });
        this.isFilterPanelExpanded = true;

        // Just search for jobs, don't update query filters / route.
        this.getJobs();
      }
    });
  }

  showJobDetailsDialog(element?: job) {
    const dialogRef = this.dialog.open(JobDetailsDialogComponent, {
      data: element,
    });
  }


  // ===============
  // API Calls
  // ===============

  GetTenantJobTypes() {
    this.isJobSubTypesLoading = true;
    this.jobsAPI.getJobTypes().subscribe({
      next: (data: jobType[]) => {
        this.jobSubTypes = data;
      },
      error: (result: any) => { console.log(result) },
      complete: () => this.isJobSubTypesLoading = false
    });
  }

  GetJobsFromAPI(jobId?: string, jobType?: string, jobSubType?: string, jobStatus?: JobStatus, pageSize?: number, paginationToken?: string) {
    this.isPageLoading = paginationToken ? false : true;
    this.isNextPageLoading = paginationToken ? true : false;
    this.lastJobId = jobId;
    this.lastJobType = jobType;
    this.lastJobSubType = jobSubType;
    this.lastJobStatus = jobStatus;
    this.lastPageSize = pageSize;

    this.jobsAPI.getJobs(jobId, jobType, jobSubType, jobStatus, pageSize, paginationToken).subscribe({
      next: (data: PaginatedResponse<job>) => {
        data.data.forEach(obj => {
          obj.jobName = this.jobSubTypes.filter(x => x.jobTypeId == obj.jobSubType)[0]?.name ?? obj.jobName;
          obj.createdDate = formatDate(obj.createdDate ?? new Date(), 'MMM dd YYYY h:mm a', 'en-US');
        });
        //Append the last page results to this result, update the token, and check if we have more results to process
        //on the last page.
        this.jobs = paginationToken ? [...this.jobs, ...data.data ] : data.data;
        this.paginationToken = data.paginationToken ?? undefined;
        this.hasMoreResults = data.data.length == this.maxPageSize && this.paginationToken !== undefined;
      },
      error: (result: any) => { 
        this.isPageLoading = false; 
        this.isNextPageLoading = false;
        console.log(result);
      },
      complete: () => {
        this.isPageLoading = false;
        this.isNextPageLoading = false;
        this.cdr.detectChanges();
      }
    });
  }

  // ===============
  // FORM STUFF
  // ===============
  updateQueryFilters() {
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: { jobID: this.searchJobsFormGroup.get('selectedJobIdToFilter')?.value },
      queryParamsHandling: 'merge',
    })
  }

  setSearchJobsFormValidators() {
    this.searchJobsFormGroup.controls["selectedJobIdToFilter"].addValidators([Validators.pattern(new RegExp('^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$'))])
  }

  // Could pass formId or value here, but we only have one form on this page.
  onSearchJobsFormSubmit(event: SubmitEvent | MouseEvent) {
    if (event instanceof MouseEvent) {
      event.stopPropagation(); // Don't let the click toggle the expansion panel state.
    }
    if (this.searchJobsFormGroup.valid) {
      this.updateQueryFilters();
      this.getJobs();
    }
  }

  getJobs() {
    if (this.searchJobsFormGroup.valid) {
      this.GetJobsFromAPI(
        this.searchJobsFormGroup.value['selectedJobIdToFilter'], // TODO hit different endpoint for backend query efficiency OR change backend to use different functionality.
        this.searchJobsFormGroup.value['selectedJobTypeToFilter'],
        this.searchJobsFormGroup.value['selectedJobSubTypeToFilter']?.jobTypeId.toString(),
        this.searchJobsFormGroup.value['selectedJobStatusToFilter']?.toString(),
        this.maxPageSize,
        undefined);

      this.hasSearched = true; // Remove tooltip.

      // TODO dynamo infinite scroll functionality / paginationTokens, etc.
      // After discussion, we will fetch 1000 records from dynamo until later when we decide how to handle pagination.
      // TODO see how pagination will effect sorting, filtering, pagination. (Get first N records from dynamo sorted by most recent?)
    }
    else {
      console.log("Job Search Form Invalid.");
    }
  }

  onGetNextPage() {
    if(this.hasMoreResults && !this.isPageLoading && !this.isNextPageLoading) {
      this.GetJobsFromAPI(
        this.lastJobId,
        this.lastJobType,
        this.lastJobSubType,
        this.lastJobStatus,
        this.lastPageSize,
        this.paginationToken
      );
    }
  }

  onScroll() {
    this.onGetNextPage();
  }
}