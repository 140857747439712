<div class="app-confirmation-dialog">
    <div class="app-confirmation-dialog-content">
        <div class="dialog-title-container {{headerClass}}">
            <h2 mat-dialog-title>{{ title }}</h2>
            <button mat-icon-button mat-dialog-close class="button-close button-dialog-close" (click)="close()" [disableRipple]="true">
                <mat-icon>close</mat-icon>
            </button>            
        </div>
        <mat-divider></mat-divider>
        <mat-dialog-content class="dialog-content-container">
            <p *ngIf="subtitle != null" class="mip-mat-dialog-subtitle" [innerHTML]="subtitle"></p>
            <p class="mip-mat-dialog-text" [innerHTML]="text"></p>
        </mat-dialog-content>
        <mat-dialog-actions>
            <button mat-stroked-button mat-dialog-close class="button-secondary">{{cancelText}}</button>
            <button mat-flat-button [class]="buttonClass" cdkFocusInitial (click)="accept()">{{okText}}</button>
        </mat-dialog-actions>
    </div>
</div>
