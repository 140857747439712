import { Component, EventEmitter, Input, Output, SimpleChanges, ViewChild, Inject } from '@angular/core';
import { MatPaginator, MatPaginatorModule } from '@angular/material/paginator';
import { MatSort, MatSortModule } from '@angular/material/sort';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatTooltipModule } from '@angular/material/tooltip';
import { jobItem } from '../../jobs/models/jobitem.model';
import { JobItemErrorDetailsDialogComponent, JobItemErrorDetailsDialogData } from '../job-item-error-details-dialog/job-item-error-details-dialog.component';
import { job } from '../../jobs/models/job.model';

@Component({
  selector: 'app-job-item-error-table',
  standalone: true,
  imports: [CommonModule, MatTableModule, MatPaginatorModule, MatSortModule, MatProgressSpinnerModule, MatCardModule, MatIconModule, MatButtonModule, MatTooltipModule],
  templateUrl: './job-item-error-table.component.html',
  styleUrl: './job-item-error-table.component.scss'
})
export class JobItemErrorTableComponent {
  @Input() tableData: jobItem[] = [];
  @Input() displayActions: boolean = true;
  dataColumns: string[] = ['jobItemStatus', 'errorCode', 'errorMessage']; //data properties to display
  displayedColumns: string[] = ['Item Status', 'Error Code', 'Error Message']; //pretty job item names to display
  dataColumnsWithAction: string[] = [];

  dataSource = new MatTableDataSource<jobItem>;

  @Input() isLoading: boolean = false;
  @Output() isLoadingChange: EventEmitter<boolean> = new EventEmitter<boolean>();

  // Bindings
  setLoadingState(isLoading: boolean): void {
    this.isLoading = isLoading;
    this.isLoadingChange.emit(this.isLoading);
  }

  // Sort and Pagintor are set in Angular's view query system and thus must be declared optional here. They are not created on constuction.
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator | undefined;
  @ViewChild(MatSort, { static: false }) sort: MatSort | undefined;

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['tableData']) {
      this.UpdateDataSource();
    }
  }

  ngOnInit() {
    if (this.displayActions) {
      this.dataColumnsWithAction = [...this.dataColumns, 'actions'];
    }
  }

  ngAfterViewInit() {
    this.UpdateDataSource();
  }

  UpdateDataSource() {
    this.dataSource = new MatTableDataSource(this.tableData);
    if (this.paginator) {
      this.dataSource.paginator = this.paginator;
    }

    if (this.sort) {
      // If sorting fails, make sure you are not removing the table from the DOM via ngIf or similar. Use [hidden] instead.
      this.dataSource.sort = this.sort;
      this.dataSource.sortingDataAccessor = (item: any, property) => {
        if (!isNaN(Date.parse(item[property]))) {
          return new Date(item[property]);
        }
        else {
          return item[property];
        }
      }
    }
  }

  constructor(private dialog: MatDialog, private route: ActivatedRoute, @Inject(MAT_DIALOG_DATA) public data: job) { }

  showJobItemErrorDetails(jobItem: jobItem) {
    const dialogData: JobItemErrorDetailsDialogData = {
      job: this.data,
      jobItem: jobItem
    };

    const dialogRef = this.dialog.open(JobItemErrorDetailsDialogComponent, {
      data: dialogData,
    });
  }
}