<app-header class="payx-setting" *ngIf="!isCallbackRoute"></app-header>

<mat-sidenav-container matSidenav class="main payx-setting" *ngIf="authenticator.route === 'authenticated' && !isCallbackRoute">
    <mat-sidenav class="nav-bar" mode="side" opened [disableClose]="true" [ngSwitch]="navigationBarType">
        <app-nav-bar-offering *ngSwitchCase="NavigationBarType.Offering"></app-nav-bar-offering>
        <app-nav-bar-integration *ngSwitchCase="NavigationBarType.Integration"></app-nav-bar-integration>
    </mat-sidenav>
    <mat-sidenav-content class="nav-content">
        <app-tenant-info></app-tenant-info>
        <router-outlet></router-outlet>
    </mat-sidenav-content>
</mat-sidenav-container>

<app-footer *ngIf="!isCallbackRoute"></app-footer>

<app-login *ngIf="!isCallbackRoute && authenticator.route !== 'authenticated'"></app-login>
<app-callback *ngIf="isCallbackRoute"></app-callback>
