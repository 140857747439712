<div class="dialog-container-common">
    <mat-card-header>
        <mat-card-title class="dialog-title-common">{{title}}</mat-card-title>
        <button class="disable-dialog-close-icon-button-container" mat-icon-button mat-dialog-close aria-label="Close"
            (click)="cancel()">
            <mat-icon class="disable-dialog-close-icon">close</mat-icon>
        </button>
    </mat-card-header>

    <div class="form-container">
        <form [formGroup]="tableSettingForm">
            <mat-dialog-content #dialogContent class="dialog-content-common">
                <div *ngFor="let column of selectedTableSettingColumns;" #inputContainers class="input-container">
                    <div class="label-and-asterisk-container">
                        <mat-label>{{column.displayName}}</mat-label>
                        <span class="field-required" *ngIf="column.required">*</span>
                    </div>
                    <ng-container *ngIf="column.type === 'bool'; else nonBoolField">
                        <mat-form-field class="type-select-field">
                            <mat-select placeholder="Select One" [formControlName]="column.settingName"
                                [required]="column.required" [disabled]="submittingForm">
                                <mat-option value="1">Yes</mat-option>
                                <mat-option value="0">No</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </ng-container>

                    <ng-template #nonBoolField>
                        <mat-form-field>
                            <input matInput [type]="column.type" [formControlName]="column.settingName"
                                [required]="column.required" [disabled]="submittingForm" />
                        </mat-form-field>
                    </ng-template>
                    <mat-error
                        class="input-validation-error-message"
                        *ngIf="
                        tableSettingForm.get(column.settingName)?.hasError('required') &&
                        (tableSettingForm.get(column.settingName)?.touched ||
                            tableSettingForm.get(column.settingName)?.dirty)
                        "
                    >
                        This field is required
                    </mat-error>
                </div>
            </mat-dialog-content>

            <hr class="footer-divider" />

            <mat-dialog-actions class="dialog-actions-common">
                <button class="close-button cancel-button" mat-button (click)="cancel()">Cancel</button>
                <button mat-flat-button type="submit" class="save-button" [disabled]="cannotSubmit()"
                    (click)="onSubmit()">Save</button>
                <mat-spinner class="mdc-card__actions-spinner" [diameter]="20" id="save-spinner"
                    *ngIf="submittingForm"></mat-spinner>
            </mat-dialog-actions>

        </form>
    </div>
</div>