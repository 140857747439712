import { Component, Input, } from '@angular/core';
import { tenantSetting } from '../../../Models/Class-Interfaces/tenantSetting.model';
import { TenantAPIService } from '../../../Services/api.tenantApi.service';
import { FormBuilder, FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatDividerModule } from '@angular/material/divider';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { MIPNotification, NotificationsService } from '../../../Shared/Notifications/notifications.service';
import { MIPNotificationType } from '../../../Shared/Notifications/mip-notification-type.enum';
import { ActivatedRoute, Router } from '@angular/router';
import { MatProgressSpinner } from '@angular/material/progress-spinner';
import { environment } from '../../../../environments/environment';
import { MatTooltipModule } from '@angular/material/tooltip';

@Component({
  selector: 'app-credential',
    standalone: true,
    imports: [CommonModule, ReactiveFormsModule, MatTooltipModule ,MatProgressSpinner, FormsModule, MatInputModule, MatFormFieldModule, MatButtonModule, MatDividerModule],
  templateUrl: './credential.component.html',
  styleUrl: './credential.component.scss'
})


export class CredentialComponent {
  TYPE_API_SECRET: string = "api-secret";
  TYPE_API_ALTAMETRICS_SECRET: string = "api-altm-secret";
  TYPE_API_DEPUTY_SECRET: string = "api-deputy-secret";
  TYPE_API_QUICKBOOKS_SECRET: string = "api-qbt-secret";
  TYPE_API_SEVENSHIFTS_SECRET: string = "api-sevenshifts-secret";
  TYPE_API_BAMBOOHR_SECRET: string = "api-bamboohr-secret";
  TYPE_API_PAYCHEX_SECRET: string = "paychex-secret";
  TYPE_DATABASE_CONNECTION: string = "databaseConnection";
  PLACEHOLDER_SECRET_TEXT: string = "**********";
  tenantAPIService: TenantAPIService;
  uiNotificationService: NotificationsService;
  @Input({ required: true }) credential!: tenantSetting;
  formGroup: FormGroup;
  credentialType: string = "";
  formlabelsList: [] = [];
  tokenRequired: boolean = false; // Used for flows like Deputy, 7Shifts
  companyIdRequired: boolean = false; // Used for flows like 7Shifts
  isConfigured: boolean = false;
  isSaving: boolean = false;
  redirectUrl: string = '';

  constructor(private activatedRoute: ActivatedRoute, tenantAPIService: TenantAPIService, uiNotificationService: NotificationsService, private router: Router, formBuilder: FormBuilder) {
    //depending on the secret we will populate the form differently
    this.formGroup = formBuilder.group({});
    this.tenantAPIService = tenantAPIService;
    this.uiNotificationService = uiNotificationService;
  }
    ngOnInit() {
        this.isConfigured = this.credential?.isConfigured ?? false;
        let type = this.credential?.value ?? "";
        let accessToken = null; // Used for flows like Deputy, 7Shifts
        let companyId = null; // Used for flows like 7Shifts
        switch (type.toLowerCase()) {
            case "api-deputy-secret":
            case "api-deputy-secret-child":
              accessToken = this.activatedRoute.snapshot.queryParams['code'];
              //build api secret form
                this.formGroup.addControl("accessToken", new FormControl("", Validators.required));
                var redirectUri = environment.BaseUrl + "deputy/callback";
              if (accessToken) {
                  this.tokenRequired = false;
                  this.formGroup.controls["accessToken"].setValue(accessToken);
                  this.formGroup.controls["accessToken"].markAsDirty();
                  this.formGroup.addControl("redirectUri", new FormControl(redirectUri, Validators.required));
                  this.formGroup.controls["redirectUri"].markAsDirty();
              } else {
                  this.tokenRequired = true;
              }
                this.credentialType = this.TYPE_API_DEPUTY_SECRET;
                this.redirectUrl = "https://once.deputy.com/my/oauth/login?client_id=" + environment.DeputyClientId + "&redirect_uri=" + redirectUri + "&response_type=code&scope=longlife_refresh_token";
              break;
              case "api-qbt-secret":
              case "api-qbt-secret-child":
                accessToken = this.activatedRoute.snapshot.queryParams['code'];
                var stateParam = this.activatedRoute.snapshot.queryParams['state'];
                //build api secret form
                  this.formGroup.addControl("accessToken", new FormControl("", Validators.required));
                  var redirectUri = environment.BaseUrl + "qbt/callback";

                if (!stateParam) {
                  stateParam = crypto.randomUUID();
                }
                if (accessToken) {
                    this.tokenRequired = false;
                    this.formGroup.controls["accessToken"].setValue(accessToken);
                    this.formGroup.controls["accessToken"].markAsDirty();
                    this.formGroup.addControl("redirectUri", new FormControl(redirectUri, Validators.required));
                    this.formGroup.controls["redirectUri"].markAsDirty();
                } else {
                    this.tokenRequired = true;
                }
                  this.credentialType = this.TYPE_API_QUICKBOOKS_SECRET;
                  this.redirectUrl = "https://rest.tsheets.com/api/v1/authorize?response_type=code&client_id=" + environment.QuickbooksClientId + "&redirect_uri=" + redirectUri + "&state=" + stateParam;
                break;
            case this.TYPE_API_SEVENSHIFTS_SECRET:
            case  "api-sevenshifts-secret-child":
              accessToken = this.activatedRoute.snapshot.queryParams['guid'];
              companyId = this.activatedRoute.snapshot.queryParams['company_id'];
              //build api secret form
              this.formGroup.addControl("companyGUID", new FormControl("", Validators.required));
              this.formGroup.addControl("companyId", new FormControl("", Validators.required));
              if (accessToken) {
                  this.tokenRequired = false;
                  this.formGroup.controls["companyGUID"].setValue(accessToken);
                  this.formGroup.controls["companyGUID"].markAsDirty();
              } else {
                  this.tokenRequired = true;
              }

              if (companyId) {
                this.companyIdRequired = false;
                this.formGroup.controls["companyId"].setValue(companyId);
                this.formGroup.controls["companyId"].markAsDirty();
            } else {
                this.companyIdRequired = true;
            }
                this.credentialType = this.TYPE_API_SEVENSHIFTS_SECRET;
                this.redirectUrl = "https://app.7shifts.com/generate_token?client_id=" + environment.SevenShiftsClientId + "&redirect_uri=" + environment.BaseUrl + "sevenshifts/callback";
              break;
            case "api-secret":
                //build api secret form
                this.formGroup.addControl("uri", new FormControl("", Validators.required));
                this.formGroup.addControl("apiKey", new FormControl("", Validators.required));
                this.credentialType = this.TYPE_API_SECRET;
                break;
            case "api-altm-secret":
            case "api-altm-secret-child":
                //build api secret form
                this.formGroup.addControl("uri", new FormControl("", Validators.required));
                this.formGroup.addControl("partnerKey", new FormControl("", Validators.required));
                this.credentialType = this.TYPE_API_ALTAMETRICS_SECRET;
              break;
            case "api-bamboohr-secret":
                //build api secret form
                this.formGroup.addControl("uri", new FormControl("", Validators.required));
                this.formGroup.addControl("apiKey", new FormControl("", Validators.required));
                this.credentialType = this.TYPE_API_BAMBOOHR_SECRET;
                break;
            case "paychex-secret":
            case "paychexsecrets":
                this.formGroup.addControl("uri", new FormControl("", Validators.required));
                this.formGroup.addControl("apiKey", new FormControl("", Validators.required));
                this.formGroup.addControl("userName", new FormControl("", Validators.required));
                this.formGroup.addControl("password", new FormControl("", Validators.required));
                this.credentialType = this.TYPE_API_PAYCHEX_SECRET;
                break;
            case "databaseconnection":
            case "database-connection":
                //build api secret form
                this.formGroup.addControl("host", new FormControl("", Validators.required));
                this.formGroup.addControl("port", new FormControl("", Validators.required));
                this.formGroup.addControl("databaseName", new FormControl("", Validators.required));
                this.formGroup.addControl("userName", new FormControl("", Validators.required));
                this.formGroup.addControl("password", new FormControl("", Validators.required));
                this.formGroup.addControl("databaseEngine", new FormControl("", Validators.required));
                this.credentialType = this.TYPE_DATABASE_CONNECTION;
                break;

            default:
                break;
        }
  }

  // We cannot save if
  saveButtonDisabled = () => {
    return !this.formGroup.valid || this.isSaving
  }

  getDisplayName = () => {
    if (this.credential) {
      return this.credential?.displayName ?? this.credential.settingName
    }
    else {
      return "API Setting"
    }
  }
  shouldFloat = () => {
    return this.isConfigured ? "always" : 'auto';
  }

  private formGroupToRecord(formGroup: Record<string, any>): Record<string, string> {
    const formRecord: Record<string, string> = {};
      for (const key in formGroup['controls']) {
          if (key == "uri" && this.credentialType == this.TYPE_API_BAMBOOHR_SECRET) {
              formRecord[key] = "https://api.bamboohr.com/api/gateway.php/" + this.formGroup.get(key)?.value + "/v1/"
          }
          else {
              formRecord[key] = this.formGroup.get(key)?.value;
          }
    }
    return formRecord;
  }

  saveTenantSetting = () => {
    if (this.formGroup.valid) {
      this.isSaving = true;

      if (this.credential == null || this.credential.settingName == null) {
        console.error("Setting name is required to save.");
        return;
      }
      
      this.tenantAPIService.putTenantSetting(this.credential.settingName, this.credential?.value ?? "", this.formGroupToRecord(this.formGroup)).subscribe({
        error: (result: any) => { 
          console.log(result);
        },
        complete: () => {
          this.saveTenantSettingComplete();
        }
      });
    }
  }

  saveTenantSettingComplete = () => {
    this.isSaving = false;
    this.uiNotificationService.queueNotification({
      type: MIPNotificationType.Success,
      title: "Updated",
      message: "Saved Tenant Setting."
    } as MIPNotification);

    this.router.navigateByUrl('/', {skipLocationChange: true}).then(()=>
      this.router.navigate(['/settings'])
 );
  }
}

