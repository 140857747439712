<div>
    <mat-card class="">
        <mat-card-header>
            <mat-card-title>Configurations</mat-card-title>
        </mat-card-header>
        <mat-card-content>
            <form [formGroup]="tenantFlowSettingsOptionsForm" (ngSubmit)="saveConfig()">
                <mat-card-content class="standard-gap-vertical">
                    <div class="gridContent">
                        <mat-spinner *ngIf="integration !== null && hideForm()" [diameter]="20"></mat-spinner>
                        <div [hidden]="hideForm()" class="margin-bottom-10">
                            <div *ngFor="let setting of flowSettings">
                                <div
                                    *ngIf="!setting.isReadOnly && tenantFlowSettingsOptionsForm.get(setting.stepName + '-' + setting.settingName)">
                                    <div *ngIf="setting.type === 'string' || setting.type === 'number'">
                                        <mat-form-field>
                                            <mat-label>{{setting.displayName || setting.settingName}}</mat-label>
                                            <input [formControlName]="setting.stepName + '-' + setting.settingName"
                                                [value]="setting.defaultValue" matInput [required]="setting.required"
                                                [type]="setting.type === 'number' ? 'number':'text'">
                                        </mat-form-field>
                                    </div>
                                    <div *ngIf="setting.type === 'bool'">
                                        <div class="form-group">
                                            <mat-checkbox matInput labelPosition="before" class="enable-checkbox"
                                                [formControlName]="setting.stepName + '-' + setting.settingName"
                                                [checked]="setting.defaultValue">{{setting.displayName ||
                                                setting.settingName}}</mat-checkbox>
                                        </div>
                                    </div>
                                    <div *ngIf="setting.type === 'filter-string'">
                                        <div class="flex-start" style="margin-bottom: 0;">
                                            <mat-form-field class="col-6 text-area-medium" style="margin-bottom: 0;">
                                                <mat-label>{{setting.displayName || setting.settingName}}</mat-label>
                                                <mat-chip-grid #chipGrid
                                                    [formControlName]="setting.stepName + '-' + setting.settingName"
                                                    class="chip-grid-inline">
                                                    <mat-chip-row *ngFor="let keyword of getFilters(setting.stepName + '-' + setting.settingName); trackBy: trackByKeyword"
                                                        (removed)="removeFilter(keyword, setting.stepName + '-' + setting.settingName)"
                                                        color="primary">
                                                        <button matChipRemove>
                                                            <mat-icon>close</mat-icon>
                                                        </button>
                                                        {{keyword}}
                                                    </mat-chip-row>
                                                </mat-chip-grid>
                                                <input [matChipInputFor]="chipGrid"
                                                    (matChipInputTokenEnd)="addFilter($event, setting.stepName + '-' + setting.settingName)" 
                                                    (input)="onFilterTextBoxInputChange($event, setting.stepName + '-' + setting.settingName)"
                                                    [disabled]="isFilterTextBoxDisabled(setting)"/>
                                            </mat-form-field>
                                            <mat-form-field class="col-6 select-box-small" style="margin-left: 5px; margin-bottom: 0;">
                                                <mat-label>Filter Options</mat-label>
                                                <mat-select
                                                    [formControlName]="setting.stepName + '-' + setting.settingName + '-toggle'"
                                                    (selectionChange)="toggleChange($event, setting.stepName + '-' + setting.settingName)">
                                                    <mat-option value="include">Include</mat-option>
                                                    <mat-option value="exclude">Exclude</mat-option>
                                                </mat-select>
                                            </mat-form-field>
                                        </div>
                                    </div>
                                    <div *ngIf="setting.type === 'filter-string-with-null'">
                                        <div class="flex-start" style="margin-bottom: 0;">
                                            <mat-form-field class="col-6 text-area-medium" style="margin-bottom: 0;">
                                                <mat-label>{{setting.displayName || setting.settingName}}</mat-label>
                                                <mat-chip-grid #chipGrid
                                                    [formControlName]="setting.stepName + '-' + setting.settingName"
                                                    class="chip-grid-inline">
                                                    <mat-chip-row *ngFor="let keyword of getFilters(setting.stepName + '-' + setting.settingName); trackBy: trackByKeyword"
                                                        (removed)="removeFilter(keyword, setting.stepName + '-' + setting.settingName)"
                                                        color="primary">
                                                        <button matChipRemove>
                                                            <mat-icon>close</mat-icon>
                                                        </button>
                                                        {{keyword}}
                                                    </mat-chip-row>
                                                </mat-chip-grid>
                                                <input [matChipInputFor]="chipGrid"
                                                    (matChipInputTokenEnd)="addFilter($event, setting.stepName + '-' + setting.settingName)"
                                                    (input)="onFilterTextBoxInputChange($event, setting.stepName + '-' + setting.settingName)"
                                                    [disabled]="isFilterTextBoxDisabled(setting)"/>
                                            </mat-form-field>
                                            <mat-form-field class="col-6 select-box-small" style="margin-left: 5px; margin-bottom: 0;">
                                                <mat-label>Filter Options</mat-label>
                                                <mat-select
                                                    [formControlName]="setting.stepName + '-' + setting.settingName + '-toggle'"
                                                    (selectionChange)="toggleChange($event, setting.stepName + '-' + setting.settingName)">
                                                    <mat-option value="includeAll">Include All Values</mat-option>
                                                    <mat-option value="includeFilled">Include Only Filled Values</mat-option>
                                                    <mat-option value="excludeAll">Exclude All Values</mat-option>
                                                    <mat-option value="excludeEmpty">Exclude Only Empty Values</mat-option>
                                                    <!-- Include All Values – Shows everything, whether the field has information or not.
                                                        Include Only Filled Values – Only shows items with information in the field; empty fields are left out.
                                                        Exclude All Values – Hides everything, regardless of whether the field has information.
                                                        Exclude Only Empty Values – Only hides items with empty fields; items with information will still be shown. -->
                                                </mat-select>
                                            </mat-form-field>
                                        </div>
                                    </div>
                                    
                                    <div *ngIf="setting.type === 'filter-date'">
                                        <mat-form-field class="col-6 text-area-medium">
                                            <mat-label>{{setting.displayName || setting.settingName}}</mat-label>
                                            <mat-date-range-input [rangePicker]="picker" (input)="onDatepickerInputChange($event, setting)">
                                                <input matStartDate placeholder="Start date" [formControlName]="setting.stepName + '-' + setting.settingName+ '-start'" ng-model-options="{timezone: utc}">
                                                <input matEndDate placeholder="End date" [formControlName]="setting.stepName + '-' + setting.settingName+ '-end'" ng-model-options="{timezone: utc}">
                                            </mat-date-range-input>
                                            <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
                                            <mat-date-range-picker #picker></mat-date-range-picker>
                                        </mat-form-field>
                                        <mat-form-field class="col-6 select-box-small" style="margin-left: 5px; margin-bottom: 0;">
                                            <mat-label>Filter Options</mat-label>
                                            <mat-select
                                                [formControlName]="setting.stepName + '-' + setting.settingName + '-toggle'"
                                                (selectionChange)="toggleChange($event, setting.stepName + '-' + setting.settingName)">
                                                <mat-option value="include">Include</mat-option>
                                                <mat-option value="exclude">Exclude</mat-option>
                                            </mat-select>
                                        </mat-form-field>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </mat-card-content>
                <mat-card-actions class="align-right-down standard-gap-vertical">
                    <div class="start-job-card-actions">
                        <div class="start-job-calculate-button">
                            <button mat-flat-button color="primary" id="start-job" type="submit" class="upload-btn"
                                [disabled]="canSubmit()">
                                Save
                            </button>
                        </div>
                        <div class="start-job-calculate-spinner-container">
                            <mat-spinner class="start-job-calculate-spinner" *ngIf="" [diameter]="36"></mat-spinner>
                        </div>
                    </div>
                </mat-card-actions>
            </form>
        </mat-card-content>
    </mat-card>
</div>