
<mat-grid-list cols="2" gutterSize="26">

    <mat-grid-tile>
        <div class="container-fluid p-0">
            <mat-card>

                <mat-card-header>
                    <mat-card-title>File Transfer</mat-card-title>
                </mat-card-header>
                <mat-card-content *ngIf="!transferTypesLoaded">
                    <mat-spinner [diameter]="20"></mat-spinner>
                </mat-card-content>
       
                <mat-card-content *ngIf="transferTypesLoaded" class="standard-gap-vertical">
                    <mat-form-field class="full-width" *ngIf="transferTypesLoaded">
                        <mat-label>Transfer Type</mat-label>
                        <mat-select [(value)]="selectedTransferType" id="transferType-select" required matInput>
                            <mat-option>--</mat-option>
                            @for (type of transferTypes; track transferTypes)
                            {
                            <mat-option [value]="type">{{type.name}}</mat-option>
                            }
                        </mat-select>
                    </mat-form-field>

                </mat-card-content>

                <mat-card-actions class="align-right-down standard-gap-vertical" *ngIf="transferTypesLoaded">
                    <div class="file-upload">
                        <input type="file" class="file-input" (change)="onFileSelected($event)" #fileUpload id="fileInput">
                        <button mat-flat-button color="primary" class="upload-btn" (click)="fileUpload.click()"
                                id="file-upload-btn">
                            Select File
                            <mat-icon>upload_file</mat-icon>
                        </button>
                        {{fileName || "No file uploaded yet."}}
                    </div>

                    <button type="button" id="start-job" [disabled]="!canSubmit()" (click)="uploadFile()"
                            mat-flat-button color="primary">
                        Start Transfer
                    </button>
                </mat-card-actions>
            </mat-card>
        </div>
    </mat-grid-tile>

    <mat-grid-tile>
        <div class="container-fluid p-0">
            <mat-card>
                <mat-card-header>
                    <mat-card-title>Transfer Status</mat-card-title>
                </mat-card-header>
                <mat-card-content>
                    <app-job-info-card [jobId]="getActiveJobId()" [pollForActivity]=true
                                       [showExportGrid]=false [noItemSelectedText]="noItemSelectedText"
                                        [noItemSelectedMessage]="noItemSelectedMessage" [activeJobEvent]="activeJobEvent.asObservable()"></app-job-info-card>
                </mat-card-content>
            </mat-card>
        </div>
    </mat-grid-tile>
</mat-grid-list>