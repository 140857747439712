<div class="dialog-container-warning">
    <mat-card-header>
        <mat-card-title class="dialog-title-warning">
            <mat-icon class="warning-icon">warning</mat-icon
            ><span class="warning-text"
                >Whoops! Something’s Missing</span
            ></mat-card-title
        >
        <button
            class="disable-dialog-close-icon-button-container"
            mat-icon-button
            mat-dialog-close
            aria-label="Close"
            (click)="cancel()"
        >
            <mat-icon class="disable-dialog-close-icon">close</mat-icon>
        </button>
    </mat-card-header>

    <mat-dialog-content class="dialog-content-warning">
        <p class="dialog-content-warning-text">
            {{this.noTenantText}}
        </p>
        <p class="dialog-content-warning-text">
            {{this.noTenantContact}}
            <a href="mailto: {{this.mfaHelpDisplayURL}}">{{this.mfaHelpDisplayURL}}</a>.
        </p>
    </mat-dialog-content>
</div>
