import { Component, Input, signal } from '@angular/core';
import { MatTabsModule } from '@angular/material/tabs';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { CommonModule } from '@angular/common';
import { FormBuilder, FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { tenantFlowResult } from '../integration/models/integration-models';
import { FlowSettingsService } from '../../Services/api.flowSettings.service';
import { TenantAPIService } from '../../Services/api.tenantApi.service';
import { tenantFlowSetting } from '../../Models/Class-Interfaces/tenantFlowSetting.model';
import { Router } from '@angular/router';
import { MatIconModule } from '@angular/material/icon';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { ConfirmationDialogComponent } from '../../Shared/confirmation-dialog/confirmation-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { MatListModule } from '@angular/material/list';
import { interval } from 'rxjs';
import { createUserDefinedScheduleRequest, scheduleTypesEnum, updateUserDefinedScheduleRequest } from './models/createUserDefinedSchedule';

@Component({
  selector: 'app-payroll-schedule',
  standalone: true,
  imports: [CommonModule, MatButtonToggleModule, MatChipsModule, MatDatepickerModule, MatIconModule, MatProgressSpinnerModule, MatTabsModule, MatCardModule, MatFormFieldModule, MatInputModule, MatButtonModule, FormsModule, ReactiveFormsModule, MatCheckboxModule, MatRadioModule, MatSelectModule, MatListModule],
  templateUrl: './payroll-schedule.component.html',
  styleUrl: './payroll-schedule.component.scss'
})
export class PayrollScheduleComponent {
  constructor(public router: Router, private flowSettingsAPI: FlowSettingsService, private tenantAPI: TenantAPIService, private fb: FormBuilder, private dl: MatDialog) {
    this.tenantScheduleSettingsOptionsForm = this.fb.group({
      enableSchedule: new FormControl(),
      scheduleType: '',
      interval: '',
      cadenceType: '',
      timeOfDayAdv: '',
      timezoneAdv: '',
      daysOfWeek: '',
      timeOfDayBasic: '',
      timezoneBasic: ''
    });
    this.dialog = this.dl;
  }
  @Input() integration: tenantFlowResult | null = null;
  @Input() tenantFlowSettings: tenantFlowSetting[] = [];
  tenantFlowScheduleSetting: tenantFlowSetting | undefined;
  tenantScheduleSettings: createUserDefinedScheduleRequest;
  tenantScheduleSettingsOptionsForm: FormGroup;
  tenantScheduleSettingsLoaded: boolean = false;
  submittingForm: boolean = false;
  showForms: boolean = false;
  showAdvancedForm: boolean = false;
  showBasicForm: boolean = false;
  timezones: string[] = Intl.supportedValuesOf('timeZone');
  daysOfWeek: string[] = ['SUN', 'MON', 'TUES', 'WED', 'THUR', 'FRI', 'SAT'];
  cadenceType: string[] = ['Minutes', 'Hours', 'Days'];
  dialog: MatDialog;

  ngOnInit() {
    this.loadConfigSettings();
  }

  loadConfigSettings = (): void => {
    //load settings here and update form if settings already exist
    this.tenantFlowScheduleSetting = this.tenantFlowSettings.find(d => d.subType === "user-configurable-schedule");
    this.flowSettingsAPI.getFlowSchedule(this.integration?.type ?? "", this.integration?.flowCode ?? "").subscribe({
      next: (data: createUserDefinedScheduleRequest[]) => {
        this.tenantScheduleSettings = data[0];
      },
      error: (result: any) => { console.log(result) },
      complete: () => {
        if (this.tenantScheduleSettings != null) {
          this.updateFormData();
        }
        this.tenantScheduleSettingsLoaded = true;
      }
    });
  }

  updateFormData = (): void => {
    //update control fields with values loaded from config
    this.tenantScheduleSettingsOptionsForm.controls['enableSchedule']?.setValue(this.tenantScheduleSettings.enabled.toString());
    this.tenantScheduleSettingsOptionsForm.controls['scheduleType']?.setValue(this.tenantScheduleSettings.type);
    if (this.tenantScheduleSettings.type === scheduleTypesEnum.advanced) {
      this.tenantScheduleSettingsOptionsForm.controls['timezoneAdv']?.setValue(this.tenantScheduleSettings.timeZone);
      this.tenantScheduleSettingsOptionsForm.controls['timeOfDayAdv']?.setValue(this.tenantScheduleSettings.timeOfDay);
      this.tenantScheduleSettingsOptionsForm.controls['interval']?.setValue(parseInt(this.tenantScheduleSettings.repeatCadence));
      this.tenantScheduleSettingsOptionsForm.controls['cadenceType']?.setValue(this.tenantScheduleSettings.repeatCadenceType);
    }
    else if (this.tenantScheduleSettings.type === scheduleTypesEnum.basic) {
      this.tenantScheduleSettingsOptionsForm.controls['timezoneBasic']?.setValue(this.tenantScheduleSettings.timeZone);
      this.tenantScheduleSettingsOptionsForm.controls['timeOfDayBasic']?.setValue(this.tenantScheduleSettings.timeOfDay);
      this.tenantScheduleSettingsOptionsForm.controls['daysOfWeek']?.setValue(this.tenantScheduleSettings.daysOfWeek.split(","));
    }

    this.setSchedule(this.tenantScheduleSettings.enabled.toString());
    this.switchScheduleType(this.tenantScheduleSettings.type);
  }

  saveConfig = (): void => {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      data: { title: "Update Payroll Schedule", text: "Are you sure you want to proceed?" },
    });

    dialogRef.afterClosed().subscribe({
      next: (confirmed: boolean) => {
        if (confirmed) {
          var formFields;
          var isAdvancedField = this.tenantScheduleSettingsOptionsForm.controls['scheduleType']?.value === scheduleTypesEnum.advanced;

          if (this.tenantScheduleSettings != null) {

            formFields = new updateUserDefinedScheduleRequest(
              this.tenantScheduleSettingsOptionsForm.controls['scheduleType']?.value,
              isAdvancedField ? this.tenantScheduleSettingsOptionsForm.controls['timeOfDayAdv']?.value :
                this.tenantScheduleSettingsOptionsForm.controls['timeOfDayBasic']?.value,
              isAdvancedField ? this.tenantScheduleSettingsOptionsForm.controls['timezoneAdv']?.value :
                this.tenantScheduleSettingsOptionsForm.controls['timezoneBasic']?.value,
              this.tenantScheduleSettingsOptionsForm.controls['interval']?.value,
              this.tenantScheduleSettingsOptionsForm.controls['cadenceType']?.value,
              isAdvancedField ? "" :
                this.tenantScheduleSettingsOptionsForm.controls['daysOfWeek']?.value.join(","),
              this.tenantScheduleSettingsOptionsForm.controls['enableSchedule']?.value === 'true',
              this.tenantFlowScheduleSetting?.stepScheduleDetailType,
              this.tenantScheduleSettings.startDate
            );

            this.flowSettingsAPI.updateFlowSchedule(this.integration?.type ?? "", this.integration?.flowCode ?? "", this.tenantScheduleSettings.name, formFields).subscribe({
              error: (result: any) => { console.log(result) },
              complete: () => {
                this.resetFormLoading();
                this.loadConfigSettings();
              }
            });
          }
          else {

            formFields = new createUserDefinedScheduleRequest(
              this.tenantFlowScheduleSetting?.settingName ?? "",
              this.tenantFlowScheduleSetting?.stepScheduleName ?? "",
              this.tenantScheduleSettingsOptionsForm.controls['scheduleType']?.value,
              isAdvancedField ? this.tenantScheduleSettingsOptionsForm.controls['timeOfDayAdv']?.value :
                this.tenantScheduleSettingsOptionsForm.controls['timeOfDayBasic']?.value,
              isAdvancedField ? this.tenantScheduleSettingsOptionsForm.controls['timezoneAdv']?.value :
                this.tenantScheduleSettingsOptionsForm.controls['timezoneBasic']?.value,
              this.tenantScheduleSettingsOptionsForm.controls['interval']?.value,
              this.tenantScheduleSettingsOptionsForm.controls['cadenceType']?.value,
              isAdvancedField ? "" :
                this.tenantScheduleSettingsOptionsForm.controls['daysOfWeek']?.value.join(","),
              this.tenantScheduleSettingsOptionsForm.controls['enableSchedule']?.value === 'true',
              this.tenantFlowScheduleSetting?.stepScheduleDetailType,
              new Date()
            );

            this.flowSettingsAPI.createFlowSchedule(this.integration?.type ?? "", this.integration?.flowCode ?? "", formFields).subscribe({
              error: (result: any) => { console.log(result) },
              complete: () => {
                this.resetFormLoading();
                this.loadConfigSettings();
              }
            });
          }
        }
      }
    });
  }

  setSchedule = (scheduleEnabled: string): void => {
    this.showForms = scheduleEnabled === "true" ? true : false;
  }

  switchScheduleType = (scheduleType: string): void => {
    if (scheduleType === scheduleTypesEnum.advanced) {
      this.showAdvancedForm = true;
      this.showBasicForm = false;
    }
    else if (scheduleType === scheduleTypesEnum.basic) {
      this.showAdvancedForm = false;
      this.showBasicForm = true;
    }
    else {
      this.showAdvancedForm = false;
      this.showBasicForm = false;
    }
  }

  resetFormLoading = (): void => {
    this.tenantScheduleSettingsLoaded = false;
    this.submittingForm = false;
    this.showForms = false;
    this.showAdvancedForm = false;
    this.showBasicForm = false;
  }

  hideForm = (): boolean => {
    return this.integration?.flowCode == null || !this.tenantScheduleSettingsLoaded;
  }

  canSubmit = (): boolean => {
    return !this.integration || !this.tenantScheduleSettingsOptionsForm.valid || !this.tenantScheduleSettingsOptionsForm.controls['enableSchedule'].valid;
  }
}
