import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output, SimpleChanges, ViewChild } from '@angular/core';
import { MatPaginator, MatPaginatorModule } from '@angular/material/paginator';
import { MatSort, MatSortModule } from '@angular/material/sort';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatCardModule } from '@angular/material/card';
import {MatIconModule} from '@angular/material/icon';
import { MatButtonModule } from "@angular/material/button";
import { MatDialog } from '@angular/material/dialog';
import { MatTooltipModule } from '@angular/material/tooltip';
import { ActivatedRoute, Router } from '@angular/router';
import { JobDetailsDialogComponent } from '../job-details-dialog/job-details-dialog.component';
import { JobSearchErrorDialogComponent } from '../job-search-error-dialog/job-search-error-dialog.component';
import { JobStatus, job } from '../../jobs/models/job.model';
import { JobRetryDialogComponent, JobRetryDialogData } from '../job-retry-dialog/job-retry-dialog.component';

@Component({
  selector: 'app-job-search-table',
  standalone: true,
  imports: [CommonModule, MatTableModule, MatPaginatorModule, MatSortModule, MatProgressSpinnerModule, MatCardModule, MatIconModule, MatButtonModule, MatTooltipModule],
  templateUrl: './job-search-table.component.html',
  styleUrl: './job-search-table.component.scss'
})
export class JobSearchTableComponent {
  @Input() tableData: job[] = [];
  @Input() displayedColumns: string[] = [];
  @Input() dataColumns: string[] = [];
  @Input() displayActions: boolean = false;
  dataColumnsWithAction: string[] = [];

  dataSource = new MatTableDataSource<job>;

  @Input() isLoading: boolean = false;
  @Output() isLoadingChange: EventEmitter<boolean> = new EventEmitter<boolean>();

  // Bindings
  setLoadingState(isLoading: boolean): void {
    this.isLoading = isLoading;
    this.isLoadingChange.emit(this.isLoading);
  }

  // Sort and Pagintor are set in Angular's view query system and thus must be declared optional here. They are not created on constuction.
  @ViewChild(MatPaginator, {static: false}) paginator: MatPaginator  | undefined;
  @ViewChild(MatSort, {static: false}) sort: MatSort | undefined;

  ngOnChanges(changes: SimpleChanges): void {
    if(changes['tableData'])
    {
      this.UpdateDataSource();

      if (this.displayActions && this.tableData.length > 0)
      {
        this.route.queryParams.subscribe(params => {
          if(params['jobID'] && params['showDialog'] && this.tableData.length > 0)
          {
            let convertedJobs = this.tableData as job[];
            let jobFromId = convertedJobs.find(element => element.jobId === params['jobID']);
            this.showJobDetails(jobFromId);
          }
        });
      }
    }
  }

  ngOnInit() {
    if (this.displayActions) {
      this.dataColumnsWithAction = [...this.dataColumns, 'actions'];
    }
  }

  ngAfterViewInit(){
    this.UpdateDataSource();
  }

  UpdateDataSource(){
    this.dataSource = new MatTableDataSource(this.tableData);
    if(this.paginator){
      this.dataSource.paginator = this.paginator;
    }

    if(this.sort){
      // If sorting fails, make sure you are not removing the table from the DOM via ngIf or similar. Use [hidden] instead.
      this.dataSource.sort = this.sort;
      this.dataSource.sortingDataAccessor = (item: any, property) => {
        if (!isNaN(Date.parse(item[property])))
        {
          return new Date(item[property]);
        }
        else {
          return item[property];
        }
      }
    }
  }

  constructor(private dialog: MatDialog, private router: Router, private route: ActivatedRoute) { }

  // Action Buttons that Trigger the Dialog

  showJobDetails(element?: job) {
    const dialogRef = this.dialog.open(JobDetailsDialogComponent, {
      data: element,
    });
  }

  

  showJobErrors(element?: job) {
    const dialogRef = this.dialog.open(JobSearchErrorDialogComponent, {
      data: element,
      width: 'auto',
      height: 'auto',
      minHeight: '400px',
      minWidth:'800px',
    });
  }

  shouldShowJobRestart(element?: job){
    return element?.jobStatus === JobStatus.Error
  }

  showJobRestartDialog(element: job) {
    const retryDialogData: JobRetryDialogData = {
      title: "Retry Failed Job",
      text: `Are you sure you want to restart job ${element?.jobId}?`,
      job: element
    }

    const dialogRef = this.dialog.open(JobRetryDialogComponent, {
      data: retryDialogData
    });
  }
}