<div>
    <div [hidden]="!fileListLoaded">
        <table mat-table [dataSource]="dataSource"> class="mat-elevation-z8" class=""
            id="export-file-list">
            <ng-container matColumnDef="Type">
                <th mat-header-cell *matHeaderCellDef> Type </th>
                <td mat-cell *matCellDef="let element"> {{element.transferType}} </td>
            </ng-container>

            <ng-container matColumnDef="File Name">
                <th mat-header-cell *matHeaderCellDef> File Name </th>
                <td mat-cell *matCellDef="let element"> {{element.fileName ?? element.keyName}} </td>
            </ng-container>

            <ng-container matColumnDef="Actions">
                <th mat-header-cell *matHeaderCellDef> Action </th>
                <td mat-cell *matCellDef="let element">
                    <span (click)="downloadFile(element)" *ngIf="!element.downloading"
                        id="download-{{element.transferType}}-{{element.keyName}}">
                        <mat-icon matTooltip="download" class="icon-color-primary">download</mat-icon>
                        </span>
                    <mat-spinner [diameter]="20" *ngIf="element.downloading"
                        id="spinner-{{element.transferType}}-{{element.keyName}}"></mat-spinner>
                </td>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="dataColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: dataColumns;"></tr>
        </table>
    </div>
    <div [hidden]="!fileListLoaded">
        <mat-paginator #paginator (page)="pageChangeEvent($event)" [pageSize]="pageSize"
            [pageIndex]="pageIndex" [length]="dataSource.data.length"
            [pageSizeOptions]="[5, 10, 20, 50, 100]" showFirstLastButtons
            aria-label="Select page of exportable files">
        </mat-paginator>
    </div>
</div>
<mat-spinner [diameter]="40" *ngIf="!fileListLoaded" id="fil-list-pending"></mat-spinner>