<div>
    <div *ngIf="!activeJob">
        <div class="illustration-container">
            <span class="illustration-placeholder center">
                <span class="rectangle">
                    <span class="illustration"><mat-icon [matTooltip]=noItemSelectedText fontIcon="swap_horiz" class="icon-display"></mat-icon></span>
                </span>
            </span>
        </div>
        <div class="center"> <b>{{noItemSelectedText}}</b></div>
        <div class="center">{{noItemSelectedMessage}}</div>
    </div>
    <div class="" *ngIf="activeJob">
        <div class="center">
            <span *ngIf="isJobActive(activeJob)"> <mat-spinner [diameter]="34"></mat-spinner></span>
           <span *ngIf="!isJobActive(activeJob)" [ngClass]="getIconClass(activeJob)"> <mat-icon  [matTooltip]="getIconFont(activeJob)" [fontIcon]="getIconFont(activeJob)" class="icon-display"></mat-icon></span>           
        </div>
        <div class="center">
           <span class="label-padding"> <b>Status: </b></span> {{activeJob.jobStatus ?? "N/A"}}
        </div>
        <div class="center">
            <span class="label-padding"><b>Job ID: </b></span> {{activeJob.jobId ?? "N/A"}}
        </div>
    </div>
    <div>
        <div *ngIf="showExportGrid">
            <div>
                <label>Files</label>
                <app-exportable-files [jobId]="activeJob?.jobId ?? 'N/A'"></app-exportable-files>
        </div>

    </div>
</div>