import { Component, Inject, OnDestroy } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { MAT_DIALOG_DATA, MatDialog, MatDialogActions, MatDialogConfig, MatDialogContent, MatDialogModule, MatDialogRef, MatDialogTitle } from '@angular/material/dialog';
import { MatProgressSpinner } from '@angular/material/progress-spinner';
import { MatIconModule } from '@angular/material/icon';
import { ActionPollResultDialogData } from './Models/ActionPollResultDialogData';
import { TenantFlowActionResultStatus } from '../../Models/Class-Interfaces/TenantFlowActionResultStatus';
import { ActionResultPollingServiceService } from '../../Services/action-result-polling-service.service';

@Component({
    selector: 'app-action-poll-result-dialog',
    standalone: true,
    imports: [CommonModule, MatDialogActions, MatDialogContent, MatDialogModule, MatButtonModule, MatDialogTitle, MatProgressSpinner, MatIconModule],
    templateUrl: './action-pollresult-dialog.html',
    styleUrls: ['./action-pollresult-dialog.scss'],
    providers: [ActionResultPollingServiceService]
})
export class ActionPollResultDialogComponent implements OnDestroy {
    actionResultPollingService: ActionResultPollingServiceService;
    title: string;
    message: string | null;
    state: TenantFlowActionResultStatus = TenantFlowActionResultStatus.InProgress;

    TenantFlowActionResultStatus=TenantFlowActionResultStatus;

    constructor(
        public dialogRef: MatDialogRef<ActionPollResultDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: ActionPollResultDialogData,
        actionResultPollingService: ActionResultPollingServiceService
    ) {
        this.title = data.busy.title;
        this.message = data.busy.message;
        this.actionResultPollingService = actionResultPollingService;
    }

    ngOnInit(): void {
        // Ensure a default return value when closed via backdrop click or ESC
        this.dialogRef.beforeClosed().subscribe(() => {
            this.dialogRef.close(this.state === TenantFlowActionResultStatus.Complete);
        });

        this.actionResultPollingService.checkFlowActionStatus(this.data.request).subscribe({
            next: (result) => {
                this.state = result.status as TenantFlowActionResultStatus;
                if ((this.state === TenantFlowActionResultStatus.Complete || this.state === TenantFlowActionResultStatus.None ) && this.data.autoCloseSuccess) {
                    this.close();
                }
            },
            error: (error) => {
                console.error('Error polling for action result:', error);
                this.state = TenantFlowActionResultStatus.Error;
            }
        });


    }

    ngOnDestroy(): void {
        console.log('Dialog component destroyed.');
        this.actionResultPollingService.destroy();
        this.dialogRef.close(this.state === TenantFlowActionResultStatus.Complete);
    }

    close(): void {
        this.dialogRef.close(this.state === TenantFlowActionResultStatus.Complete);
    }
}