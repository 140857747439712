import { ApplicationConfig, importProvidersFrom } from '@angular/core';
import { provideRouter } from '@angular/router';
import { Amplify, ResourcesConfig } from 'aws-amplify';
import { routes } from './app.routes';
import { environment } from '../environments/environment';
import { HttpClientModule, provideHttpClient, withInterceptors, withInterceptorsFromDi } from '@angular/common/http';
import { provideAnimations } from '@angular/platform-browser/animations';
import { httpResponseNotificationInteceptor } from './Interceptors/http-response.interceptor';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';
import { awsAuthHttpInterceptor } from './Interceptors/aws-auth.intercerceptor';

Amplify.configure((environment as ResourcesConfig) ); // need to case due to typescript type checking

export const appConfig: ApplicationConfig = {
    providers:
    [
        provideRouter(routes),
        importProvidersFrom(HttpClientModule), 
        provideHttpClient( withInterceptors([awsAuthHttpInterceptor, httpResponseNotificationInteceptor])),
        provideAnimations(),
        {
            provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
            useValue: {
                appearance: 'outline'
            },
        }
    ],
};
