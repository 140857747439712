import { Component, Input, signal } from '@angular/core';
import { MatTabsModule } from '@angular/material/tabs';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { CommonModule, formatDate } from '@angular/common';
import { FormBuilder, FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipInputEvent, MatChipsModule } from '@angular/material/chips';
import { tenantFlowResult } from '../integration/models/integration-models';
import { flowDefinitionSetting } from '../jobs/models/flowDefinitionSetting';
import { FlowSettingsService } from '../../Services/api.flowSettings.service';
import { TenantAPIService } from '../../Services/api.tenantApi.service';
import { dateFiterSetting, stringFiterSetting, tenantFlowSetting, upsertTenantFlowSetting } from '../../Models/Class-Interfaces/tenantFlowSetting.model';
import { Router } from '@angular/router';
import { MatIconModule } from '@angular/material/icon';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatSelectModule } from '@angular/material/select';
@Component({
  selector: 'app-tenant-configuration',
  standalone: true,
  imports: [CommonModule, MatButtonToggleModule, MatSelectModule, MatChipsModule, MatDatepickerModule, MatIconModule, MatProgressSpinnerModule, MatTabsModule, MatCardModule, MatFormFieldModule, MatInputModule, MatButtonModule, FormsModule, ReactiveFormsModule, MatCheckboxModule],
  templateUrl: './tenant-configuration.component.html',
  styleUrl: './tenant-configuration.component.scss'
})
export class TenantConfigurationComponent {
  constructor(public router: Router, private flowSettingsAPI: FlowSettingsService, private tenantAPI: TenantAPIService, private fb: FormBuilder) {
    this.tenantFlowSettingsOptionsForm = this.fb.group({
    });
  }
  @Input() integration: tenantFlowResult | null = null;
  flowSettings: flowDefinitionSetting[] = [];
  tenantFlowSettings: tenantFlowSetting[] = [];
  tenantFlowSettingsOptionsForm: FormGroup;
  submittingForm: boolean = false;
  tenantFlowSettingsLoaded: boolean = false;
  flowSettingsLoaded: boolean = false;
  allFilters: { [id: string]: string[]; } = {};
  readonly filters = signal(this.allFilters);

  ngOnInit() {
    this.loadConfigSettings();
  }
  addFilter(event: MatChipInputEvent, filterName: string): void {
    const value = (event.value || '').trim();
    // Add our keyword
    if (value) {
      this.filters.update(filters => {
        if (!filters[filterName]) {
          filters[filterName] = [];
        }
        filters[filterName] = [...filters[filterName], value];
        return filters;
      });
    }
    // Clear the input value
    event.chipInput!.clear();
  }

  initFilter(chips: string[], filterName: string): void {
    this.filters.update(filters => {
      filters[filterName] = chips;
      return filters;
    });
  }

  getFilters(filterName: string): string[] {
    return this.filters()[filterName] || [];
  }
  removeFilter(filter: string, filterName: string): void {
    this.filters.update(allFilters => {
      var specificFilter = allFilters[filterName] || [];
      const index = specificFilter.indexOf(filter);
      if (index < 0) {
        return allFilters;
      }
      specificFilter.splice(index, 1);
      return allFilters;
    });
  }

  updateForm(flowSettings: flowDefinitionSetting[], tenantFlowSettings: tenantFlowSetting[]) {
    if (this.tenantFlowSettingsLoaded == false || this.flowSettingsLoaded == false) {
      return;
    }
    // Add new form controls on switch type
    flowSettings.forEach((setting) => {
      let formControlDefault: any | null = null;
      let formControlName = setting.stepName + '-' + setting.settingName;
      let disableParent: boolean = false;
      if (!setting.isReadOnly) {
        // check to see if tenant has settings already, if not use default values
        var tenantSetting = tenantFlowSettings.find(o => o.settingName === setting.settingName && o.stepName === setting.stepName);
        if (setting?.type && (setting?.type === "filter-string" || setting?.type === "filter-string-with-null") && tenantSetting !== null) {
          let filter: stringFiterSetting = tenantSetting?.value ? JSON.parse(tenantSetting?.value) : {} as stringFiterSetting;
          var list = filter?.value?.split(",") ?? []
          this.initFilter(list, formControlName);//`{setting.stepName}-{setting.settingName}`);
          let comparison: string = !filter.comparison ? "" : filter.comparison;
          const control = this.fb.control(comparison, setting.required ? Validators.required : null);
          
          if (!filter.comparison) {
            disableParent = true;
          }
          this.tenantFlowSettingsOptionsForm.addControl(formControlName + '-toggle', control);
        }
      
        else if (setting?.type && setting?.type === "filter-date" && tenantSetting != null) {
          let filter: dateFiterSetting = tenantSetting?.value ? JSON.parse(tenantSetting?.value) : {} as dateFiterSetting;

          var dates: string[] = filter.value?.split(",") ?? [];
          //format start,end          
          if (dates.length === 2) {
            //format YYYY-MM-DD,YYY-MM-DD
            filter.startDate = dates[0] == '' ? null : new Date(dates[0]);
            filter.endDate = dates[1] == '' ? null :new Date(dates[1]);
          }
          let comparison:string = !filter.comparison ? "" : filter.comparison;
          const startControl = this.fb.control(filter.startDate,
            setting.required ? Validators.required : null)
          this.tenantFlowSettingsOptionsForm.addControl(setting.stepName + '-' + setting.settingName + "-start", startControl);
          const endControl = this.fb.control(filter.endDate,
            setting.required ? Validators.required : null)
          this.tenantFlowSettingsOptionsForm.addControl(setting.stepName + '-' + setting.settingName + "-end", endControl);

          const control = this.fb.control(comparison, setting.required ? Validators.required : null);
          if (!filter.comparison) {
            disableParent = true;
            startControl.disable();
            endControl.disable();
          }
          this.tenantFlowSettingsOptionsForm.addControl(formControlName + '-toggle', control);
        }
        else if (tenantSetting) {// if the user has cleared the setting(null or "") we want to display what they chose
          if (setting.type === 'bool') {
            formControlDefault = tenantSetting?.value === 'true' ?? false;
          }
          else {
            formControlDefault = tenantSetting?.value
          }
        }
        else {
          formControlDefault = setting.defaultValue;
        }
        const control = this.fb.control(formControlDefault, setting.required ? Validators.required : null)
        if (disableParent) { control.disable(); }
        this.tenantFlowSettingsOptionsForm.addControl(setting.stepName + '-' + setting.settingName, control);
      }
    });
  }
  hideForm = (): boolean => {
    return this.integration?.flowCode == null || !this.tenantFlowSettingsLoaded || !this.flowSettingsLoaded; // Proxy for unloaded job types
  }
  updateFormAfterLoad() {
    if (this.tenantFlowSettingsLoaded && this.flowSettingsLoaded) {
      this.updateForm(this.flowSettings, this.tenantFlowSettings);
    }
  }
  loadConfigSettings() {
    if (this.integration !== null) {
      this.tenantAPI.getTenantFlowSettings(this.integration.flowCode).subscribe({
        next: (data: tenantFlowSetting[]) => {
          this.tenantFlowSettings = data;
        },
        error: (result: any) => { console.log(result) },
        complete: () => {
          this.tenantFlowSettingsLoaded = true;
          this.updateFormAfterLoad();
        }
      });
      this.flowSettingsAPI.getStepFlowDefinitionSettings(this.integration.flowCode, this.integration.version).subscribe({
        next: (data: flowDefinitionSetting[]) => {
          this.flowSettings = data;
        },
        error: (result: any) => { console.log(result) },
        complete: () => {
          this.flowSettings = this.flowSettings.sort((a, b) => {
            let orderA = a.sortPriority !== null ? a.sortPriority : Number.MAX_SAFE_INTEGER;
            let orderB = b.sortPriority !== null ? b.sortPriority : Number.MAX_SAFE_INTEGER;
            return orderA - orderB;
          });
          this.flowSettingsLoaded = true;
          this.updateFormAfterLoad();
        }
      });
    }
  }

  toggleChange(event: any, parent: any) {
    const selectedValue = event.value; // This gets the selected value from mat-select
  
    if (!selectedValue) {
      this.tenantFlowSettingsOptionsForm.controls[parent].disable();
      this.tenantFlowSettingsOptionsForm.controls[`${parent}-start`]?.disable();
      this.tenantFlowSettingsOptionsForm.controls[`${parent}-end`]?.disable();
    } else {
      this.tenantFlowSettingsOptionsForm.controls[parent].enable();
      this.tenantFlowSettingsOptionsForm.controls[`${parent}-start`]?.enable();
      this.tenantFlowSettingsOptionsForm.controls[`${parent}-end`]?.enable();
    }
  }  

  saveConfig = (): void => {
    if (this.tenantFlowSettingsOptionsForm.invalid) {
      return;
    }
    this.submittingForm = true;
    var formFields = [];
    let settingVal: string;
    // convert form fields to settings to be upserted
    for (var field in this.tenantFlowSettingsOptionsForm.controls) {
      var setting = this.flowSettings.find(o => o.stepName + '-' + o.settingName === field);
      if (setting !== undefined) {

        if (setting !== undefined) {
          if ((setting?.type === "filter-string"|| setting?.type === "filter-string-with-null") && this.tenantFlowSettingsOptionsForm.controls[field + "-toggle"]?.value !== null) {
            let filter: stringFiterSetting = {} as stringFiterSetting;
            let comparison:string|null=  this.tenantFlowSettingsOptionsForm.controls[field + "-toggle"]?.value?.toString();
            filter.comparison = this.tenantFlowSettingsOptionsForm.controls[field + "-toggle"]?.value?.toString();
            let chips = this.getFilters(field)
            filter.value = chips.length > 0 ? this.getFilters(field)?.join(",") : null;
            settingVal = JSON.stringify(filter);
          }
          else if (setting?.type === "filter-date" && this.tenantFlowSettingsOptionsForm.controls[field + "-toggle"]?.value !== null) {
            let filter: stringFiterSetting = {} as stringFiterSetting;
            var startDate: Date | null = this.tenantFlowSettingsOptionsForm.controls[field + "-start"]?.value;
            var endDate: Date | null = this.tenantFlowSettingsOptionsForm.controls[field + "-end"]?.value;

            const dateFormat = "yyyy-MM-ddTHH:mm:ss";
            const dateLocale = "en-US";
            var formattedStartDate = "";
            var formattedEndDate = "";
            if (startDate)
            {
              formattedStartDate = formatDate(startDate.toISOString(), dateFormat, dateLocale);
            }
            if (endDate)
            {
              formattedEndDate = formatDate(endDate.toISOString(), dateFormat, dateLocale);
            }

            filter.comparison = this.tenantFlowSettingsOptionsForm.controls[field + "-toggle"]?.value?.toString();
            filter.value = `${formattedStartDate},${formattedEndDate}`;
            settingVal = JSON.stringify(filter);
          }
          else if (this.tenantFlowSettingsOptionsForm.controls[field].touched === true) {

            settingVal = this.tenantFlowSettingsOptionsForm.controls[field].value.toString();
          }
          else {
            // we don't want to save an unchanged record
            continue;
          }
          var formField = new upsertTenantFlowSetting(
            setting.settingName,
            setting.displayName,
            setting.settingType,
            setting.stepName,
            setting.type,
            settingVal,
            setting.isReadOnly,
            setting.subType,
            setting.stepScheduleName,
            setting.stepScheduleDetailType,
            setting.enableSchedule
          );
          formFields.push(formField);
        }
      }

    }

    if (this.integration !== null) {
      this.tenantAPI.upsertTenantFlowStepSettings(this.integration?.flowCode, this.integration?.type, formFields).subscribe({
        error: (result: any) => { console.log(result) },
        complete: () => {
          this.submittingForm = false;
          this.tenantFlowSettingsLoaded = false;
          this.flowSettingsLoaded = false;
          this.loadConfigSettings();
        }
      });
    }
  }
  canSubmit = (): boolean => {
    return !this.integration || !this.tenantFlowSettingsOptionsForm.valid || this.submittingForm;
  }
  setRequired = (formControlName: string) => {
    this.tenantFlowSettingsOptionsForm.controls[formControlName]?.setValidators(Validators.required);
    this.tenantFlowSettingsOptionsForm.controls[formControlName]?.updateValueAndValidity();;
  }
  clearRequired = (formControlName: string) => {
    this.tenantFlowSettingsOptionsForm.controls[formControlName]?.clearValidators();
    this.tenantFlowSettingsOptionsForm.controls[formControlName]?.updateValueAndValidity();;
  }
}
