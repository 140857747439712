<div class="app-confirmation-dialog">
    <div class="app-confirmation-dialog-content">
        <div class="dialog-title-container dialog-title-container-info" >      
            <h2 mat-dialog-title>{{ title }}</h2>
        </div>
        <mat-divider></mat-divider>
        <mat-dialog-content>
            <div class="center">
                <mat-spinner [diameter]="60" color="primary"></mat-spinner>               
            </div>
            <div class="center"><p>{{ text }}</p></div>
        </mat-dialog-content>
    </div>
</div>
