import { Component,OnInit, inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SessionService } from '../../Services/session.service';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { TenantAPIService } from '../../Services/api.tenantApi.service';
import { MatToolbarModule } from '@angular/material/toolbar';
import { tenant } from '../../Models/tenant.model';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatFormFieldModule } from '@angular/material/form-field';
import { ConfirmationDialogComponent } from '../../Shared/confirmation-dialog/confirmation-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { CognitoService } from '../../Services/cognito.service';
import { MatGridListModule } from '@angular/material/grid-list';
import { openBlockerDialog } from '../../Shared/blocker-dialog/blocker-dialog.component';
import { Router } from '@angular/router';


@Component({
  selector: 'app-tenant-info',
    standalone: true,
    imports: [CommonModule, MatProgressSpinnerModule, MatToolbarModule, MatFormFieldModule, MatSelectModule, MatGridListModule],
  templateUrl: './tenant-info.component.html',
  styleUrl: './tenant-info.component.scss'
})
export class TenantInfoComponent implements OnInit  {
    public tenantLoaded: boolean = false;
    public allowedTenantsLoaded: boolean = false;
    
    constructor(
        private router: Router,  
        private cognitoService: CognitoService,
        private tenantApi: TenantAPIService,
        private dialog: MatDialog
    ) 
    {
      this.router = router
    }

    allowedTenants: tenant[] | undefined = [];
    currentTenant: tenant | undefined;
    currentTenantId: string = "";

  ngOnInit() {
    this.loadCurrentTenant();
    this.loadAllowedTenants();
  }
  getCurrentTenantName(): string {
    if (!this.tenantLoaded) {
      this.loadCurrentTenant();
      this.loadAllowedTenants();
    }   
    return this.currentTenant?.name ?? "";
  }

  loadCurrentTenant = async ():Promise<void> => {
    this.tenantLoaded = false;
    this.tenantApi.getTentant()
      .subscribe({
        next: (tenant: any) => { 
          this.currentTenant = tenant;
          this.currentTenantId = tenant.tenantId;
        }, complete:() => this.tenantLoaded=true});
  }

    loadAllowedTenants = async (): Promise<void> => {
        this.tenantApi.getAllowedTenants()
            .subscribe({
                next: (data: tenant[]) => {
                    this.allowedTenants = data;
                },
                error: (result: any) => { console.error(result) },
                complete: () => this.allowedTenantsLoaded = true
            });
    }

    switchTenant = async (tenantId: string): Promise<void> => {
        //pop confirmation dialog
        const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
            data: {title: "Change Tenant", text: "Are you sure you want to proceed?"},
        });

        dialogRef.afterClosed().subscribe({
            next: (confirmed: boolean) => {
                if (confirmed) {
                    //pop waiting blocker
                    var blockerRef = openBlockerDialog(this.dialog, { title: "Changing Tenant", text: "Please Wait" });

                    //do tenant switch
                    this.tenantApi.switchTenant(tenantId).subscribe({
                        error: (result: any) => {
                            blockerRef.close();
                            console.error(result);
                        },
                        complete: async () => {
                            this.cognitoService.refreshUserToken()
                                .then(() => { window.location.reload(); })
                                .catch((e) => {
                                    console.error(e);
                                })
                                .finally
                                {
                                  this.router.navigate(['/dashboard']);
                                }; 
                        }
                    });
                }
            }
        });
    }
}
