import { Injectable } from '@angular/core';
import { DialogComponent } from '../dialog/dialog.component';
import { MIPNotificationType } from './mip-notification-type.enum';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';

export interface MIPNotification {
  type: MIPNotificationType;
  title?: string;
  message: string;
  jsonDetails?: { [key: string]: any };
}

@Injectable({
  providedIn: 'root'
})
export class NotificationsService {
  private notificationQueue: MIPNotification[] = [];

  constructor(private dialog: MatDialog, private snackBar: MatSnackBar) { }

  queueNotification(notification: MIPNotification): void {
    this.notificationQueue.push(notification);
    //console.log(`Notification Enqueued: ${notification.type} ${notification.title ?? "Title"} ${notification.message}`);
    if(this.notificationQueue.length === 1){
      this.displayNextNotification();
    }
  }

  private displayNextNotification(): void {
    if(this.notificationQueue.length === 0){
      return;
    }

    const notification = this.notificationQueue[0];
    switch(notification.type){
      case MIPNotificationType.Success:
        //console.log(`Notify SUCCESS via toast: ${notification.type} ${notification.title ?? "Title"} ${notification.message}`);
        var config: MatSnackBarConfig = {
          duration: 3000,
          panelClass: ['snackbar-success'],
          verticalPosition: 'top',
        }
        this.snackBar.open(notification.message, 'Close', config).afterDismissed().subscribe(() => {
          this.dequeueAndDisplayNext();
        })
        break;
      case MIPNotificationType.Error:
        //console.log(`Notify ERROR via dialog: ${notification.type} ${notification.title ?? "Title"} ${notification.message}`);
        var config: MatSnackBarConfig = {
          panelClass: ['snackbar-error'],
          data: notification,
          verticalPosition: 'top',
        }
        const dialogRef = this.dialog.open(DialogComponent, config);
        dialogRef.afterClosed().subscribe(() => {
          this.dequeueAndDisplayNext();
        });
        break;
    }
  }

  private dequeueAndDisplayNext(): void {
    this.notificationQueue.shift();
    this.displayNextNotification();
  }
}
