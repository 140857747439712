import { HttpContextToken, HttpEvent, HttpHandler, HttpHandlerFn, HttpInterceptorFn, HttpRequest } from '@angular/common/http';

import { Injectable, inject } from '@angular/core';
import { CognitoService } from '../Services/cognito.service';
import { EMPTY, Observable, catchError, from, switchMap } from 'rxjs';
import { SET_AWS_TOKEN } from '../Services/api.mipHttpService';

export const awsAuthHttpInterceptor: HttpInterceptorFn = (req: HttpRequest<any>, next: HttpHandlerFn): Observable<HttpEvent<any>>  => {
    const cognitoService = inject(CognitoService);
    //console.log("HTTP Response Notification Interceptor Triggered.");
    return from(cognitoService.getToken())
        .pipe(
            switchMap((accessToken: any) => {
                const setSettingToken = req.context.get(SET_AWS_TOKEN);
                if (setSettingToken){
                    req = req.clone({
                        setHeaders: {
                            Authorization: `Bearer ${accessToken}`
                        }
                    });
                }              
                return next(req);
            }),
            catchError((err) => {
                // stop processing if getToken fails for some reason
                // we will logout the user if the token is invalid
                return EMPTY;              
            })
        );
};