<mat-toolbar>
    <mat-toolbar-row>
            <div class="tenant-select">
                <span *ngIf="tenantLoaded && allowedTenantsLoaded">
                    <mat-select [(value)]="currentTenantId"
                                (selectionChange)="switchTenant($event.value)" required>
                        <mat-option *ngFor="let tenant of allowedTenants" [value]="tenant.tenantId">{{tenant.name}}</mat-option>
                    </mat-select>
                </span>
                <span *ngIf="!tenantLoaded || !allowedTenantsLoaded">
                    <mat-spinner [diameter]="30"
                                 color="accent"></mat-spinner>
                </span>
            </div>
    </mat-toolbar-row>
</mat-toolbar>
