<div class="job-item-error-dialog">
    <div class="job-item-error-dialog-content">
        <h2 mat-dialog-title>Job Items - Errors and Warnings</h2>
        <mat-dialog-content>
            <div *ngIf="!jobItemsLoaded"
                style="display: flex; justify-content: center; align-items: center; background: white;">
                <mat-progress-spinner color="primary" mode="indeterminate">
                </mat-progress-spinner>
            </div>
            <div *ngIf="jobItemsLoaded">
                <mat-grid-list cols="1" rowHeight="30px">
                    <mat-grid-tile>
                        <p class="left-align"><strong>Job ID: </strong>{{ job.jobId }}</p>
                    </mat-grid-tile>
                </mat-grid-list>
                <mat-grid-list cols="1" rowHeight="30px">
                    <mat-grid-tile>
                        <p class="left-align"><strong>Job Status: </strong>{{ job.jobStatus }}</p>
                    </mat-grid-tile>
                </mat-grid-list>
                <div *ngIf="this.jobItems.length == 0">
                    <mat-card>
                        <mat-card-content>
                            <p>No errors or warnings to report.</p>
                        </mat-card-content>
                    </mat-card>
                </div>
                <app-job-item-error-table *ngIf="this.jobItems.length != 0" [(isLoading)]="!jobItemsLoaded" [tableData]="this.jobItems" id="job-item-error-table">
                </app-job-item-error-table>
            </div>
        </mat-dialog-content>
    </div>
</div>