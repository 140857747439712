<div class="dialog-container dialog-container-info job-details-dialog">
    <div class="dialog-title-container dialog-title-container-info dialog-title-container-job-item-error">
        <h1 mat-dialog-title class="dialog-title-text">Job Details</h1>
        <button mat-icon-button mat-dialog-close class="button-close button-dialog-close" [disableRipple]="true">
            <mat-icon>close</mat-icon>
        </button>
    </div>

    <mat-dialog-content>
        <mat-grid-list cols="2" rowHeight="30px">
            <mat-grid-tile>
                <p class="left-align"><strong>Job Name: </strong>{{ job.jobName }}</p>
            </mat-grid-tile>
            <mat-grid-tile>
                <p class="left-align"><strong>Job Type: </strong>{{ job.jobSubType }}</p>
            </mat-grid-tile>
            <mat-grid-tile >
                <p class="left-align"><strong>Job ID: </strong>{{ job.jobId }}</p>
            </mat-grid-tile>
            <mat-grid-tile>
                <p class="left-align"><strong>Status: </strong>{{ job.jobStatus }}</p>
            </mat-grid-tile>
            <mat-grid-tile colspan="2" rowspan="2">
                <p class="left-align"><strong>Description: </strong>{{ job.jobDescription }} </p>
            </mat-grid-tile>
            <mat-grid-tile>
                <p class="left-align"><strong>Created Date: </strong>{{ formattedDate() }}</p>
            </mat-grid-tile>
            <mat-grid-tile>
                <p class="left-align"><strong>Created By: </strong>{{ job.createdByUserName }}</p>
            </mat-grid-tile>
        </mat-grid-list>
        <p class="left-align"><strong>Files to Download</strong></p>
        <mat-card>
            <mat-card-content class="card-content-fill">
                <div class="table-container-scrollable dialog-table-container-scrollable">
                    <app-exportable-files *ngIf="isJobCompleted" [jobId]="job.jobId ?? ''"></app-exportable-files>
                </div>
            </mat-card-content>
        </mat-card>
        <div class="center" *ngIf="!isJobCompleted">
            <span><mat-spinner [diameter]="34"></mat-spinner></span>
        </div>
    </mat-dialog-content>
</div>