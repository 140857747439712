import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogActions, MatDialogContent, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MatExpansionModule } from '@angular/material/expansion';
import { MIPNotification } from '../Notifications/notifications.service';
import { CommonModule } from '@angular/common';
import { MatCardModule } from '@angular/material/card';

@Component({
  selector: 'app-dialog',
  standalone: true,
  imports: [CommonModule, MatCardModule, MatDialogModule, MatDialogContent, MatDialogActions, MatExpansionModule],
  providers: [
    {
      provide: MatDialogRef,
      useValue: {}
    }
 ],
  templateUrl: './dialog.component.html',
  styleUrl: './dialog.component.scss'
})

export class DialogComponent {

  notification: MIPNotification;

  constructor(
    public dialogRef: MatDialogRef<DialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: MIPNotification
  ) {
    this.notification = data;
  }
}