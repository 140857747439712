import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { NoTenantDialogComponent} from '../../Shared/no-tenant-dialog/no-tenant-dialog.component';

@Component({
    selector: 'app-notenant',
    templateUrl: './notenant.component.html',
    styleUrls: ['./notenant.component.scss'],
    standalone: true,
})
export class NoTenantComponent implements OnInit {
    dialog: MatDialog;
    constructor(private dl: MatDialog) {
        this.dialog = this.dl;
    }

    ngOnInit(): void {
        const dialogRef = this.dialog.open(NoTenantDialogComponent);
    }
}
