<div class="container-fluid  navigation-bar offering-component" *ngIf="!loading">
    <div *ngIf="!offeringLoading" class="row-fluid navigation-title ">
        <div class="flex-start-row flex-large-spacing offering-title-container">
            <div class="flex-centered-row flex-xsmall-spacing">
                <app-company-icon [offeringCode]="offeringCode" ></app-company-icon>
                <span class="offering-name"> {{offering?.offeringName ?? "Offering Not Found"}}</span>
            </div>
            <mat-select
                    *ngIf="!offeringFlowsLoading"
                    [(ngModel)]="offeringFlowSelected"
                    (selectionChange)="changeFlowToSelected()"
                    placeholder="Select One"
                    class="offering-integration-select">
                    <mat-option *ngFor="let option of offeringFlows" [value]="option">
                        {{option.flowName}}
                    </mat-option>
                </mat-select>
        </div>
        <mat-spinner *ngIf="offeringFlowsLoading" class="loading-spinner" [diameter]="20"></mat-spinner>
    </div>
    <mat-spinner *ngIf="offeringLoading" class="loading-spinner inline-spinner" [diameter]="60" ></mat-spinner>
    <app-integration *ngIf="flowCode" ></app-integration>
</div>
<div *ngIf="loading" class="container-fluid ">
    <div class="row">
        <mat-spinner class="loading-spinner" [diameter]="60"></mat-spinner>
    </div>
</div>