<div>
    <mat-card class="section-container-card">
        <mat-card-header>
            <mat-card-title class="section-container-title">Auth / Credentials</mat-card-title>
        </mat-card-header>
        <mat-card-content>
            <mat-spinner *ngIf="loading" [diameter]="40"></mat-spinner>
            <div *ngIf="!loading">
                <div *ngFor="let item of credentials; let last = last" class="section-item">
                    <app-credential *ngIf="item" [credential]="item"></app-credential>
                    <ng-container *ngIf="!last">
                        <div class="row">
                            <mat-divider class="section-divider"></mat-divider>
                        </div>
                    </ng-container>
                </div>
            </div>
        </mat-card-content>
    </mat-card>
</div>