import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, FormGroupDirective, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatProgressSpinner } from '@angular/material/progress-spinner';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { environment } from '../../../environments/environment';
import { MatOption, MatSelect } from '@angular/material/select';
import { RecordAuditApiService } from '../../Services/api.record-audit.service';
import { RecordAuditStartRequest } from '../../Models/Class-Interfaces/RecordAuditStartRequest.model';
import { RecordAuditStartResponse } from '../../Models/Class-Interfaces/RecordAuditStartResponse.model';
import { TenantRecordAuditGetResult } from '../../Models/Class-Interfaces/TenantRecordAuditGetResult.model';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmationDialogComponent } from '../../Shared/confirmation-dialog/confirmation-dialog.component';
import { FlowSettingsService } from '../../Services/api.flowSettings.service';

export interface MatchType {
  value: string;
  label: string;
}

export interface RecordAuditFlow {
  code: string;
  auditType: string;
}

@Component({
  selector: 'app-record-audit',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    MatProgressSpinner,
    MatButtonModule,
    MatCardModule,
    MatCheckboxModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelect,
    MatOption
  ],
  templateUrl: './record-audit.component.html',
  styleUrl: './record-audit.component.scss'
})
export class RecordAuditComponent {
  @Input() auditFlows: TenantRecordAuditGetResult[];

  flowCode: string = "";
  formGroup: FormGroup;
  loading: boolean = true;
  matchTypeLoading: boolean = false; // For when we fetch the matchtype after audittype is selected
  helpSupportUrl: string = environment.GetSupportUrl;
  submittingForm: boolean = false;

  auditTypes: RecordAuditFlow[];

  matchTypes: MatchType[];

  constructor(
    private route: ActivatedRoute,
    private formBuilder: FormBuilder,
    private flowSettingsAPI: FlowSettingsService,
    private recordAuditApiService: RecordAuditApiService,
    private router: Router,
    private dialog: MatDialog,
  ) {
    this.formGroup = this.formBuilder.group({
      auditType: new FormControl({value: null, disabled: false}, Validators.required),
      matchType: new FormControl({value: null, disabled: true}, Validators.required)
    });
  }

  ngOnInit() {
    this.route.params.subscribe((param: Params) => {
      this.flowCode = param['flowCode'] ?? "";
      this.loading = true;
      this.submittingForm = false;
      this.auditTypes = this.auditFlows
        .filter(item => item.isEnabled)
        .map(item => ({
          code: item.flowCode,
          auditType: this.auditTypeDisplayName(item.auditType)
        }));

        this.loading = false;
    });
  }

  onAuditTypeChange(auditType: RecordAuditFlow) {
    const matchTypeControl = this.formGroup.get('matchType');
    matchTypeControl?.reset();
    matchTypeControl?.disable();
    this.matchTypeLoading = true;

    this.flowSettingsAPI.getRuntimeFlowDefinitionSettings(auditType.code, 0, "recordaudit").subscribe(settings => {
      const matchTypeSetting = settings.find(setting => setting.settingName === 'MatchType');
      if (matchTypeSetting && matchTypeSetting.allowedValues) {
        this.matchTypes = matchTypeSetting.allowedValues.map(value => ({
          label: value.displayName,
          value: value.value || '' // Provide a default value if value is undefined
        }));
        matchTypeControl?.enable();
      } else {
        this.matchTypes = [];
      }
      this.matchTypeLoading = false;
    });
  }

  onSubmit(formDirective: FormGroupDirective) {
    if (!this.formGroup.valid) {
      console.log("Form not valid, cannot submit");
      return;
    }
  
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      data: {
        title: 'View Audit Record',
        text: 'This process can take up to 10 minutes. Select continue to open a new tab to view the file associated with the audit when its done processing.',
        width: '540px',
        height: 'auto',
        okText: 'Continue'
      }
    });
  
    dialogRef.afterClosed().subscribe(result => {
      if (result === true) {
        const formValues = this.formGroup.getRawValue();
        
        const startRequest: RecordAuditStartRequest = {
          flowCode: formValues.auditType.code,
          matchType: formValues.matchType
        };
  
        this.submittingForm = true;
        this.recordAuditApiService.start(startRequest).subscribe({
          next: (result: RecordAuditStartResponse) => {
            const queryParams = {
              jobID: result.jobId,
              showDialog: true
            };
            const urlTree = this.router.createUrlTree(['/jobs/search'], { queryParams });
            const url = this.router.serializeUrl(urlTree);
            window.open(url, '_blank');
            this.submittingForm = false;
          },
          error: (result: any) => {
            console.log(result);
            this.submittingForm = false;
          },
          complete: () => {
            formDirective.resetForm();
          }
        });
      }
    });
  }

  auditTypeDisplayName(value: string) : string {
    switch(value.toLowerCase()){
      case 'worker':
        return 'Worker';
      default:
        return value;
    }
  }

}
