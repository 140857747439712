
<a 
    id= {{name}} 
    (click)="navItemClickFN()"
    [routerLink]="[route]"
    routerLinkActive="nav-item-active">
    <span class="d-inline-flex" routerLinkActive="active">
        <mat-icon mat-list-icon [matTooltip]="name" matTooltipPosition="right">
            {{icon}}
        </mat-icon>
        {{name}} 
    </span>
</a>
