import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { MatListModule } from '@angular/material/list';
import { MatSidenavModule } from '@angular/material/sidenav';
import { CognitoService } from '../../Services/cognito.service';
import { Router, RouterLink, RouterLinkActive } from '@angular/router';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { OfferingApiService } from '../../Services/api.offering.service';
import { TenantOfferingLink } from '../../Models/Class-Interfaces/Offerings/TenantOfferingLink.model';
import { TenantOfferingFlowLink } from '../../Models/Class-Interfaces/Offerings/TenantOfferingFlowLink.model';
import { CompanyIconComponent } from '../../Shared/company-icon/company-icon.component';


@Component({
  selector: 'app-nav-bar-offering',
  standalone: true,
  imports: [
    CompanyIconComponent,
    MatSidenavModule,
    MatListModule,
    RouterLinkActive,
    RouterLink,
    MatIconModule,
    MatTooltipModule,
    CommonModule,
    MatProgressSpinnerModule],
  templateUrl: './nav-bar-offering.component.html',
  styleUrl: './nav-bar-offering.component.scss'
})
export class NavBarOfferingComponent {
  constructor(private cognitoService: CognitoService, public router: Router, public offeringService:OfferingApiService) { }

  public offeringsLoaded:boolean = true;
  public failedLoadingOfferings:boolean=true;
  public offerings:TenantOfferingLink[] = [];
  ngOnInit() {
   this.loadOfferings();
  }
  public signOut(event: any) {
    this.cognitoService.handleSignOut();
  }

  loadOfferings = () => {
    this.offeringsLoaded = false;
    this.failedLoadingOfferings = false;
    this.offeringService.listTenantOfferingLink({})
    .subscribe({
      next:(result:TenantOfferingLink[])=>{
        this.offerings = result.sort((x, y) => x.offeringName.localeCompare(y.offeringName) );
      },
      error: () => {
        this.offeringsLoaded = true;
        this.failedLoadingOfferings = true;
      },
      complete: () => {
        this.offeringsLoaded = true;
        this.loadUnassociatedOfferings();
      }
    });
  }

  loadUnassociatedOfferings = () => {
    this.offeringService.getUnassociatedTenantOfferingFlowLink({})
    .subscribe({
      next:(result:TenantOfferingFlowLink[])=>{
        if(result && result.length > 0 ) {
          var item = result[0];
          this.offerings.push({
            offeringCode: item.offeringCode,
            offeringName: item.offeringName,
            offeringDescription: item.offeringDescription,
            offeringPrice: null,
            createDate: item.createDate,
            createdBy: item.createdBy,
            modifiedDate: item.modifiedDate,
            modifiedBy: item.modifiedBy
          })
        }
      },
      error: () => {
      },
      complete: () => {  }
    });
  }


}
