<div class="grid-container">
    <mat-card class="grid-item">
        <mat-card-header>
            <mat-card-title>Run Calculations</mat-card-title>
        </mat-card-header>
        <form [formGroup]="jobStartOptionsForm" (ngSubmit)="startJob()">
            <mat-card-content class="standard-gap-vertical">
                <div class="gridContent">
                    <mat-spinner *ngIf="integration !== null && hideForm()" [diameter]="20"></mat-spinner>
                    <div [hidden]="hideForm()" class="margin-bottom-10">
                        <div>
                            <mat-form-field class="full-width">
                                <mat-label>Job Name</mat-label>
                                <input id="name" formControlName="name" matInput>
                            </mat-form-field>
                        </div>
                        <div>
                            <mat-form-field class="full-width">
                                <mat-label>Job Description</mat-label>
                                <input  id="description" formControlName="description" matInput>
                            </mat-form-field>
                        </div>
                        <div *ngFor="let setting of jobFlowSettings">
                            <div *ngIf="setting.type === 'string'">
                                <mat-form-field class="full-width">
                                    <mat-label>{{setting.displayName || setting.settingName}}</mat-label>
                                    <input [formControlName]="setting.settingName" matInput [required]="setting.required">
                                </mat-form-field>
                            </div>
                            <div *ngIf="isDateTimeType(setting.type)">
                                <p>
                                    <mat-form-field class="full-width">
                                        <mat-label>{{setting.displayName || setting.settingName}}</mat-label>
                                        <input [formControlName]="setting.settingName" [matDatepicker]="picker" matInput
                                            [required]="setting.required" [min]="setting.dependentOn ? jobStartOptionsForm.get(setting.dependentOn)?.value : null" (dateChange)="onDateChange($event, setting.settingName, setting?.dependentOn)" >
                                        <mat-hint>MM/DD/YYYY</mat-hint>
                                        <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
                                        <mat-datepicker #picker="matDatepicker"></mat-datepicker>
                                        <mat-error *ngIf="jobStartOptionsForm.get(setting.settingName)?.errors?.['endDateBeforeStartDate']">
                                            End date must be greater than or equal to start date.
                                        </mat-error>
                                    </mat-form-field>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </mat-card-content>
            <mat-card-actions class="align-right-down standard-gap-vertical">
                <div class="start-job-card-actions">
                    <div class="start-job-calculate-button">
                        <button mat-flat-button color="primary" id="start-job" type="submit" class="upload-btn"
                            [disabled]="!integration || !jobStartOptionsForm.valid || this.jobStarted">
                            Calculate
                        </button>
                    </div>
                    <div class="start-job-calculate-spinner-container">
                        <mat-spinner class="start-job-calculate-spinner" *ngIf="this.jobStarted" [diameter]="36"></mat-spinner>
                    </div>
                </div>
            </mat-card-actions>
        </form>
    </mat-card>
</div>