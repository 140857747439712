<div class="dialog-container dialog-container-info job-item-error-dialog">
    <div class="dialog-title-container dialog-title-container-info dialog-title-container-job-item-error">
        <h1 mat-dialog-title class="dialog-title-text">Job Item Details</h1>
        <button mat-icon-button mat-dialog-close class="button-close button-dialog-close" [disableRipple]="true">
            <mat-icon>close</mat-icon>
        </button>
    </div>
    <mat-dialog-content>
        <div class="job-details">
            <div class="field-readonly">
                <span class="field-label-readonly">Job ID: </span>
                <span class="field-value-readonly">{{ jobAndJobItem.job.jobId }}</span>
            </div>
            <div class="field-readonly">
                <span class="field-label-readonly">Status: </span>
                <span class="field-value-readonly">{{ jobAndJobItem.job.jobStatus }}</span>
            </div>
            <div class="field-readonly">
                <span class="field-label-readonly">Item Status: </span>
                <span class="field-value-readonly">{{ jobAndJobItem.jobItem.jobItemStatus}}</span>
            </div>
            <div class="field-readonly">
                <span class="field-label-readonly">Item Error Code: </span>
                <span class="field-value-readonly">{{ jobAndJobItem.jobItem.errorCode}}</span>
            </div>
            <div class="field-readonly">
                <span class="field-label-readonly">Item Error Message: </span>
                <span class="field-value-readonly">{{ jobAndJobItem.jobItem.errorMessage}}</span>
            </div>
        </div>
        <mat-card>
            <mat-card-content class="card-content-fill">
                <div class="table-container-scrollable dialog-table-container-scrollable">
                    <mat-table [dataSource]="getKeyValuePairs()">
                        <ng-container matColumnDef="field">
                            <mat-header-cell *matHeaderCellDef mat-sort-header> Fields </mat-header-cell>
                            <mat-cell *matCellDef="let fieldValuePair"> {{ fieldValuePair.key }} </mat-cell>
                        </ng-container>
                        <ng-container matColumnDef="value">
                            <mat-header-cell *matHeaderCellDef mat-sort-header> Values </mat-header-cell>
                            <mat-cell *matCellDef="let fieldValuePair"> {{ fieldValuePair.value }} </mat-cell>
                        </ng-container>
                        <mat-header-row *matHeaderRowDef="['field', 'value']"></mat-header-row>
                        <mat-row *matRowDef="let fieldValuePair; columns: ['field', 'value']"></mat-row>
                    </mat-table>
                </div>
            </mat-card-content>
        </mat-card>
    </mat-dialog-content>
</div>