import { Component, EventEmitter, Inject, Output } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MAT_DIALOG_DATA, MatDialogActions, MatDialogContent, MatDialogModule, MatDialogRef, MatDialog, MatDialogConfig, MatDialogTitle } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
@Component({
    selector: 'app-confirmation-dialog',
    standalone: true,
    imports: [MatDialogActions, MatDialogContent, MatDialogModule, MatButtonModule, MatDialogTitle, MatIconModule],
    templateUrl: './disable-scheduling-dialog.component.html',
    styleUrl: './disable-scheduling-dialog.component.scss'
})
export class DisableSchedulingComponent {
    title: string = "Disable Scheduling";
    subTitle: string = "Are you sure you want to disable scheduling?";
    revertSchedulingWarningMessage: string = "If you continue, the scheduling settings will revert to the last saved configuration and the integration will not have scheduling enabled.";
    saveSettingsReminderMessage: string = "If you want to save your current settings, please make sure all required fields are filled before saving.";

    constructor(
        public dialogRef: MatDialogRef<DisableSchedulingComponent>,
        @Inject(MAT_DIALOG_DATA) public data: { title: string, subTitle: string, revertSchedulingWarningMessage: string, saveSettingsReminderMessage: string }
    ) {
        dialogRef.updateSize('500px');
        if (data) {
            this.title = data.title ?? this.title;
            this.subTitle = data.subTitle ?? this.subTitle;
            this.revertSchedulingWarningMessage = data.revertSchedulingWarningMessage ?? this.revertSchedulingWarningMessage;
            this.saveSettingsReminderMessage = data.saveSettingsReminderMessage ?? this.saveSettingsReminderMessage;
        }
    }

    accept(): void {
        this.dialogRef.close(true);
    }

    cancel(): void {
        this.dialogRef.close(false);
    }
}

export function openDialog(dialog: MatDialog, data?: { title: string, subTitle: string, revertSchedulingWarningMessage: string, saveSettingsReminderMessage: string }): MatDialogRef<DisableSchedulingComponent> {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.width = '250px';
    dialogConfig.height = '250px';

    const ref = dialog.open(DisableSchedulingComponent, {
        data: data,
    });

    return ref;
}
