<div class="app-confirmation-dialog">
    <div class="app-confirmation-dialog-content">
        <h2 mat-dialog-title>{{ title }}</h2>
        <mat-dialog-content>
            <div class="center">
                <mat-spinner [diameter]="60" color="primary"></mat-spinner>               
            </div>
            <div class="center"><p>{{ text }}</p></div>
        </mat-dialog-content>
    </div>
</div>
