<mat-toolbar>
    <mat-toolbar-row>
            <div class="tenant-select">
                <span *ngIf="tenantLoaded && allowedTenantsLoaded">
                    <app-searchable-dropdown [searchOptions]="allowedTenantsSearchable" (selectedOption)="switchTenant($event)" [defaultOption]="currentTenantSearchable" [setAsRequired]="true"></app-searchable-dropdown>
                </span>
                <span *ngIf="!tenantLoaded || !allowedTenantsLoaded">
                    <mat-spinner [diameter]="30"
                                 color="accent"></mat-spinner>
                </span>
            </div>
    </mat-toolbar-row>
</mat-toolbar>
