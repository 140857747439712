<mat-card>
    <mat-card-content>
        <mat-accordion id="job-search-filter-accordion" class="job-search-filter-panel">
            <mat-expansion-panel [expanded]="isFilterPanelExpanded" hideToggle>
                <mat-expansion-panel-header>
                    <mat-panel-title id="job-search-filter-title">
                        Filters
                    </mat-panel-title>
                    <mat-panel-description id="job-search-filter-description">
                        Job Search Filters
                    </mat-panel-description>
                    <button id="job-search-filter-submit-button" mat-flat-button color="primary" (click)="onSearchJobsFormSubmit($event)"
                        [disabled]="!this.searchJobsFormGroup.valid" form="ngForm" type="submit">Search Jobs</button>
                </mat-expansion-panel-header>
                <form id="job-search-filter-form" class="flex-container" [formGroup]="this.searchJobsFormGroup" (ngSubmit)="onSearchJobsFormSubmit($event)">
                    <mat-form-field id="job-search-form-field-jobId" class="flex-item flex-item-guid">
                        <mat-label>Job Id</mat-label>
                        <input matInput [value]="selectedJobIdToFilter" formControlName="selectedJobIdToFilter">
                    </mat-form-field>
                    <mat-form-field id="job-search-form-field-jobStatus" class="flex-item">
                        <mat-label>Job Status</mat-label>
                        <mat-select formControlName="selectedJobStatusToFilter" [(value)]="selectedJobStatusToFilter" matInput>
                            <mat-option>None</mat-option>
                            <mat-option *ngFor="let status of jobStatuses" [value]="status">{{ status }}</mat-option>
                        </mat-select>
                    </mat-form-field>
                    <mat-form-field id="job-search-form-field-jobType" class="flex-item">
                        <mat-label>Job Type</mat-label>
                        <input matInput [value]="selectedJobTypeToFilter" formControlName="selectedJobTypeToFilter">
                    </mat-form-field>
                    <mat-form-field id="job-search-form-field-jobSubType" class="flex-item">
                        <mat-label>Job Name</mat-label>
                        <mat-select formControlName="selectedJobSubTypeToFilter" [(value)]="selectedJobSubTypeToFilter" matInput>
                            <mat-option>None</mat-option>
                            <mat-option *ngFor="let jobsubtype of jobSubTypes" [value]="jobsubtype">{{ jobsubtype.name }}</mat-option>
                        </mat-select>
                    </mat-form-field>
                </form>
            </mat-expansion-panel>
        </mat-accordion>
    </mat-card-content>
</mat-card>
<mat-card id="job-search-table-card" *ngIf="hasSearched">
    <mat-card-content>
        <app-job-search-table id="job-search-table" [(isLoading)]="isJobsLoading" [tableData]="jobs"
            [displayedColumns]="displayedColumns" [dataColumns]="dataColumns" [displayActions]="displayActions"></app-job-search-table>
    </mat-card-content>
</mat-card>