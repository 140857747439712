import { AfterViewInit, Component, Input, ViewChild, } from '@angular/core';
import { tenantSetting } from '../../../Models/Class-Interfaces/tenantSetting.model';
import { TenantAPIService } from '../../../Services/api.tenantApi.service';
import { FormBuilder, FormControl, FormGroup, FormsModule, NgForm, ReactiveFormsModule, Validators } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatDividerModule } from '@angular/material/divider';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { MatDialog, MatDialogModule } from '@angular/material/dialog';
import { MIPNotification, NotificationsService } from '../../../Shared/Notifications/notifications.service';
import { MIPNotificationType } from '../../../Shared/Notifications/mip-notification-type.enum';
import { ActivatedRoute, NavigationStart, Router } from '@angular/router';
import { MatProgressSpinner } from '@angular/material/progress-spinner';
import { environment } from '../../../../environments/environment';
import { MatTooltipModule } from '@angular/material/tooltip';
import { Subject, Observable, catchError, throwError, takeUntil, filter, of } from 'rxjs';
import { MatIconModule } from '@angular/material/icon';
import { validateCredentialRequest, validateCredentialSource } from '../../../Models/Class-Interfaces/validateCredentialRequest.model';
import { StateActionResult } from '../../../Models/Class-Interfaces/StateActionResult.model';
import { ActionPollResultDialogComponent } from '../../../Shared/action-pollresult-dialog/action-pollresult-dialog';
import { ActionPollResultDialogData } from '../../../Shared/action-pollresult-dialog/Models/ActionPollResultDialogData';
import { TenantFlowSecretValidationSupportedResult } from '../../../Models/Class-Interfaces/TenantFlowSecretValidationSupportedResult';

@Component({
  selector: 'app-credential',
    standalone: true,
    imports: [CommonModule, ReactiveFormsModule, MatTooltipModule ,MatProgressSpinner,
      FormsModule, MatInputModule, MatFormFieldModule, MatButtonModule, MatDividerModule,
      MatIconModule, MatDialogModule],
  templateUrl: './credential.component.html',
  styleUrl: './credential.component.scss'
})


export class CredentialComponent implements AfterViewInit {
  TYPE_API_SECRET: string = "api-secret";
  TYPE_API_GENERIC_SECRET: string = "api-generic-secret";
  TYPE_API_ALTAMETRICS_SECRET: string = "api-altm-secret";
  TYPE_API_DEPUTY_SECRET: string = "api-deputy-secret";
  TYPE_API_QUICKBOOKS_SECRET: string = "api-qbt-secret";
  TYPE_API_SEVENSHIFTS_SECRET: string = "api-sevenshifts-secret";
  TYPE_API_BAMBOOHR_SECRET: string = "api-bamboohr-secret";
  TYPE_API_WHENIWORK_SECRET: string = "api-wheniwork-secret";
  TYPE_API_PAYCHEX_SECRET: string = "paychex-secret";
  TYPE_DATABASE_CONNECTION: string = "databaseConnection";
  PLACEHOLDER_SECRET_TEXT: string = "**********";
  VALIDATE_ACTION_FLOW_TYPE: string = "action";
  VALIDATE_ACTION_FLOW_CODE: string = "secret-validation";
  tenantAPIService: TenantAPIService;
  uiNotificationService: NotificationsService;
  @Input({ required: true }) credential!: tenantSetting;
  @ViewChild('credentialForm') credentialForm!: NgForm;
  formGroup: FormGroup;
  credentialType: string = "";
  formlabelsList: [] = [];
  tokenRequired: boolean = false; // Used for flows like Deputy, 7Shifts
  companyIdRequired: boolean = false; // Used for flows like 7Shifts
  isConfigured: boolean = false;
  isSaving: boolean = false;
  isDirty: boolean = false;
  redirectUrl: string = '';
  validationSupported: boolean = false;
  validating: boolean = false;
  validationSuccess: boolean | null = null;

  // Subject to signal component destruction or page change.
  // Any long running operations or polling should stop when
  // this is emitted.
  private destroy$ = new Subject<void>();

  constructor(private activatedRoute: ActivatedRoute,
    tenantAPIService: TenantAPIService,
    uiNotificationService: NotificationsService,
    private router: Router,
    private dialog: MatDialog,
    formBuilder: FormBuilder) {
    //depending on the secret we will populate the form differently
    this.formGroup = formBuilder.group({});
    this.tenantAPIService = tenantAPIService;
    this.uiNotificationService = uiNotificationService;
  }
    ngOnInit() {
        this.isConfigured = this.credential?.isConfigured ?? false;
        let type = this.credential?.value ?? "";
        let accessToken = null; // Used for flows like Deputy, 7Shifts
        let companyId = null; // Used for flows like 7Shifts
        switch (type.toLowerCase()) {
            case "api-deputy-secret":
            case "api-deputy-secret-child":
              accessToken = this.activatedRoute.snapshot.queryParams['code'];
              //build api secret form
                this.formGroup.addControl("accessToken", new FormControl("", Validators.required));
                var redirectUri = environment.BaseUrl + "deputy/callback";
              if (accessToken) {
                  this.tokenRequired = false;
                  this.formGroup.controls["accessToken"].setValue(accessToken);
                  this.formGroup.controls["accessToken"].markAsDirty();
                  this.formGroup.addControl("redirectUri", new FormControl(redirectUri, Validators.required));
                  this.formGroup.controls["redirectUri"].markAsDirty();
              } else {
                  this.tokenRequired = true;
              }
                this.credentialType = this.TYPE_API_DEPUTY_SECRET;
                this.redirectUrl = "https://once.deputy.com/my/oauth/login?client_id=" + environment.DeputyClientId + "&redirect_uri=" + redirectUri + "&response_type=code&scope=longlife_refresh_token";
              break;
              case "api-qbt-secret":
              case "api-qbt-secret-child":
                accessToken = this.activatedRoute.snapshot.queryParams['code'];
                var stateParam = this.activatedRoute.snapshot.queryParams['state'];
                //build api secret form
                  this.formGroup.addControl("accessToken", new FormControl("", Validators.required));
                  var redirectUri = environment.BaseUrl + "qbt/callback";

                if (!stateParam) {
                  stateParam = crypto.randomUUID();
                }
                if (accessToken) {
                    this.tokenRequired = false;
                    this.formGroup.controls["accessToken"].setValue(accessToken);
                    this.formGroup.controls["accessToken"].markAsDirty();
                    this.formGroup.addControl("redirectUri", new FormControl(redirectUri, Validators.required));
                    this.formGroup.controls["redirectUri"].markAsDirty();
                } else {
                    this.tokenRequired = true;
                }
                  this.credentialType = this.TYPE_API_QUICKBOOKS_SECRET;
                  this.redirectUrl = "https://rest.tsheets.com/api/v1/authorize?response_type=code&client_id=" + environment.QuickbooksClientId + "&redirect_uri=" + redirectUri + "&state=" + stateParam;
                break;
            case this.TYPE_API_SEVENSHIFTS_SECRET:
            case  "api-sevenshifts-secret-child":
              accessToken = this.activatedRoute.snapshot.queryParams['guid'];
              companyId = this.activatedRoute.snapshot.queryParams['company_id'];
              //build api secret form
              this.formGroup.addControl("companyGUID", new FormControl("", Validators.required));
              this.formGroup.addControl("companyId", new FormControl("", Validators.required));
              if (accessToken) {
                  this.tokenRequired = false;
                  this.formGroup.controls["companyGUID"].setValue(accessToken);
                  this.formGroup.controls["companyGUID"].markAsDirty();
              } else {
                  this.tokenRequired = true;
              }

              if (companyId) {
                this.companyIdRequired = false;
                this.formGroup.controls["companyId"].setValue(companyId);
                this.formGroup.controls["companyId"].markAsDirty();
            } else {
                this.companyIdRequired = true;
            }
                this.credentialType = this.TYPE_API_SEVENSHIFTS_SECRET;
                this.redirectUrl = "https://app.7shifts.com/generate_token?client_id=" + environment.SevenShiftsClientId + "&redirect_uri=" + environment.BaseUrl + "sevenshifts/callback";
              break;
            case "api-secret":
              //build api secret form
              this.formGroup.addControl("uri", new FormControl("", Validators.required));
              this.formGroup.addControl("apiKey", new FormControl("", Validators.required));
              this.credentialType = this.TYPE_API_SECRET;
              break;
            case "api-generic-secret":
            case "api-generic-secret-child":
              //build api generic secret form
              this.formGroup.addControl("uri", new FormControl("", Validators.required));
              this.formGroup.addControl("clientId", new FormControl("", Validators.required));
              this.formGroup.addControl("clientSecret", new FormControl("", Validators.required));
              this.credentialType = this.TYPE_API_GENERIC_SECRET;
              break;           
            case "api-altm-secret":
            case "api-altm-secret-child":
                //build api secret form
                this.formGroup.addControl("uri", new FormControl("", Validators.required));
                this.formGroup.addControl("partnerKey", new FormControl("", Validators.required));
                this.credentialType = this.TYPE_API_ALTAMETRICS_SECRET;
              break;
            case "api-bamboohr-secret":
                //build api secret form
                this.formGroup.addControl("uri", new FormControl("", Validators.required));
                this.formGroup.addControl("apiKey", new FormControl("", Validators.required));
                this.credentialType = this.TYPE_API_BAMBOOHR_SECRET;
                break;
            case "paychex-secret":
            case "paychexsecrets":
                this.formGroup.addControl("uri", new FormControl("", Validators.required));
                this.formGroup.addControl("apiKey", new FormControl("", Validators.required));
                this.formGroup.addControl("userName", new FormControl("", Validators.required));
                this.formGroup.addControl("password", new FormControl("", Validators.required));
                this.credentialType = this.TYPE_API_PAYCHEX_SECRET;
                break;
            case "databaseconnection":
            case "database-connection":
                //build api secret form
                this.formGroup.addControl("host", new FormControl("", Validators.required));
                this.formGroup.addControl("port", new FormControl("", Validators.required));
                this.formGroup.addControl("databaseName", new FormControl("", Validators.required));
                this.formGroup.addControl("userName", new FormControl("", Validators.required));
                this.formGroup.addControl("password", new FormControl("", Validators.required));
                this.formGroup.addControl("databaseEngine", new FormControl("", Validators.required));
                this.credentialType = this.TYPE_DATABASE_CONNECTION;
                break;
            case "api-wheniwork-secret":
            case "api-wheniwork-secret-child":       
              this.formGroup.addControl("email", new FormControl("", Validators.required));
              this.formGroup.addControl("password", new FormControl("", Validators.required));
              this.credentialType = this.TYPE_API_WHENIWORK_SECRET;
              break;

            default:
                break;
        }

        this.tenantAPIService.validateCredentialsSupported(this.credential?.settingName).subscribe({
          next: (isSupported: TenantFlowSecretValidationSupportedResult) => {
            this.validationSupported = isSupported.supported;
          },
          error: (error) => {
            console.error('Error checking credentials support:', error);
          },
          complete: () => {
          }
        });

        this.router.events.pipe(filter(event => event instanceof NavigationStart))
              .subscribe((e) => {
                // Stop current subscriptions.
                this.destroy$.next();
              });
  }

  ngAfterViewInit() {
    console.log("on view init")
    // Subscribe to form changes
    this.credentialForm.valueChanges?.subscribe(() => {
      this.isDirty = true;
      this.validationSuccess = null;
    });
  }

  // We cannot save if
  saveButtonDisabled = () => {
    return !this.formGroup.valid || this.isSaving || this.validating || this.validationSuccess === false;
  }

  getDisplayName = () => {
    if (this.credential) {
      return this.credential?.displayName ?? this.credential.settingName
    }
    else {
      return "API Setting"
    }
  }
  shouldFloat = () => {
    return this.isConfigured ? "always" : 'auto';
  }

  private formGroupToRecord(formGroup: Record<string, any>): Record<string, string> {
    const formRecord: Record<string, string> = {};
      for (const key in formGroup['controls']) {
          if (key == "uri" && this.credentialType == this.TYPE_API_BAMBOOHR_SECRET) {
              formRecord[key] = "https://api.bamboohr.com/api/gateway.php/" + this.formGroup.get(key)?.value + "/v1/"
          }
          else {
              formRecord[key] = this.formGroup.get(key)?.value;
          }
    }
    return formRecord;
  }

  validateTenantSettingsClick = (validateExisting: boolean) => {
    this.validateTenantSetting(validateExisting).subscribe({
      next: (result) => {
        // Handle the validation result if needed
        if (result) {
          // Validation successful
          console.log('Validation successful');
        } else {
          // Validation failed
          console.log('Validation failed');
        }
      },
      error: (error) => {
        console.error('Error during validation:', error);
      }
    });
  }
  
  validateTenantSetting = (validateExisting: boolean, autoCloseDialog: boolean = false): Observable<boolean> => {
    if (!this.validationSupported) {
      return of(true); 
    }
  
    if (this.credential == null || this.credential.settingName == null) {
      console.error("Setting name is required to validate.");
      return of(false);
    }
  
    const request: validateCredentialRequest = {
      source: validateExisting ? validateCredentialSource.Existing : validateCredentialSource.Request,
      secretValues: this.formGroupToRecord(this.formGroup)
    }
    const headers = { };
  
    this.validating = true;
    
    return new Observable<boolean>(observer => {
      this.tenantAPIService.validateCredentialsStart(this.credential.settingName, request, headers).subscribe({
        next: (result: StateActionResult) => {
          const dialogRequest: ActionPollResultDialogData = {
            autoCloseSuccess: autoCloseDialog,
            request: {
              flowType: this.VALIDATE_ACTION_FLOW_TYPE,
              flowCode: this.VALIDATE_ACTION_FLOW_CODE,
              stateActionCode: result.stateActionCode,
              stateActionId: result.stateActionId
            },
            busy: {
              title: "Credentials Validating",
              message: null
            },
            success: {
              title: "Credentials Validated",
              message: "Your credentials were successfully validated.",
            },
            error: {
              title: "Unable to Validate Credentials",
              message: "Something went wrong and we weren't able to validate the credentials that were entered.\n\nPlease try again. If this error continues, please contact your support rep.",
            }
          };
  
          const dialogRef = this.dialog.open(ActionPollResultDialogComponent, {
            data: dialogRequest
          });
  
          dialogRef.afterClosed().subscribe(result => {
            this.validating = false;
            this.validationSuccess = result;
            observer.next(result);
            observer.complete();
          });
        },
        error: (error) => {
          console.error('Error validating credentials:', error);
          this.validating = false;
          this.validationSuccess = false;
          observer.next(false);
          observer.complete();
        }
      });
    });
  }

  saveTenantSettingClick = () => {
    if (!this.formGroup.valid) {
      return;
    }
    if (this.credential == null || this.credential.settingName == null) {
      console.error("Setting name is required to save.");
      return;
    }
    if (this.validationSupported) {
      if (this.validationSuccess === true) {
        // Already validated, move on to save
        this.saveTenantSetting();
      }
      else if (this.validationSuccess === false) {
        // validation already failed, don't bother saving
        console.error("Cannot save, validation failed");
        return;
      }
      else if (this.validationSuccess === null) {
        // validation not run, validate and run through the logic again.
        this.validateTenantSetting(false, true).subscribe({
          next: (result) => {
              this.saveTenantSettingClick();
          }
        })
      }
    }
    else {
      // validation not supported, ship it.
      this.saveTenantSetting();
    }

    
  }

  saveTenantSetting = () => {
    if (this.formGroup.valid) {

      if (this.credential == null || this.credential.settingName == null) {
        console.error("Setting name is required to save.");
        return;
      }

      if (this.validationSupported && this.validationSuccess !== true) {
        console.error("Cannot save, validation failed");
        return;
      }

      this.isSaving = true;
      this.tenantAPIService.putTenantSetting(this.credential.settingName, this.credential?.value ?? "", this.formGroupToRecord(this.formGroup)).subscribe({
        error: (result: any) => { 
          this.isSaving = false;
          console.log(result);
        },
        complete: () => {
          this.saveTenantSettingComplete();
        }
    });

    }
  }

  saveTenantSettingComplete = () => {
    this.isSaving = false;
    this.uiNotificationService.queueNotification({
      type: MIPNotificationType.Success,
      title: "Updated",
      message: "Saved Tenant Setting."
    } as MIPNotification);

    this.router.navigateByUrl('/', {skipLocationChange: true}).then(()=>
      this.router.navigate(['/settings'])
 );
  }
}

