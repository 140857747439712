<mat-grid-list cols="3" rowHeight="fit">
  <mat-grid-tile></mat-grid-tile>
  <mat-grid-tile>
    <div class="amplify-block" >
      <amplify-authenticator [hideSignUp]="true">
        <ng-template amplifySlot="sign-in">
          <div class="header-sso-login">
            <h6 class="h6">Log in with Single Sign-On and access your integration screens seamlessly.</h6>
          </div>
          <div class="container-button-sso-login">
            <button type="button" class="button-sso-login" (click)="federatedSignIn()">Log In with Flex</button>
          </div>
          <div class="container-payx-copyright-sso-login">
            <p class="payx-copyright-sso-login">&copy; 2025 Paychex </p>
          </div>
          <div class="privacy-and-terms-container-sso-login">
            <a href="{{privacyPolicyDocumentURL}}" class="privacy-and-terms-link-sso-login link">
              Privacy Policy
            </a>
            <p style="margin: 0; padding-top: 6px;">&bull;</p>
            <a href="{{termsAndConditionsDocumentURL}}" class="privacy-and-terms-link-sso-login link">
              Terms & Conditions
            </a>
          </div>
        </ng-template>
      </amplify-authenticator>
    </div>
  </mat-grid-tile>
  <mat-grid-tile></mat-grid-tile>
</mat-grid-list>