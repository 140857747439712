import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatToolbarModule } from '@angular/material/toolbar';
import { AuthenticatorService } from '@aws-amplify/ui-angular';
import { AccountAvatarComponent } from './account-avatar/account-avatar.component';

@Component({
  selector: 'app-header',
  standalone: true,
  imports: [CommonModule, MatToolbarModule, AccountAvatarComponent],
  templateUrl: './header.component.html',
  styleUrl: './header.component.scss'
})
export class HeaderComponent {

    public userInfoLoaded: boolean = false;

    constructor(
        public authenticator: AuthenticatorService
    ){}
    getIsAuthenticated(): boolean {
        return this.authenticator.route === "authenticated";
    }
}