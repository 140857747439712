import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { MatListModule } from '@angular/material/list';
import { MatSidenavModule } from '@angular/material/sidenav';
import { CognitoService } from '../../Services/cognito.service';
import { Router, RouterLink, RouterLinkActive } from '@angular/router';
import { NavBarItemComponent } from '../Nav-bar-item/nav-bar-item.component';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { TenantAPIService } from '../../Services/api.tenantApi.service';
import { flow } from '../../Models/Class-Interfaces/flow.model';
import { jobType } from '../../Pages/jobs/models/jobtypes.model';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';


@Component({
  selector: 'app-nav-bar',
  standalone: true,
  imports: [MatSidenavModule, MatListModule, NavBarItemComponent, RouterLinkActive, RouterLink, MatIconModule, MatTooltipModule, NavBarItemComponent, CommonModule, MatProgressSpinnerModule],
  templateUrl: './nav-bar.component.html',
  styleUrl: './nav-bar.component.scss'
})
export class NavBarComponent {
  constructor(private cognitoService: CognitoService, public router: Router, public tenantService:TenantAPIService) { }

  public integrationsLoaded:boolean = true;
  public failedlLoadingIntegrations:boolean=true;
  public integrations:flow[] = [];
  ngOnInit() {
   this.loadIntegrations();
  }
  public signOut(event: any) {
    this.cognitoService.handleSignOut();
  }
loadIntegrations = () => {
  this.integrationsLoaded = !this.integrationsLoaded;
  this.tenantService.getJobTypes()
  .subscribe({
    next:(result:jobType[])=>{
      this.integrations = result.map((item: jobType) =>{
        let flow: flow = { name: item.name, code: item.jobTypeId, version: item.version };
        return flow;
      })
    },
    
    error: () => { this.integrationsLoaded = true; },
    complete: () => { this.integrationsLoaded = true; }
   });
   
  }
}
