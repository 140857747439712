<div class="job-details-dialog">
    <div class="job-details-dialog-content">
        <h2 mat-dialog-title>Job Details</h2>
        <mat-dialog-content>
            <mat-grid-list cols="2" rowHeight="50px">
                <mat-grid-tile>
                    <p class="left-align"><strong>Job Name: </strong>{{ job.jobName }}</p>
                </mat-grid-tile>
                <mat-grid-tile>
                    <p class="left-align"><strong>Job Type: </strong>{{ job.jobSubType }}</p>
                </mat-grid-tile>
            </mat-grid-list>

            <mat-grid-list cols="2" rowHeight="60px">
                <mat-grid-tile>
                    <p class="left-align"><strong>Job ID: </strong>{{ job.jobId }}</p>
                </mat-grid-tile>
                <mat-grid-tile>
                    <p class="left-align"><strong>Status: </strong>{{ job.jobStatus }}</p>
                </mat-grid-tile>
            </mat-grid-list>

            <mat-grid-list cols="1" rowHeight="60px">
                <mat-grid-tile>
                    <p class="left-align"><strong>Description: </strong>{{ job.jobDescription }}</p>
                </mat-grid-tile>
            </mat-grid-list>

            <mat-grid-list cols="2" rowHeight="60px">
                <mat-grid-tile>
                    <p class="left-align"><strong>Created Date: </strong>{{ formattedDate() }}</p>
                </mat-grid-tile>
                <mat-grid-tile>
                    <p class="left-align"><strong>Created By: </strong>{{ job.createdByUserName }}</p>
                </mat-grid-tile>
            </mat-grid-list>
            
            <mat-grid-list cols="1" rowHeight="30px">
                <mat-grid-tile>
                    <p class="left-align"><strong>Exportable Files</strong></p>
                </mat-grid-tile>
            </mat-grid-list>
            
            <app-exportable-files *ngIf="isJobCompleted" [jobId]="job.jobId ?? ''"></app-exportable-files>
            <div class="center" *ngIf="!isJobCompleted">
                <span><mat-spinner [diameter]="34"></mat-spinner></span>
            </div>
        </mat-dialog-content>
    </div>
</div>