import { CommonModule } from '@angular/common';
import { Component, OnInit, Inject, ViewChild, SimpleChanges} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogActions, MatDialogContent, MatDialogModule } from '@angular/material/dialog';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { jobItem } from '../../jobs/models/jobitem.model';
import { job } from '../../jobs/models/job.model';
import { MatCardModule } from '@angular/material/card';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';

  export interface JobItemErrorDetailsDialogData {
    job: job;
    jobItem: jobItem;
  }

@Component({
  selector: 'app-job-item-error-details-dialog',
  standalone: true,
  imports: [CommonModule, MatDialogModule, MatDialogContent, MatDialogActions, MatProgressSpinnerModule, MatGridListModule, MatTableModule, MatCardModule, MatPaginator, MatSort],
  templateUrl: './job-item-error-details-dialog.component.html',
  styleUrl: './job-item-error-details-dialog.component.scss'
})
export class JobItemErrorDetailsDialogComponent {
  dataSource: MatTableDataSource<{ key: string; value: string }> = new MatTableDataSource();

  @ViewChild(MatPaginator, {static: false})
  set paginator(value: MatPaginator) {
    if (this.dataSource){
      this.dataSource.paginator = value;
    }
  }

  @ViewChild(MatSort, {static: false})
  set sort(value: MatSort) {
    if (this.dataSource){
      this.dataSource.sort = value;
    }
  }

  constructor(@Inject(MAT_DIALOG_DATA) public jobAndJobItem: JobItemErrorDetailsDialogData) {}

  ngOnInit(): void {
    this.dataSource.data = this.getKeyValuePairs();
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  getKeyValuePairs(): { key: string; value: string }[] {
    return Object.entries(this.jobAndJobItem.jobItem.additionalDetails).map(([key, value]) => ({
      key,
      value,
    }));
  }
}