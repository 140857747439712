import { BehaviorSubject, filter, map, Observable } from "rxjs";
import { emailSettings } from "../Pages/email-notification-settings/models/emailSettings.model";
import { EmailNotificationService } from "./api.emailNotification.service";
import { CognitoService } from "./cognito.service";
import { TenantAPIService } from "./api.tenantApi.service";
import { Injectable } from "@angular/core";

@Injectable({
    providedIn: 'root',
})
export class EmailSettingsService {

    private emailSettings: { [category: string]: BehaviorSubject<emailSettingsState> } = {};

    constructor(
        private cognitoService: CognitoService,
        private emailAPIService: EmailNotificationService,
        private tenantService: TenantAPIService
    ) { 
        emailAPIService.emailSettingsUpdate.subscribe(
            (next) => {
                let subj = this.emailSettings[next.category];
                if(!subj) {
                    subj = new BehaviorSubject<emailSettingsState>({ emailSettings: next.emailSettings, loaded: true }); 
                    this.emailSettings[next.category] = subj;
                } else {
                    subj.next({ emailSettings: next.emailSettings, loaded: true });
                }
            }
        )

        // On login, load in the current email settings for all the integrations
        cognitoService.isUserLoggedIn.subscribe({
            next: (isLoggedIn) => {
                if(isLoggedIn) {
                    this.tenantService.getJobTypes()
                    .subscribe({
                        next: (jobTypes) => {
                            jobTypes.forEach((val, i, arr) => this.getEmailSettingSubj(val.jobTypeId))
                        }
                    });
                }
            }    
        });
    }

    private getEmailSettingSubj(category: string): BehaviorSubject<emailSettingsState> {
        if(!this.emailSettings[category]) {
            this.emailSettings[category] = new BehaviorSubject<emailSettingsState>({
                emailSettings: {
                    emailFromAddress: null,
                    emailMessage: null,
                    emailRecipients: null,
                    emailSubject: null,
                    notifcationsEnabled: false,
                    sendAsBCC: false
                },
                loaded: false
            });
            this.emailAPIService.getEmailNotificationSettings(category)
                .subscribe({
                    next: (currentEmailSettings) => this.emailSettings[category].next({ emailSettings: currentEmailSettings, loaded: true }),
                    error: (ex) => {
                        console.log(ex);
                        //Set the category as loaded if the load failed.
                        this.emailSettings[category].next({ emailSettings: this.emailSettings[category].value.emailSettings, loaded: true });
                    }
                });
        }
        return this.emailSettings[category];        
    }

    getEmailSetting(category: string): Observable<emailSettings> {
        return this.getEmailSettingSubj(category).pipe(
            filter((state) => state.loaded),
            map((state) => state.emailSettings)
        );
    }

    getCurrentEmailSetting(category: string): emailSettings {
        return this.getEmailSettingSubj(category).value.emailSettings;
    }
}

class emailSettingsState {
    emailSettings: emailSettings;
    loaded: boolean;
}