import { EventEmitter, Injectable, Output } from '@angular/core';
const currentUserNameKey: string = "currentUserName";

@Injectable({
    providedIn: 'root',
})

export class SessionService {
    constructor() {
    }

    setSessionUserName(value: string): void {
        localStorage.setItem(currentUserNameKey, value);
    }

    getSessionUserName(): Promise<string> {
        return new Promise<string>((resolve) => {
            resolve(this.getUserName());
            return this.getUserName();
        });
    }

    setUserItem(key: string, value: string): void{
        const storageKey = key;
        localStorage.setItem(storageKey, value);   
    }

    getUserItem(key: string): Promise<string> {
        return new Promise<string>((resolve) => {
            const storageKey = key;
            var item = localStorage.getItem(storageKey) ?? ""; 
            resolve(item);
            return item;
        });   
    }

    private getStorageKey(key: string): string {
        return this.getUserName() + key;
    }

    private getUserName() {
        return localStorage.getItem(currentUserNameKey) ?? "";
    }

    
}
