import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class IntegrationStatusService {
  private integrationStatusSubject = new BehaviorSubject<boolean>(false);
  isIntegrationEnabled$ = this.integrationStatusSubject.asObservable();

  constructor() {}

  get isEnabled(): boolean {
    return this.integrationStatusSubject.getValue();
  }

  setIsIntegrationEnabled(state: boolean): void {
    this.integrationStatusSubject.next(state);
  }
}
