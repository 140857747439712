<div class="dialog-container dialog-container-info job-item-error-dialog">
    <div class="dialog-title-container dialog-title-container-info dialog-title-container-job-item-error">
        <h1 mat-dialog-title class="dialog-title-text">Job Items - Errors and Warnings</h1>
        <button mat-icon-button mat-dialog-close class="button-close button-dialog-close" [disableRipple]="true">
            <mat-icon>close</mat-icon>
        </button>
    </div>
    <mat-dialog-content>
        <div *ngIf="!jobItemsLoaded"
            style="display: flex; justify-content: center; align-items: center; background: white;">
            <mat-progress-spinner color="primary" mode="indeterminate">
            </mat-progress-spinner>
        </div>
        <div *ngIf="jobItemsLoaded" class="dialog-content-container">
            <div class="field-readonly">
                <span class="field-label-readonly">Job ID: </span>
                <span class="field-value-readonly">{{ job.jobId }}</span>
            </div>
            <div class="field-readonly">
                <span class="field-label-readonly">Status: </span>
                <span class="field-value-readonly">{{ job.jobStatus }}</span>
            </div>
            <div *ngIf="this.jobItems.length == 0">
                <div class="no-errors-container">
                    <mat-icon class="icon-color-success icon-large" >check_circle</mat-icon>
                    <span class="message">No Errors or Warnings</span>
                </div>
            </div>
            <app-job-item-error-table *ngIf="this.jobItems.length != 0" [(isLoading)]="!jobItemsLoaded" [tableData]="this.jobItems" id="job-item-error-table">
            </app-job-item-error-table>
        </div>
    </mat-dialog-content>
</div>