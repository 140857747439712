import { Component, OnDestroy, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CognitoService } from '../../Services/cognito.service';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { FormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatGridListModule } from '@angular/material/grid-list';
import { Observable, map } from 'rxjs';
import { MatIconModule } from '@angular/material/icon';
import { TenantAPIService } from '../../Services/api.tenantApi.service';
import { SessionService } from '../../Services/session.service';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { AmplifyAuthenticatorModule } from '@aws-amplify/ui-angular';
import { Hub } from 'aws-amplify/utils';
import { AuthenticatorService } from '@aws-amplify/ui-angular';
import { environment } from '../../../environments/environment';
import { signInWithRedirect } from 'aws-amplify/auth';

@Component({
    selector: 'app-login',
    standalone: true,
    imports: [CommonModule, FormsModule, MatInputModule, MatButtonModule, MatCardModule, MatGridListModule, MatIconModule, MatProgressSpinnerModule, AmplifyAuthenticatorModule],
    templateUrl: './loginSSO.component.html',
    styleUrl: './loginSSO.component.scss',
})
export class SSOLoginComponent implements OnInit, OnDestroy {
    mfaHelpDisplayURL: string = environment.MFAHelpDisplayUrl;
    privacyPolicyDocumentURL: string = environment.PrivacyPolicyDocumentURL;
    termsAndConditionsDocumentURL: string = environment.TermsAndConditionsDocumentURL;
    getSupportURL: string = environment.GetSupportUrl;
    showSessionExpired$: Observable<boolean> = new Observable<boolean>();
    showSessionExpiredRaw$: Observable<string | null> = new Observable<string | null>();
    returnUrl$: Observable<string | null> = new Observable<string | null>();
    loading: boolean = false;
    state: string = 'loading';
    
    constructor(
        private cognitoService: CognitoService,
        private router: Router,
        private route: ActivatedRoute,
        private authenticatorService: AuthenticatorService
    ) {}
    federatedSignIn() {
        signInWithRedirect({provider: {custom: environment.Auth.Cognito.identityProviderName[0]}});
    }
    ngOnInit() {
        this.returnUrl$ = this.route.queryParamMap.pipe(
            map((params: ParamMap) => params.get('returnUrl')),
        );

        this.showSessionExpired$ = this.route.queryParamMap.pipe(
            map((params: ParamMap) => params.get('expnotify') ? params.get('expnotify') === "1" : false),
        );
    }
    isPending = (): boolean => {
        return this.authenticatorService.isPending;
    }
    listener = Hub.listen('auth', (data) => {
        const { payload } = data;
        var returnUrl = "";
        this.returnUrl$.subscribe(x => returnUrl = (x ?? ""));
        this.cognitoService.onAuthEvent(payload);
        if (returnUrl != "") {
            this.router.navigateByUrl("/" + returnUrl);
        } else {
            this.router.navigate(['/']);
        }
    });
    ngOnDestroy(): void {
        this.listener();
    }
    onForgotPassword(): void {
        this.authenticatorService.toForgotPassword();
      }
      
}
