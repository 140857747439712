import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Params, Router } from '@angular/router';
import { EmailNotificationService } from '../../Services/api.emailNotification.service';
import { emailSettings } from './models/emailSettings.model';
import { MatCardModule } from '@angular/material/card';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { FormGroup, FormControl, FormsModule, ReactiveFormsModule, FormBuilder, Validators } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { MIPNotification, NotificationsService } from '../../Shared/Notifications/notifications.service';
import { MIPNotificationType } from '../../Shared/Notifications/mip-notification-type.enum';
import { MatProgressSpinner } from '@angular/material/progress-spinner';
import { constants } from '../../Shared/constants';
import { EmailSettingsService } from '../../Services/email-settings.service';
import { ResetNotificationsService } from '../../Services/reset-notifications.service';
import { environment } from '../../../environments/environment';
import { filter } from 'rxjs';
import { RouteContextService } from '../../Services/route-context.service';
@Component({
  selector: 'app-email-notification-settings',
  standalone: true,
  imports: [CommonModule, ReactiveFormsModule, FormsModule, MatProgressSpinner, MatButtonModule, MatCardModule, MatCheckboxModule, MatFormFieldModule, MatInputModule],
  templateUrl: './email-notification-settings.component.html',
  styleUrl: './email-notification-settings.component.scss'
})
export class EmailNotificationSettingsComponent {
  flowCode: string | null = null;
  fromEmailAddress: string = "";
  setting: emailSettings = {} as emailSettings;
  loading: boolean = false;
  showSubjectAndMessage: boolean = false;
  enableNotificon: boolean = false;
  emailSettingsForm: FormGroup;
  isSaving: boolean = false;
  isResettingNotifications: boolean = false;

  constructor(
    private routeContext: RouteContextService,
    private emailAPIService: EmailNotificationService, 
    private uiNotificationService: NotificationsService, 
    private formbuilder: FormBuilder,
    private emailSettingsService: EmailSettingsService,
    private resetNotificationsService: ResetNotificationsService
  ) {
    this.emailSettingsForm = this.formbuilder.group({
      notificationsEnabled: new FormControl(),
      recipients: new FormControl(null, Validators.required),
      subject: new FormControl(null, Validators.required),
      message: new FormControl(null, Validators.required)
    });

  }

  ngOnInit() {
    if(environment.EmailNotificationSwitch.DisplaySubjectAndMessage){
      this.showSubjectAndMessage = true;
    }

    this.routeContext.params$.subscribe(params => {
      var flowCode = params['flowCode'];
      this.onFlowCodeChange(flowCode);
    })
  }

  onFlowCodeChange(flowCode: string | null) {
    if (flowCode === this.flowCode) {
      console.log("Same flow code, skipping update");
      return;
    }

    console.log(`Email Notification Flow Code '${this.flowCode}' -> '${flowCode}'`)
    this.flowCode = flowCode;
    this.loadEmailSettings();
  }

  loadEmailSettings() {
    if (!this.flowCode) {
      return;
    }

    this.loading = true;
    this.emailSettingsService.getEmailSetting(this.flowCode)
      .subscribe({
        next: (result: emailSettings) => {
          this.setting = result;
          if (this.setting) {
            this.emailSettingsForm.controls["notificationsEnabled"].setValue(this.setting.notifcationsEnabled);
            this.emailSettingsForm.controls["recipients"].setValue(this.setting.emailRecipients);
            this.emailSettingsForm.controls["subject"].setValue(this.setting.emailSubject);
            this.emailSettingsForm.controls["message"].setValue(this.setting.emailMessage);
            this.fromEmailAddress = this.setting.emailFromAddress ?? "";
            this.enableNotificationsCheckChanged(this.setting.notifcationsEnabled);
          }
          else {
            this.enableNotificationsCheckChanged(false);
          }
          this.loading = false;
        },
      }
    );
  }

  enableNotificationsCheckChanged = (checked: boolean) => {
    if (!checked) {
      this.emailSettingsForm.controls["recipients"].disable();
      this.emailSettingsForm.controls["subject"].disable();
      this.emailSettingsForm.controls["message"].disable();

      this.emailSettingsForm.controls["recipients"].clearValidators();
      this.emailSettingsForm.controls["subject"].clearValidators();
      this.emailSettingsForm.controls["message"].clearValidators();
    }
    else {
      this.emailSettingsForm.controls["recipients"].enable();
      this.emailSettingsForm.controls["subject"].enable();
      this.emailSettingsForm.controls["message"].enable();

      this.emailSettingsForm.controls["recipients"].setValidators(Validators.required);
      this.emailSettingsForm.controls["subject"].setValidators(Validators.required);
      this.emailSettingsForm.controls["message"].setValidators(Validators.required);
    }
    this.emailSettingsForm.controls["recipients"].updateValueAndValidity();
    this.emailSettingsForm.controls["subject"].updateValueAndValidity();
    this.emailSettingsForm.controls["message"].updateValueAndValidity();
  }

  saveEmailSettings = () => {
    if (!this.flowCode) {
      return;
    }

    this.isSaving = true;

    const emailSettingsPostRequest: emailSettings = 
    {
      notifcationsEnabled: this.emailSettingsForm.controls["notificationsEnabled"].value,
      emailRecipients: this.emailSettingsForm.controls["recipients"].value,
      emailFromAddress: this.fromEmailAddress,
      emailSubject: this.emailSettingsForm.controls["subject"].value,
      emailMessage: this.emailSettingsForm.controls["message"].value,
      sendAsBCC: false,
    }

    this.emailAPIService.postEmailNotificationSettings(this.flowCode, emailSettingsPostRequest)
    .subscribe({
      complete: () => {  
        this.showSaveEmailSettingsSuccessful() 
      },
      error: (err) => {
        this.isSaving = false;
      },
    });

    // Update our 'reference' setting object to reflect the updated saved values.
    this.setting.notifcationsEnabled = this.emailSettingsForm.controls["notificationsEnabled"].value;
    this.setting.emailRecipients = this.emailSettingsForm.controls["recipients"].value;
    this.setting.emailSubject = this.emailSettingsForm.controls["subject"].value;
    this.setting.emailMessage = this.emailSettingsForm.controls["message"].value;
  }

  // On cancel changes, revert the settings to how they were.
  onCancel = () => {
    this.emailSettingsForm.controls["notificationsEnabled"].setValue(this.setting.notifcationsEnabled);
    this.emailSettingsForm.controls["recipients"].setValue(this.setting.emailRecipients);
    this.emailSettingsForm.controls["subject"].setValue(this.setting.emailSubject);
    this.emailSettingsForm.controls["message"].setValue(this.setting.emailMessage);
    this.enableNotificationsCheckChanged(this.setting.notifcationsEnabled);
  }

  canSave = () => {
    // Can save if
    // form is valid
    // we haven't already saved with no changes
    // there are changes
    return this.emailSettingsForm.valid && this.anyFormChangesFromSavedValues();
  }

  canResetNotificationStatuses = () => {
    return !this.anyFormChangesFromSavedValues() && this.setting.notifcationsEnabled && !this.isSaving && !this.isResettingNotifications;
  }

  // If any changes occured, we can save. If we haven't made any changes, don't allow the user to save or cancel.
  anyFormChangesFromSavedValues = () => {
    return this.setting.notifcationsEnabled != this.emailSettingsForm.controls["notificationsEnabled"].value ||
    this.setting.emailRecipients != this.emailSettingsForm.controls["recipients"].value ||
    this.setting.emailSubject != this.emailSettingsForm.controls["subject"].value ||
    this.setting.emailMessage != this.emailSettingsForm.controls["message"].value;
  }

  showSaveEmailSettingsSuccessful = () => {
    this.isSaving = false;
    this.uiNotificationService.queueNotification({
      type: MIPNotificationType.Success,
      title: constants.common.updated,
      message: "Saved Email Notification Settings"
    } as MIPNotification);
  }

  resetNotificationStatuses = () => {
    if (!this.flowCode) {
      return;
    }
    this.isResettingNotifications = true;
    this.resetNotificationsService.resetNotifications(this.flowCode)
    .subscribe({
      complete: () => {  
        this.resetNotificationsService.onSuccess();
        this.isResettingNotifications = false;
      },
      error: (err) => {
        console.log(err);
        this.resetNotificationsService.onFailure();
        this.isResettingNotifications = false;
      }
    });    
  }

  showResetNotificationStatusesDialog = () => {
    this.resetNotificationsService.promptResetNotificationsOnReset((performReset) => {
      if(performReset) {
        this.resetNotificationStatuses();
      }
    });
  }

  disableForm = () => {
    return this.emailSettingsForm.controls["notificationsEnabled"].value;
  }
}
