import { Routes } from '@angular/router';
import { DashboardComponent } from './Pages/dashboard/dashboard.component';
import { TransferComponent } from './Pages/transfer/transfer.component';
import { loginAuthGuard, mainAuthGuard } from './Auth/auth.guard';
import { LoginComponent } from './Pages/login/login.component';
import { NoTenantComponent } from './Pages/notenant/notenant.component';
import { SSOLoginComponent } from './Pages/login-sso/loginSSO.component';
import { JobsComponent } from './Pages/jobs/jobs.component';
import { JobSearchComponent } from './Pages/job-search/job-search.component';
import { IntegrationComponent } from './Pages/integration/integration.component';
import { SettingsComponent } from './Pages/settings/settings.component';
import { environment } from '../environments/environment';
import { OfferingComponent } from './Pages/offering/offering.component';


export const routes: Routes = [
    {
        path: 'callback',
        loadComponent: () => import('./Pages/callback/callback.component').then(m => m.CallbackComponent),
    },
    {
        path: 'login',
        component: environment.LoginFeatureSwitch.SSOLogin ? SSOLoginComponent : LoginComponent,
        canActivate: [loginAuthGuard],
    },
    {
        path: 'notenant',
        component: NoTenantComponent,
        canActivate: [mainAuthGuard],
    },
    {
        path: 'transfer',
        component: TransferComponent,
        canActivate: [mainAuthGuard],
    },
    {
        path: 'jobs',
        component: JobsComponent,
        canActivate: [mainAuthGuard],
    },
    {
        path: 'jobs/search',
        component: JobSearchComponent,
        canActivate: [mainAuthGuard],
    },
    {
        path: 'offering/:offeringCode',
        component: OfferingComponent,
        canActivate:[mainAuthGuard],
        children: [
            {
                path: 'integration',
                component: OfferingComponent,
            },
            {
                path: 'integration/:flowCode',
                component: OfferingComponent,
            }
        ]
    },
    {
        path: 'integration/:flowCode',
        component: IntegrationComponent,
        canActivate:[mainAuthGuard]
    },
    {
        path: 'settings',
        component: SettingsComponent,
        canActivate:[mainAuthGuard]
    },
    {
        path: 'deputy/callback',
        component: SettingsComponent,
        canActivate: [mainAuthGuard]
    },
    {
        path: 'qbt/callback',
        component: SettingsComponent,
        canActivate: [mainAuthGuard]
    },
    {
        path: 'sevenshifts/callback',
        component: SettingsComponent,
        canActivate: [mainAuthGuard]
    },
    { path: '', redirectTo: '/settings', pathMatch: 'full' },
    { path: '**', redirectTo: '/settings' },
];
