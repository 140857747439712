import { ChangeDetectorRef, Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatGridListModule } from '@angular/material/grid-list';
import { TenantAPIService } from '../../Services/api.tenantApi.service';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatSelectModule } from '@angular/material/select';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatIconModule } from '@angular/material/icon';
import { firstValueFrom, forkJoin, Subject, tap } from 'rxjs';
import { MatDividerModule } from '@angular/material/divider';
import { tenantSetting } from '../../Models/Class-Interfaces/tenantSetting.model';
import { tenantFlowResult } from '../integration/models/integration-models';
import { tenantFlowSetting } from '../../Models/Class-Interfaces/tenantFlowSetting.model';
import { FlowSettingsService } from '../../Services/api.flowSettings.service';
import { flowDefinitionSetting } from '../jobs/models/flowDefinitionSetting';
import { ResetNotificationsService } from '../../Services/reset-notifications.service';
import { TableComponent } from '../../Shared/table/table.component';
import { actions, actionTypes, themeType } from '../../Shared/models/actions.model';
import { BulkUploadComponent } from '../../Shared/bulk-upload-dialog/bulk-upload-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { TableSettingsRecordComponent } from '../../Shared/tablesettings-record-dialog/tablesettings-record-dialog.component';
import { ConfirmationDialogComponent } from '../../Shared/confirmation-dialog/confirmation-dialog.component';


@Component({
    selector: 'app-table-settings',
    standalone: true,
    imports: [CommonModule, MatCardModule, MatGridListModule, MatSelectModule, MatFormFieldModule, MatButtonModule, MatProgressSpinnerModule, MatIconModule, MatDividerModule, TableComponent],
    templateUrl: './table-settings.component.html',
    styleUrl: './table-settings.component.scss',
})
export class TableSettingsComponent implements OnChanges {
    @Input() integration: tenantFlowResult | null;
    tenantFlowSettings: tenantFlowSetting[] = [];
    flowDefinitionSettings: flowDefinitionSetting[] = [];
    tableSettingsLoaded: boolean = false;
    tableSettings: tenantSetting[] = [];
    selectedTableSettings: tenantSetting | null = null;

    //table vars
    tenantTableSettingItems: any[] = [];
    tenantTableSettingItemsLoaded: boolean = true;
    paginationToken: string | undefined = undefined;
    dataColumns: string[] = []; //data properties to display
    displayedColumns: string[] = []; //pretty job names to display
    selectedTableSettingColumns: flowDefinitionSetting[] = []
    upsertedTableSettingItem: any[] = [];
    displayActions: boolean = false; //determines if the action column will show up
    maxPageSize: number = 200;
    lastPageSize: number | undefined = undefined;
    hasMoreResults: boolean = true;
    isNextPageLoading: boolean = false; // Used for loading the next page of results after the initial search
    isPageLoading: boolean = false;// Used for the table spinner. Spinner exists on table component two-way binding.

    actionsList: actions[] = [];

    activeJobEvent: Subject<string> = new Subject<string>();
    dialog: MatDialog;

    bulkUploadDialogWidth: '37.5rem';

    constructor(
        private flowSettingsAPI: FlowSettingsService,
        private tenantAPI: TenantAPIService,
        private resetNotificationsService: ResetNotificationsService,
        private cdr: ChangeDetectorRef,
        private dl: MatDialog
    ) {
        this.dialog = this.dl;
    }

    ngOnInit() {
        this.actionsList.push(new actions("Edit", actionTypes.edit, "edit_square", themeType.primary));
        this.actionsList.push(new actions("Delete", actionTypes.delete, "clear", themeType.error));

        this.loadIntegration();
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes['integration']) {
            // Load new data
            this.loadIntegration();
        }
    }

    loadIntegration() {
        // Cross reference our flowDefinitionSettings for the tenant flow with our tenant settings to scope the table settings.
        if (!this.integration) {
            return;
        }
        forkJoin([
            this.flowSettingsAPI.getTenantFlowDefinitionSettings(this.integration.flowCode, this.integration.version),
            this.tenantAPI.getTenantSetting()
        ])
        .subscribe({
            next: (obsResults) => {
                this.flowDefinitionSettings = obsResults[0].filter((item) => item.type === 'table');

                // Extract names from the filtered flowDefinitionSettings
                const flowNames = this.flowDefinitionSettings.map(item => item.settingName);
                this.tableSettings = obsResults[1].filter((item) => item.type === 'table');

                // Further filter tableSettings to include only those whose names are in flowDefinitionSettings
                this.tableSettings = this.tableSettings.filter((item) => flowNames.includes(item.settingName));
            },
            error: (result: any) => { console.log(result); },
            complete: () => {
                this.tableSettingsLoaded = true;
                this.isPageLoading = true;
                this.isNextPageLoading = false;
            }
        });
    }

    switchTableSettings = (event: any): void => {
        this.selectedTableSettings = event;
        this.tenantTableSettingItemsLoaded = true;
        this.paginationToken = "";

        if(this.selectedTableSettings?.settingName){
            this.flowSettingsAPI.getTableSettingFlowDefinitionSettings(this.selectedTableSettings?.settingName).subscribe({
                next: (data: flowDefinitionSetting[]) => {

                    this.dataColumns = [];
                    this.displayedColumns = [];
                    this.selectedTableSettingColumns = [];

                    data = data.sort((x, y) => (x.sortPriority - y.sortPriority));
                    data.forEach(setting => {
                        if (!setting.isReadOnly)
                        {
                            this.selectedTableSettingColumns = [...this.selectedTableSettingColumns, setting];
                            this.dataColumns = [...this.dataColumns, setting.settingName];
                            this.displayedColumns = [...this.displayedColumns, setting.displayName];
                        }
                    });

                    this.GetTenantTableSettingItemsAPI(this.selectedTableSettings?.settingName ?? "", this.integration?.flowCode ?? "", this.maxPageSize, this.paginationToken);
                }
            });
        }
        else{
            this.dataColumns = [];
            this.displayedColumns = [];
            this.selectedTableSettingColumns = [];
        }
    }

    deleteTableSettings = (event: any): void => {
        this.dialog.open(ConfirmationDialogComponent, {
            data: {
                title: "Delete Record",
                subtitle: "Are you sure you want to delete this record?",
                text: "This action cannot be undone",
                okText: "Delete",
                snackbarText: "Record has been successfully deleted",
                themeType: themeType.error,
                onConfirmationAction: async (): Promise<boolean> => {
                    if (event?.SK && this.selectedTableSettings?.settingName) {
                        var result = await this.DeleteTenantTableSettingItemsAPI(this.selectedTableSettings.settingName, event.SK);
                        return result;
                    }
                    return false;
                }
            }
        });

    }

    editTableSettings = (event: any): void => {
        this.dialog.open(TableSettingsRecordComponent, {
            data: {
                columns: this.selectedTableSettingColumns,
                data: event,
                actionType: actionTypes.edit,
                title: "Edit Record",
                successMessage: "Record has been successfully updated",
                errorMessage: "Record failed to update",
                onSaveAction: async (updatedRow:any): Promise<boolean> => {
                    var result = await this.UpsertTenantTableSettingItemsAPI(this.selectedTableSettings?.settingName ?? "", this.integration?.flowCode ?? "", updatedRow);
                    return result ? true : false;
                }
            }
        });
    }

    addRecord = (): void => {
        this.dialog.open(TableSettingsRecordComponent, {
            data: {
                columns: this.selectedTableSettingColumns,
                actionType: actionTypes.add,
                title: "Add Record",
                successMessage: "Record has been successfully added",
                errorMessage: "Record failed to be added",
                onSaveAction: async (addedRow:any): Promise<boolean> => {
                    var result = await this.UpsertTenantTableSettingItemsAPI(this.selectedTableSettings?.settingName ?? "", this.integration?.flowCode ?? "", addedRow);
                    return result ? true : false;
                }
            }
        });
    }

    onGetNextPage() {
        if(this.hasMoreResults && !this.isPageLoading && !this.isNextPageLoading) {
            this.GetTenantTableSettingItemsAPI(this.selectedTableSettings?.settingName ?? "", this.integration?.flowCode ?? "", this.maxPageSize, this.paginationToken);
        }
    }

    onScroll() {
        this.onGetNextPage();
    }

    bulkUpload = (): void => {
        const dialogRef = this.dialog.open(BulkUploadComponent, {
            width: this.bulkUploadDialogWidth,
            maxWidth: this.bulkUploadDialogWidth,
            data: {
                selectedTableSettings: this.selectedTableSettings
            }
        });

        //update the table with the newly uploaded data
        dialogRef.afterClosed().subscribe({
            next: (data: any) => {
                this.tenantTableSettingItemsLoaded = true;
                this.GetTenantTableSettingItemsAPI(this.selectedTableSettings?.settingName ?? "",  this.integration?.flowCode ?? "");
                this.cdr.detectChanges();
            }
        })
    }

    resetNotificationStatuses = () => {
        this.resetNotificationsService.resetNotifications(this.integration!.flowCode)
        .subscribe({
          complete: () => {
            this.resetNotificationsService.onSuccess();
           },
          error: (err) => {
            console.log(err);
            this.resetNotificationsService.onFailure();
          }
        });
    }

    GetTenantTableSettingItemsAPI(tableSettingCode: string, flowCode: string, pageSize?: number, paginationToken?: string) {
        this.tenantAPI.getTableSettingItems(tableSettingCode, flowCode, pageSize, paginationToken).subscribe({
            next: (data: any) => {
                this.paginationToken = data.paginationToken ?? undefined;
                this.isNextPageLoading = this.paginationToken ? true : false;
                this.hasMoreResults = data.data.length == this.maxPageSize && this.paginationToken !== undefined;
                this.tenantTableSettingItems = paginationToken ? [...this.tenantTableSettingItems, ...data.data] : data.data;
            },
            error: (result: any) => { console.log(result) },
            complete: () => {
                this.isPageLoading = false;
                this.tenantTableSettingItemsLoaded = false;
                this.isNextPageLoading = false;
                this.cdr.detectChanges();
            }
          });
    }

    async DeleteTenantTableSettingItemsAPI(code: string, base64encodedSK: string): Promise<boolean> {
        try {
            await firstValueFrom(this.tenantAPI.deleteTableSettingItems(code, base64encodedSK));
            this.tenantTableSettingItems = this.tenantTableSettingItems.filter(item => item.SK !== base64encodedSK);
            this.cdr.detectChanges();
            return true;
        }
        catch (error) {
            console.error("Error deleting table setting items:", error);
            return false;
        }
    }

    async UpsertTenantTableSettingItemsAPI(tableSettingCode: string, flowCode: string, formData: any, actionType?: actionTypes): Promise<boolean> {

        try {
            // Call the API to upsert table setting items
            var response = await firstValueFrom(this.tenantAPI.upsertTableSettingItems(tableSettingCode, flowCode, formData));

            // Extract SK and other properties from formData
            const { SK, sk, ...formDataWithoutSk } = formData;

            // Merge the new SK from the response with the rest of the form data
            const mergedDataWithNewSK = {
                SK: response['sk'],
                ...formDataWithoutSk
            };

            // Find the index of the row with the matching SK
            let index = this.tenantTableSettingItems.findIndex(item => item.SK === formData.SK);

            // If the row exists, replace it with the new data
            if (index !== -1) {
                this.tenantTableSettingItems.splice(index, 1, mergedDataWithNewSK);
            } else {// If the row does not exist, add the new row to the beginning of the array
                this.tenantTableSettingItems = [mergedDataWithNewSK, ...this.tenantTableSettingItems];
            }
            this.tenantTableSettingItems = [...this.tenantTableSettingItems]; //force table to detect splice changes
            this.cdr.detectChanges();
            return true;
        }
        catch(err){
            console.error("Upsert error:", err);
            return false;
        }
    }
}
