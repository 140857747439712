<div class="container-fluid p-0 subsection">
    <div class="navigation-header row">
        <div class="col-8">
            <h5 class="subsection-title">{{getDisplayName()}}</h5>
        </div>
    </div>
    <div class="row" class="subsection-content-container">
        <form [formGroup]="formGroup" (ngSubmit)="saveTenantSetting()">
            <div *ngIf="credentialType == TYPE_API_PAYCHEX_SECRET" class="col-6">
                <p class="subsection-subtitle">Configure your API credentials by completing the sections below.</p>
                <div class="form-group">
                    <mat-form-field appearance="outline" [floatLabel]="shouldFloat()">
                        <mat-label>URL</mat-label>
                        <input id="url" formControlName="uri" matInput
                               [placeholder]="isConfigured ? PLACEHOLDER_SECRET_TEXT: ''">
                    </mat-form-field>
                    <mat-form-field appearance="outline" [floatLabel]="shouldFloat()">
                        <mat-label>API Key</mat-label>
                        <input id="apiKey" formControlName="apiKey" matInput
                               [placeholder]="isConfigured ? PLACEHOLDER_SECRET_TEXT: ''">
                    </mat-form-field>
                    <mat-form-field appearance="outline" [floatLabel]="shouldFloat()">
                        <mat-label>User Name</mat-label>
                        <input id="userName" formControlName="userName" matInput
                               [placeholder]="isConfigured ? PLACEHOLDER_SECRET_TEXT: ''">
                    </mat-form-field>
                    <mat-form-field appearance="outline" [floatLabel]="shouldFloat()">
                        <mat-label>Password</mat-label>
                        <input id="password" formControlName="password" matInput
                               [placeholder]="isConfigured ? PLACEHOLDER_SECRET_TEXT: ''">
                    </mat-form-field>
                </div>
            </div>
            <div *ngIf="credentialType == TYPE_API_SECRET" class="col-6">
                <p class="subsection-subtitle">
                    Configure your third-party integration settings by completing the
                    sections below.
                </p>
                <div class="form-group">
                    <mat-form-field appearance="outline" [floatLabel]="shouldFloat()">
                        <mat-label>URL</mat-label>
                        <input id="url" formControlName="uri" matInput
                               [placeholder]="isConfigured ? PLACEHOLDER_SECRET_TEXT: ''">
                    </mat-form-field>
                    <mat-form-field appearance="outline" [floatLabel]="shouldFloat()">
                        <mat-label>API Key</mat-label>
                        <input id="apiKey" formControlName="apiKey" matInput
                               [placeholder]="isConfigured ? PLACEHOLDER_SECRET_TEXT: ''">
                    </mat-form-field>
                </div>
            </div>
            <div *ngIf="credentialType == TYPE_API_GENERIC_SECRET" class="col-6">
                <p class="subsection-subtitle">
                    Configure your third-party integration settings by completing the
                    sections below.
                </p>
                <div class="form-group">
                    <mat-form-field appearance="outline" [floatLabel]="shouldFloat()">
                        <mat-label>URL</mat-label>
                        <input id="Url" formControlName="uri" matInput
                                [placeholder]="isConfigured ? PLACEHOLDER_SECRET_TEXT: ''">
                    </mat-form-field>
                    <mat-form-field appearance="outline" [floatLabel]="shouldFloat()">
                        <mat-label>Client ID</mat-label>
                        <input id="clientId" formControlName="clientId" matInput
                                [placeholder]="isConfigured ? PLACEHOLDER_SECRET_TEXT: ''">
                    </mat-form-field>
                    <mat-form-field appearance="outline" [floatLabel]="shouldFloat()">
                        <mat-label>Client Secret</mat-label>
                        <input id="clientSecret" formControlName="clientSecret" matInput
                                [placeholder]="isConfigured ? PLACEHOLDER_SECRET_TEXT: ''">
                    </mat-form-field>
                </div>
            </div>
            <div *ngIf="credentialType == TYPE_API_ALTAMETRICS_SECRET" class="col-6">
                <p class="subsection-subtitle">
                    Configure your third-party integration settings by completing the
                    sections below.
                </p>
                <div class="form-group">
                    <mat-form-field appearance="outline" [floatLabel]="shouldFloat()">
                        <mat-label>URL</mat-label>
                        <input id="url" formControlName="uri" matInput
                               [placeholder]="isConfigured ? PLACEHOLDER_SECRET_TEXT: ''">
                    </mat-form-field>
                    <mat-form-field appearance="outline" [floatLabel]="shouldFloat()">
                        <mat-label>Partner Key</mat-label>
                        <input id="partnerKey" formControlName="partnerKey" matInput
                               [placeholder]="isConfigured ? PLACEHOLDER_SECRET_TEXT: ''">
                    </mat-form-field>
                </div>
            </div>
            <div *ngIf="credentialType == TYPE_API_BAMBOOHR_SECRET" class="col-6">
                <p class="subsection-subtitle">
                    Configure your third-party integration settings by completing the
                    sections below.
                </p>
                <div class="form-group">
                    <mat-form-field class=suggested-input appearance="outline" floatLabel="always">
                        <mat-label>BambooHR Subdomain</mat-label>
                        <input id="url" formControlName="uri" matInput
                               [placeholder]="isConfigured ? PLACEHOLDER_SECRET_TEXT: 'e.g., paychex'">
                        <div style="{padding-left:10px}" matPrefix>https://</div>
                        <div matSuffix>.bamboohr.com</div>
                    </mat-form-field>
                    <mat-form-field appearance="outline" floatLabel="always">
                        <mat-label>Signing Key</mat-label>
                        <input id="apiKey" formControlName="apiKey" matInput
                               [placeholder]="isConfigured ? PLACEHOLDER_SECRET_TEXT: 'e.g., abc123xyz'">
                    </mat-form-field>
                </div>
            </div>
            <div *ngIf="credentialType == TYPE_DATABASE_CONNECTION" class="col-6">
                <p class="subsection-subtitle">
                    Configure your database connection settings by completing the sections
                    below.
                </p>
                <div class="form-group">
                    <mat-form-field appearance="outline" [floatLabel]="shouldFloat()">
                        <mat-label>Database Host</mat-label>
                        <input id="url" formControlName="host" matInput
                               [placeholder]="isConfigured ? PLACEHOLDER_SECRET_TEXT: ''">
                    </mat-form-field>
                    <mat-form-field appearance="outline" [floatLabel]="shouldFloat()">
                        <mat-label>Database Port</mat-label>
                        <input id="port" formControlName="port" matInput
                               [placeholder]="isConfigured ? PLACEHOLDER_SECRET_TEXT: ''">
                    </mat-form-field>
                    <mat-form-field appearance="outline" [floatLabel]="shouldFloat()">
                        <mat-label>Database Name</mat-label>
                        <input id="databaseName" formControlName="databaseName" matInput
                               [placeholder]="isConfigured ? PLACEHOLDER_SECRET_TEXT: ''">
                    </mat-form-field>
                    <mat-form-field appearance="outline" [floatLabel]="shouldFloat()">
                        <mat-label>Database Engine</mat-label>
                        <input id="databaseEngine" formControlName="databaseEngine" matInput
                               [placeholder]="isConfigured ? PLACEHOLDER_SECRET_TEXT: ''">
                    </mat-form-field>
                    <mat-form-field appearance="outline" [floatLabel]="shouldFloat()">
                        <mat-label>User Name</mat-label>
                        <input id="userName" formControlName="userName" matInput
                               [placeholder]="isConfigured ? PLACEHOLDER_SECRET_TEXT: ''">
                    </mat-form-field>
                    <mat-form-field appearance="outline" [floatLabel]="shouldFloat()">
                        <mat-label>Password</mat-label>
                        <input id="password" formControlName="password" matInput
                               [placeholder]="isConfigured ? PLACEHOLDER_SECRET_TEXT: ''">
                    </mat-form-field>
                </div>
            </div>
            <div *ngIf="credentialType == TYPE_API_DEPUTY_SECRET" class="col-6">
                <p class="subsection-subtitle">
                    Configure access to your deputy environment by clicking the button below.
                    This will open a deputy website where you will be prompted to allow access.
                </p>
                <div class="form-group">
                    <mat-form-field appearance="outline" [floatLabel]="shouldFloat()">
                        <mat-label>Access Token</mat-label>
                        <input id="accessToken" formControlName="accessToken" matInput [disabled]="tokenRequired" [placeholder]="isConfigured ? PLACEHOLDER_SECRET_TEXT: ''">
                    </mat-form-field>
                </div>
            </div>
            <div *ngIf="credentialType == TYPE_API_SEVENSHIFTS_SECRET" class="col-6">
                <p class="subsection-subtitle">
                    Configure access to your 7shifts environment by clicking the button below.
                    This will open a 7shifts website where you will be prompted to allow access.
                </p>
                <div class="form-group">
                    <mat-form-field appearance="outline" [floatLabel]="shouldFloat()">
                        <mat-label>Company GUID</mat-label>
                        <input id="accessToken" formControlName="companyGUID" readonly matInput [placeholder]="isConfigured ? PLACEHOLDER_SECRET_TEXT: ''">
                    </mat-form-field>
                    <mat-form-field appearance="outline" [floatLabel]="shouldFloat()">
                        <mat-label>Company Id</mat-label>
                        <input id="companyId" formControlName="companyId" readonly matInput [placeholder]="isConfigured ? PLACEHOLDER_SECRET_TEXT: ''">
                    </mat-form-field>
                </div>
            </div>
            <div *ngIf="credentialType == TYPE_API_QUICKBOOKS_SECRET" class="col-6">
                <p class="subsection-subtitle">
                    Configure access to your QBT environment by clicking the button below.
                    This will open a QBT website where you will be prompted to allow access.
                </p>
                <div class="form-group">
                    <mat-form-field appearance="outline" [floatLabel]="shouldFloat()">
                        <mat-label>Access Token</mat-label>
                        <input id="accessToken" formControlName="accessToken" matInput [disabled]="tokenRequired" [placeholder]="isConfigured ? PLACEHOLDER_SECRET_TEXT: ''">
                    </mat-form-field>
                </div>
            </div>
            <div *ngIf="credentialType == TYPE_API_WHENIWORK_SECRET" class="col-6">
                <p class="subsection-subtitle">
                    Configure your third-party integration settings by completing the
                    sections below.
                </p>
                <div class="form-group">
                    <mat-form-field appearance="outline" [floatLabel]="shouldFloat()">
                        <mat-label>Email</mat-label>
                        <input id="email" name="email" formControlName="email" matInput
                               [placeholder]="isConfigured ? PLACEHOLDER_SECRET_TEXT: ''">
                    </mat-form-field>
                    <mat-form-field appearance="outline" [floatLabel]="shouldFloat()">
                        <mat-label>Password</mat-label>
                        <input id="password" name="password" formControlName="password" matInput
                               type="password" 
                               autocomplete="new-password"
                               [placeholder]="isConfigured ? PLACEHOLDER_SECRET_TEXT: ''">                               
                    </mat-form-field>
                </div>
            </div>            
            <div class="formGroup subsection-action-buttons flex-start">
                <a *ngIf="credentialType == TYPE_API_DEPUTY_SECRET && tokenRequired" id="allow-access-btn" mat-flat-button href="{{redirectUrl}}">
                    Grant Deputy Access
                </a>
                <a *ngIf="credentialType == TYPE_API_SEVENSHIFTS_SECRET" 
                    [disabled]="!tokenRequired" 
                    id="allow-access-btn" mat-flat-button href="{{redirectUrl}}">
                    Grant 7Shifts Access
                </a>
                <a *ngIf="credentialType == TYPE_API_QUICKBOOKS_SECRET" 
                    [disabled]="!tokenRequired" 
                    id="allow-access-btn" mat-flat-button href="{{redirectUrl}}">
                    Grant Quickbooks Access
                </a>
                <button href mat-flat-button color="primary" id="save-btn" type="submit"
                    *ngIf="tokenRequired == false"
                    [disabled]="this.saveButtonDisabled()">
                    Save
                </button>
                <mat-spinner class="mdc-card__actions-spinner" [diameter]="20" id="initials-spinner"
                *ngIf="this.isSaving"></mat-spinner>
            </div>
        </form>
    </div>
</div>
