<div class="container-fluid  navigation-bar" *ngIf="!loading">
    <div class="row-fluid navigation-title ">
       <h4>Settings</h4>
    </div>
    <div class="row-fluid navigation-header">
        <mat-tab-group mat-stretch-tabs="false" mat-align-tabs="start" [disableRipple]="true" animationDuration="300ms">
            <mat-tab label="Auth / Credentials" id="credentials-tab-header" class="integration-tab" > <app-credentials></app-credentials> </mat-tab>
        </mat-tab-group>
    </div>
</div>
<div *ngIf="loading" class="container-fluid ">
    <div class="row">
        <mat-spinner class="loading-spinner" *ngIf="loading" [diameter]="60"></mat-spinner>
    </div>
</div>