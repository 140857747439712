import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NavigationEnd, Router, RouterOutlet } from '@angular/router';
import { AmplifyAuthenticatorModule } from '@aws-amplify/ui-angular';
import { HeaderComponent } from './Header/header.component';
import { CognitoService } from './Services/cognito.service';
import { MatSidenavModule } from '@angular/material/sidenav';
import { NavBarIntegrationComponent } from './Navigation/Nav-bar-integration/nav-bar-integration.component';
import { NavBarOfferingComponent } from './Navigation/Nav-bar-offering/nav-bar-offering.component';
import { AuthenticatorService } from '@aws-amplify/ui-angular';
import { LoginComponent } from './Pages/login/login.component';
import { SSOLoginComponent } from './Pages/login-sso/loginSSO.component';
import { TenantInfoComponent } from './Header/tenant-info/tenant-info.component';
import { FooterComponent } from './footer/footer.component';
import { CallbackComponent } from './Pages/callback/callback.component';
import { environment } from '../environments/environment';
import { filter } from 'rxjs/operators';
import { NavigationBarType } from './Models/Configuration/navigation-bar-type.model';

@Component({
    selector: 'app-root',
    standalone: true,
    imports: [
        FooterComponent,
        HeaderComponent,
        CommonModule,
        RouterOutlet,
        AmplifyAuthenticatorModule,
        MatSidenavModule,
        NavBarIntegrationComponent,
        NavBarOfferingComponent,
        AmplifyAuthenticatorModule,
        environment.LoginFeatureSwitch.SSOLogin ? SSOLoginComponent: LoginComponent,
        CallbackComponent,
        TenantInfoComponent],
    templateUrl: './app.component.html',
    styleUrl: './app.component.scss',
})
export class AppComponent implements OnInit {
    title = 'MindexIntegrationsClient';
    isCallbackRoute: boolean = false;
    NavigationBarType = NavigationBarType;
    navigationBarType: NavigationBarType = environment.NavigationSwitch.NavigationBarType;

    constructor(public authenticator: AuthenticatorService, private router: Router) {
        const bodyElement = document.body;
        bodyElement.classList.add('payx-setting');
    }

    ngOnInit() {
        this.router.events.pipe(
            filter((event): event is NavigationEnd => event instanceof NavigationEnd)
        ).subscribe((event: NavigationEnd) => {
            this.isCallbackRoute = event.url.includes('/loginsso/callback');
        });
    }
}
