import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatToolbarModule } from '@angular/material/toolbar';
import { environment } from '../../environments/environment';
import { AuthenticatorService } from '@aws-amplify/ui-angular';

@Component({
    selector: 'app-footer',
    standalone: true,
    imports: [CommonModule, MatToolbarModule],
    templateUrl: './footer.component.html',
    styleUrl: './footer.component.scss'
})
export class FooterComponent {

    helpSupportUrl:string =  environment.GetSupportUrl;
    constructor(
        public authenticator: AuthenticatorService
    ) { }

    getIsAuthenticated(): boolean {
        return this.authenticator.route === "authenticated";
    }
}
