import { CanActivateFn, Router, RouterStateSnapshot } from '@angular/router';
import { CognitoService } from '../Services/cognito.service';
import { inject } from '@angular/core';
// route logged out users to login page
export const mainAuthGuard: CanActivateFn = () => {
    const cognitoService: CognitoService = inject(CognitoService);
    const router: Router = inject(Router);

    return new Promise((resolve, reject) => {
        cognitoService
            .currentAuthenticatedUser()
            .then(() => {
                resolve(true);
            })
            .catch((e) => {
                cognitoService.wasUserSignedIn().then((userWasSignedIn) => {
                    if (userWasSignedIn) {
                        cognitoService.handleSignOut();
                        if (router.routerState.snapshot.url) {
                            router.navigate(['login'], { queryParams: { returnUrl: router.routerState.snapshot.url, expnotify: "1" } });
                        } else {
                            router.navigate(['login'], { queryParams: { expnotify: "1" } });
                        }
                    }
                })
                .catch((e) => {
                    reject(e);
                });

                cognitoService.handleSignOut();
                router.navigate(['login']);
                reject(e);
            });
    });
};
// route logged in users to dashboard
export const loginAuthGuard: CanActivateFn = () => {
    const cognitoService: CognitoService = inject(CognitoService);
    const router: Router = inject(Router);

    return new Promise((resolve, reject) => {
        cognitoService
            .currentAuthenticatedUser()
            .then(() => {
                reject(false);
                router.navigate(['/dashboard']);
            })
            .catch((e) => {
                resolve(true);
            });
    });
};
