<div>
    <mat-card *ngIf="isSelfService() && flowSettingsLoaded && !allSettingsInSync" class="info-card configuration-out-of-sync">
        <div class="info-content">
            <mat-icon class="info-icon" color="primary">info</mat-icon>
            <p class="info-text">
                One or more configuration mappings have become out of sync.  Save to correct the issue.
            </p>
        </div>        
    </mat-card>

    <mat-card class="section-container-card" *ngIf="isSelfService() && flowSettingsLoaded && tenantFlowSettingsLoaded;">
        <mat-card-header class="section-container-header">
            <mat-card-title class="section-container-title">Configurations</mat-card-title>
        </mat-card-header>
        <mat-card-content>
            <form [formGroup]="tenantFlowSettingsOptionsForm" (ngSubmit)="saveConfig()">
                <mat-card-content class="standard-gap-vertical">
                    <div class="gridContent">
                        <mat-spinner *ngIf="integration !== null && hideForm()" [diameter]="20"></mat-spinner>
                        <div [hidden]="hideForm()" class="margin-bottom-10">
                            <div *ngFor="let display of displayedFlowSettings">
                                <div
                                    *ngIf="!display.setting.isReadOnly && tenantFlowSettingsOptionsForm.get(display.setting.stepName + '-' + display.setting.settingName)">
                                    <div *ngIf="display.setting.type === 'string' || display.setting.type === 'number'">
                                        <mat-form-field>
                                            <mat-label>{{display.setting.displayName || display.setting.settingName}}</mat-label>
                                            <input [formControlName]="display.setting.stepName + '-' + display.setting.settingName"
                                                [value]="display.setting.defaultValue" matInput [required]="display.setting.required"
                                                [type]="display.setting.type === 'number' ? 'number':'text'"
                                                [disabled]="this.submittingForm">
                                        </mat-form-field>
                                    </div>
                                    <div *ngIf="display.setting.type === 'bool'">
                                        <div class="form-group">
                                            <mat-checkbox matInput labelPosition="before" class="enable-checkbox"
                                                [formControlName]="display.setting.stepName + '-' + display.setting.settingName"
                                                [disabled]="this.submittingForm"
                                                [checked]="display.setting.defaultValue">{{display.setting.displayName ||
                                                display.setting.settingName}}</mat-checkbox>
                                        </div>
                                    </div>
                                    <div *ngIf="display.setting.type === 'dropdown'">
                                        <mat-form-field class="select-box-small" style="margin-left: 5px; margin-bottom: 0;">
                                            <mat-label>{{display.setting.displayName || display.setting.settingName}}</mat-label>
                                            <mat-select
                                                [formControlName]="display.setting.stepName + '-' + display.setting.settingName"
                                                [disabled]="this.submittingForm"
                                            >
                                                <mat-option *ngFor="let allowedValue of display.setting.allowedValues"
                                                    [value]="allowedValue.value"
                                                >
                                                    {{allowedValue.displayName}}
                                                </mat-option>                                            
                                            </mat-select>
                                        </mat-form-field>                                        
                                    </div>                                    
                                    <div *ngIf="display.setting.type === 'filter-string'">
                                        <div class="flex-start" style="margin-bottom: 0;">
                                            <mat-form-field class="col-6 text-area-medium" style="margin-bottom: 0;">
                                                <mat-label>
                                                    {{display.setting.displayName || display.setting.settingName}}
                                                    <span *ngIf="isRequired(display.setting)" class="required-asterisk">*</span>
                                                </mat-label>
                                                <mat-chip-grid #chipGrid
                                                    [formControlName]="display.setting.stepName + '-' + display.setting.settingName"
                                                    class="chip-grid-inline">
                                                    <mat-chip-row *ngFor="let keyword of getFilters(display.setting.stepName + '-' + display.setting.settingName); trackBy: trackByKeyword"
                                                        (removed)="removeFilter(keyword, display.setting.stepName + '-' + display.setting.settingName)"
                                                        color="primary">
                                                        <button matChipRemove>
                                                            <mat-icon>close</mat-icon>
                                                        </button>
                                                        {{keyword}}
                                                    </mat-chip-row>
                                                </mat-chip-grid>
                                                <input [matChipInputFor]="chipGrid"
                                                    (matChipInputTokenEnd)="addFilter($event, display.setting.stepName + '-' + display.setting.settingName)" 
                                                    (input)="onFilterTextBoxInputChange($event, display.setting.stepName + '-' + display.setting.settingName)"
                                                    [formControlName]="display.setting.stepName + '-' + display.setting.settingName + '-input'"
                                                    [disabled]="isFilterTextBoxDisabled(display.setting) || this.submittingForm"/>
                                            </mat-form-field>
                                            <mat-form-field class="col-6 select-box-small" style="margin-left: 5px; margin-bottom: 0;">
                                                <mat-label>Filter Options</mat-label>
                                                <mat-select
                                                    [formControlName]="display.setting.stepName + '-' + display.setting.settingName + '-toggle'"
                                                    [disabled]="this.submittingForm"
                                                    (selectionChange)="toggleChange($event, display.setting.stepName + '-' + display.setting.settingName)"
                                                    placeholder="Select One">
                                                    <mat-option value="">Select One</mat-option>
                                                    <mat-option value="include">Include Listed Values</mat-option>
                                                    <mat-option value="exclude">Exclude Listed Values</mat-option>
                                                </mat-select>
                                            </mat-form-field>
                                        </div>
                                    </div>
                                    <div *ngIf="display.setting.type === 'filter-string-with-null'">
                                        <div class="flex-start" style="margin-bottom: 0;">
                                            <mat-form-field class="col-6 text-area-medium" style="margin-bottom: 0;">
                                                <mat-label>
                                                    {{display.setting.displayName || display.setting.settingName}}
                                                    <span *ngIf="isRequired(display.setting)" class="required-asterisk">*</span>
                                                </mat-label>
                                                <mat-chip-grid #chipGrid
                                                    [formControlName]="display.setting.stepName + '-' + display.setting.settingName"
                                                    class="chip-grid-inline">
                                                    <mat-chip-row *ngFor="let keyword of getFilters(display.setting.stepName + '-' + display.setting.settingName); trackBy: trackByKeyword"
                                                        (removed)="removeFilter(keyword, display.setting.stepName + '-' + display.setting.settingName)"
                                                        color="primary">
                                                        <button matChipRemove>
                                                            <mat-icon>close</mat-icon>
                                                        </button>
                                                        {{keyword}}
                                                    </mat-chip-row>
                                                </mat-chip-grid>
                                                <input [matChipInputFor]="chipGrid"
                                                    (matChipInputTokenEnd)="addFilter($event, display.setting.stepName + '-' + display.setting.settingName)"
                                                    (input)="onFilterTextBoxInputChange($event, display.setting.stepName + '-' + display.setting.settingName)"
                                                    [formControlName]="display.setting.stepName + '-' + display.setting.settingName + '-input'"
                                                    [disabled]="isFilterTextBoxDisabled(display.setting) || this.submittingForm"/>
                                            </mat-form-field>
                                            <mat-form-field class="col-6 select-box-small" style="margin-left: 5px; margin-bottom: 0;">
                                                <mat-label>Filter Options</mat-label>
                                                <mat-select
                                                    [formControlName]="display.setting.stepName + '-' + display.setting.settingName + '-toggle'"
                                                    [disabled]="this.submittingForm"
                                                    (selectionChange)="toggleChange($event, display.setting.stepName + '-' + display.setting.settingName)"
                                                    placeholder="Select One">
                                                    <mat-option value="">Select One</mat-option>
                                                    <mat-option value="include">Include Listed Values</mat-option>
                                                    <mat-option value="include-with-null">Include Listed Values and Blanks</mat-option>
                                                    <mat-option value="exclude-with-null">Exclude Listed Values and Blanks</mat-option>
                                                    <mat-option value="exclude">Exclude Listed Values</mat-option>
                                                </mat-select>
                                            </mat-form-field>
                                        </div>
                                    </div>
                                    
                                    <div *ngIf="display.setting.type === 'filter-date'">
                                        <mat-form-field class="col-6 text-area-medium">
                                            <mat-label>{{display.setting.displayName || display.setting.settingName}}</mat-label>
                                            <mat-date-range-input [rangePicker]="picker" (input)="onDatepickerInputChange($event, display.setting)" [disabled]="isFilterTextBoxDisabled(display.setting) || this.submittingForm">
                                                <input matStartDate placeholder="Start date" [formControlName]="display.setting.stepName + '-' + display.setting.settingName+ '-start'" ng-model-options="{timezone: utc}">
                                                <input matEndDate placeholder="End date" [formControlName]="display.setting.stepName + '-' + display.setting.settingName+ '-end'" ng-model-options="{timezone: utc}">
                                            </mat-date-range-input>
                                            <mat-datepicker-toggle matIconSuffix [for]="picker" [disabled]="isFilterTextBoxDisabled(display.setting) || this.submittingForm"></mat-datepicker-toggle>
                                            <mat-date-range-picker #picker [disabled]="isFilterTextBoxDisabled(display.setting) || this.submittingForm"></mat-date-range-picker>
                                        </mat-form-field>
                                        <mat-form-field class="col-6 select-box-small" style="margin-left: 5px; margin-bottom: 0;">
                                            <mat-label>Filter Options</mat-label>
                                            <mat-select
                                                [formControlName]="display.setting.stepName + '-' + display.setting.settingName + '-toggle'"
                                                [disabled]="this.submittingForm"
                                                (selectionChange)="toggleChange($event, display.setting.stepName + '-' + display.setting.settingName)"
                                                placeholder="Select One">
                                                <mat-option value="">Select One</mat-option>
                                                <mat-option value="include">Include</mat-option>
                                                <mat-option value="exclude">Exclude</mat-option>
                                            </mat-select>
                                        </mat-form-field>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </mat-card-content>
                <mat-card-actions class="standard-gap-vertical">
                    <div class="flex-start">
                        <button mat-flat-button color="primary" id="save-btn" type="submit"
                            [disabled]="cannotSubmit()">
                            Save
                        </button>
                        <mat-spinner class="mdc-card__actions-spinner" [diameter]="20" id="save-spinner" *ngIf="this.submittingForm"></mat-spinner>
                    </div>
                </mat-card-actions>
            </form>
        </mat-card-content>
    </mat-card>
    <mat-card class="info-card configuration-self-service-disabled" *ngIf="!isSelfService() && flowSettingsLoaded && tenantFlowSettingsLoaded">
        <div class="info-content">
            <mat-icon class="info-icon" color="primary">info</mat-icon>
            <p class="info-text">
                For access to this integration or if you have any questions or comments, contact Paychex at
                <a class="link mailto" href="{{helpSupportUrl}}" class="link mailto">{{helpSupportUrl}}</a>.
            </p>
        </div>
    </mat-card>
    <mat-spinner class="loading-spinner" *ngIf="!jobType || !flowSettingsLoaded || !tenantFlowSettingsLoaded" [diameter]="60"></mat-spinner>
</div>