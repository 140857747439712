import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';

import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatIconModule } from '@angular/material/icon';
import { MatCardModule } from '@angular/material/card';
import { TenantAPIService } from '../../Services/api.tenantApi.service';
import { FormsModule } from '@angular/forms';
import { exportResult } from '../../Pages/jobs/models/job.model';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatPaginator, MatPaginatorModule, PageEvent } from '@angular/material/paginator';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';

@Component({
  selector: 'app-exportable-files',
  standalone: true,
  imports: [CommonModule, MatProgressSpinnerModule, MatIconModule, FormsModule, MatCardModule, MatTooltipModule, MatTableModule, MatPaginatorModule],
  templateUrl: './exportable-files.component.html',
  styleUrl: './exportable-files.component.scss'
})
export class ExportableFilesComponent implements OnInit {

  @Input("jobId") jobId: string = "";
  exportFiles: exportResult[] = [];
  dataColumns: string[] = ['Type', 'File Name', 'Actions'];
  dataSource = new MatTableDataSource<exportResult>(this.exportFiles);
  @ViewChild(MatPaginator) paginator!: MatPaginator;

  fileListLoaded: boolean = false;
  pageIndex: number = 0;
  pageSize: number = 5;

  constructor(private jobsAPI: TenantAPIService) {
  }

  ngOnInit() {
    this.getExportableFiles(this.jobId);
  }

  getExportableFiles = (id: string) => {
    this.fileListLoaded = false;
    this.jobsAPI.getExportResultsByJobId(id).subscribe({
      next: (data: exportResult[]) => {
        this.dataSource.paginator = this.paginator;
        this.dataSource.data = data;
      },
      error: (result: any) => { console.log(result) },
      complete: () => {
        this.fileListLoaded = true;
      }
    });
  }

  pageChangeEvent(event: PageEvent) {
    this.pageIndex = event.pageIndex;
    this.pageSize = event.pageSize;
  }

  downloadFile = (item: exportResult): void => {
    item.downloading = true;
    this.jobsAPI.InitiateDownload(this.jobId, item.transferType, item.keyName).subscribe({
      next: (uri: any) => {
        window.open(uri, '_blank');
      },
      error: (result: any) => { console.log(result) },
      complete: () => item.downloading = false
    });
  }
}
