  // For situations when we want to pass null instead of "".
  // For instance, optional nullable query params (no sense in sending "" in the request)
  // Handles the case where forms that are typed in then deleted become "" instead of null/undefined.
  export function GetStringOrNull(string: string | null | undefined): string | null {
    if(string === null || string === undefined || string === ""){
      return null;
    }
    return string;
  }

  export function formatDateToMMDDYYYY(date: Date): string {
    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const year = date.getFullYear();
    return `${month}-${day}-${year}`
  }

  export function isDateTimeType(typeValue: string) : boolean {
    return typeValue === 'date' || typeValue === 'dateTime' || typeValue === 'datetime';
  }