import { jobOptions } from "./jobOptions.model";

export class job {    
    constructor(public jobType: string,public jobSubType: string, public jobId?: string,  public jobName?: string, public jobDescription?: string, public jobStatus?:string, public createdDate?:string, public createdByUserName?:string ) {   
    }
}

export interface startJobRequest {
    name?: string  | null;
    jobCode: string;
    description?: string | null;
    options?: jobOptions;
}

export enum JobStatus {
    New = 'New',
    Active = 'Active',
    Complete = 'Complete',
    Error = 'Error'
}

export class exportResult {
    public downloading: boolean = false;
    constructor(public keyName: string, public transferType: string) {
    }
}

export interface retryJobRequest {
    jobId: string;
}
export interface retryJobResponse {
    jobId: string;
}