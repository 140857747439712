import { NavigationBarType } from "../app/Models/Configuration/navigation-bar-type.model";

export const environment = {
    Auth: {
        Cognito: {
            userPoolId: 'us-east-1_Whs5Emysx',
            userPoolClientId: '7cfo5ke08aafhg5488lhbvmilm',
            identityProviderName:["dev-shared-payxf-oidc"],
            signUpVerificationMethod: 'link', // 'code' | 'link'
            loginWith: {
                // OPTIONAL - Hosted UI configuration
                oauth: {
                    domain: 'https://auth.dev-shared.mindex-integrations.com',
                    scopes: [
                        'phone',
                        'email',
                        'profile',
                        'openid',
                        'aws.cognito.signin.user.admin'
                    ],
                    redirectSignIn: ['http://localhost:4000/'],
                    redirectSignOut: ['http://localhost:4000/'],
                    responseType: 'code'
                }
            }
        },
    },
    LoginFeatureSwitch: {
        SSOLogin: false
    },
    EmailNotificationSwitch: {
        DisplaySubjectAndMessage: true
    },
    NavigationSwitch : {
        NavigationBarType: NavigationBarType.Integration
    },
    BaseAPIUrl: "https://api.dev-shared.mindex-integrations.com/api/",
    BaseUrl: "https://dev-shared.mindex-integrations.com/",
    DeputyClientId: "abd60d6fec7ab6edea474bad30dead8c48ecd712",
    QuickbooksClientId: "d7f49ef11d53e715db96d4a21ab95ff7",
    SevenShiftsClientId: "c3c7556ff51ae422d78ee0c4e4d3e59b5b7f5615",
    MFAHelpDisplayUrl: "integrationdeployment@paychex.com",
    BullhornClientId: "9a266e5d-eaf6-4943-9642-1c1293d3ca04",
    BullhornLoginInfo: "https://api.dev-shared.mindex-integrations.com/api/tenant/settings/bullhorn-secrets",
    PrivacyPolicyDocumentURL: "https://mip-dev-shared-legal-documents-bucket.s3.amazonaws.com/24.08.08+Mindex+MIP+Privacy+Policy.pdf",
    TermsAndConditionsDocumentURL: "https://mip-dev-shared-legal-documents-bucket.s3.amazonaws.com/24.08.08+Mindex+MIP+Terms+and+Conditions.pdf",
    GetSupportUrl: "test@mail.com",
    NoTenantText: "Looks like you have a Paychex Flex account, but no integration set up for your account.",
    NoTenantContact: "Please contact Paychex Integration Support at"
};
