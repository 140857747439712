import { Injectable } from "@angular/core";
import { MipHttpService } from "./api.mipHttpService";
import { CognitoService } from "./cognito.service";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import {TenantOfferingFlowLink} from "../Models/Class-Interfaces/Offerings/TenantOfferingFlowLink.model";
import {TenantOfferingFlowLinkGetRequest} from "../Models/Class-Interfaces/Offerings/TenantOfferingFlowLinkGetRequest.model";
import {TenantOfferingFlowLinkListRequest} from "../Models/Class-Interfaces/Offerings/TenantOfferingFlowLinkListRequest.model";
import {TenantOfferingLink} from "../Models/Class-Interfaces/Offerings/TenantOfferingLink.model";
import {TenantOfferingLinkGetRequest} from "../Models/Class-Interfaces/Offerings/TenantOfferingLinkGetRequest.model";
import {TenantOfferingLinkListRequest} from "../Models/Class-Interfaces/Offerings/TenantOfferingLinkListRequest.model";
import { TenantOfferingFlowLinkUnassociatedRequest } from "../Models/Class-Interfaces/Offerings/TenantOfferingFlowLinkUnassociatedRequest.model";

@Injectable({
    providedIn: 'root',
})
export class OfferingApiService extends MipHttpService {
    constructor(cognitoService: CognitoService, httpClient: HttpClient) {
        super(cognitoService, httpClient);
    }

    public listOfferingFlowLink(request: TenantOfferingFlowLinkListRequest) : Observable<TenantOfferingFlowLink[]> {
        const path = `offering/${request.OfferingCode}/flow`;
        return this.get<TenantOfferingFlowLink[]>(path);
    }

    public getTenantOfferingFlowLink(request: TenantOfferingFlowLinkGetRequest) : Observable<TenantOfferingFlowLink> {
        const path = `tenant/offering/${request.OfferingCode}/flow/${request.FlowType}/${request.FlowType}`;
        return this.get<TenantOfferingFlowLink>(path);
    }

    public listTenantOfferingFlowLink(request: TenantOfferingFlowLinkListRequest) : Observable<TenantOfferingFlowLink[]> {
        const path = `tenant/offering/${request.OfferingCode}/flow`;
        return this.get<TenantOfferingFlowLink[]>(path);
    }

    public getTenantOfferingLink(request: TenantOfferingLinkGetRequest) : Observable<TenantOfferingLink> {
        const path = `tenant/offering/${request.offeringCode}`;
        return this.get<TenantOfferingLink>(path);
    }

    public listTenantOfferingLink(request: TenantOfferingLinkListRequest) : Observable<TenantOfferingLink[]> {
        const path = `tenant/offering/`;
        return this.get<TenantOfferingLink[]>(path);
    }

    public getUnassociatedTenantOfferingFlowLink(request: TenantOfferingFlowLinkUnassociatedRequest) : Observable<TenantOfferingFlowLink[]> {
        const path = `tenant/offering/unassociated/flow`;
        return this.get<TenantOfferingFlowLink[]>(path);
    }
}