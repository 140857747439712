import { Injectable } from '@angular/core';
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';
import { BehaviorSubject, of } from 'rxjs';
import { startWith, switchMap, distinctUntilChanged } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class RouteContextService {
  // Holds the merged parameters from the entire route tree.
  private _snapshot: { [key: string]: any } = {};

  // Observable mimicking ActivatedRoute.params.
  private _params = new BehaviorSubject<{ [key: string]: any }>(this._snapshot);
  public params$ = this._params.asObservable();

  // Synchronous access to the current merged parameters.
  public get snapshot(): { [key: string]: any } {
    return this._snapshot;
  }

  constructor(private router: Router, private activatedRoute: ActivatedRoute) {
    this.router.events.pipe(
      // Start with an initial emission to capture the current state.
      startWith(null),
      // Each time a router event fires, use switchMap to recalc the parameters.
      switchMap(() => {
        // Calculate the merged parameters by traversing from the root.
        const merged = this.collectRouteParams(this.activatedRoute.root);
        return of(merged);
      }),
      // Only emit if the merged map has actually changed.
      distinctUntilChanged((prev, curr) => this.areParamsEqual(prev, curr))
    ).subscribe(merged => {
      this._snapshot = merged;
      this._params.next(merged);
    });
  }

  /**
   * Recursively traverse the route tree starting at `route` and merge
   * its snapshot.params and snapshot.queryParams into one map.
   */
  private collectRouteParams(route: ActivatedRoute): { [key: string]: any } {
    // Start with the current route's params and queryParams.
    let params: { [key: string]: any } = { 
      ...route.snapshot.params, 
      ...route.snapshot.queryParams 
    };

    // Recursively merge in parameters from all children.
    route.children.forEach(child => {
      params = { ...params, ...this.collectRouteParams(child) };
    });

    return params;
  }

  /**
   * Shallow equality check between two parameter objects.
   */
  private areParamsEqual(
    oldObj: { [key: string]: any },
    newObj: { [key: string]: any }
  ): boolean {
    const oldKeys = Object.keys(oldObj);
    const newKeys = Object.keys(newObj);
    if (oldKeys.length !== newKeys.length) return false;
    for (const key of oldKeys) {
      if (oldObj[key] !== newObj[key]) return false;
    }
    return true;
  }

  /**
   * Manually update a parameter without affecting the URL.
   */
  public setParam(key: string, value: any): void {
    const newSnapshot = { ...this._snapshot, [key]: value };
    if (!this.areParamsEqual(this._snapshot, newSnapshot)) {
      this._snapshot = newSnapshot;
      this._params.next(newSnapshot);
    }
  }
}
