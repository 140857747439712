import { Component, ElementRef, EventEmitter, Inject, Input, OnInit, Output, ViewChild } from '@angular/core';
import { AsyncPipe, CommonModule } from '@angular/common';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import {MatInputModule} from '@angular/material/input';
import { MatProgressSpinner, MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { searchableDropdown } from '../models/searchableDropdown.model';
import { map, Observable, startWith } from 'rxjs';
import { FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIcon } from '@angular/material/icon';

@Component({
  selector: 'app-searchable-dropdown',
    standalone: true,
    imports: [
      FormsModule,
      MatFormFieldModule,
      MatInputModule,
      MatAutocompleteModule,
      CommonModule,
      ReactiveFormsModule,
      MatIcon
    ],
  templateUrl: './searchable-dropdown.component.html',
  styleUrl: './searchable-dropdown.component.scss'
})
export class SearchableDropdownComponent implements OnInit {
  @ViewChild('searchInput') searchInput: ElementRef<HTMLInputElement>;
   @Input() searchOptions: searchableDropdown[] = [];
   @Input() defaultOption: searchableDropdown;
   @Input() setAsRequired: boolean = false;
   @Output() selectedOption: EventEmitter<searchableDropdown> = new EventEmitter();

   searchableDropdownControl = new FormControl();
   filteredOptions: searchableDropdown[];
   currentValue: searchableDropdown;

   ngOnInit() {
    if (this.defaultOption)
    {
      this.searchableDropdownControl.setValue(this.defaultOption);
      this.currentValue = this.defaultOption;
    }

    this.filteredOptions = this.searchOptions.slice();

    this.searchableDropdownControl.valueChanges.subscribe((selectedValue) => {
      if (selectedValue !== null) { 
        this.filteredOptions = this.filter(selectedValue.name.toLowerCase());
      } else {
        this.filteredOptions = this.searchOptions;
      }
    });
   }

  filter(value?: string) {
    const filterValue = value ? value : this.searchInput.nativeElement.value.toLowerCase();
    let newOptions = this.searchOptions.filter(option => option.name.toLowerCase().includes(filterValue));
    if(newOptions.length > 0)
    {
      this.filteredOptions = newOptions;
    }
    else {
      this.filteredOptions = this.searchOptions.slice();
    }
    return this.filteredOptions;
  }

  displayFn(selectedOption: searchableDropdown){
    return selectedOption ? selectedOption.name : '';
   }

   selectionChangeFunction(selectedRow: any) {
    this.currentValue = selectedRow.option.value;
    this.selectedOption.emit(selectedRow.option.value);
   }
}

export function convertToSearchableDropdown(convertData: any, nameField: string, valueField: string): searchableDropdown[] {
  let returnData: searchableDropdown[] = [];
  if (convertData.length)
  {
    convertData.forEach((rowData: any) => {
      if(rowData[nameField] != undefined && rowData[valueField] != undefined)
      {
        let dropdownOption = new searchableDropdown(rowData[nameField], rowData[valueField])
        returnData.push(dropdownOption);
      }
    });
  }
  else {
    if(convertData[nameField] != undefined && convertData[valueField] != undefined)
      {
        let dropdownOption = new searchableDropdown(convertData[nameField], convertData[valueField])
        returnData.push(dropdownOption);
      }
  }

  return returnData;
}



