<div class="container-fluid p-0 table-settings-container">
    <mat-card class="section-container-card">
        <mat-card-header class="section-container-header">
            <mat-card-title class="section-container-title">Table Settings</mat-card-title>
        </mat-card-header>
        <mat-card-content *ngIf="!tableSettingsLoaded">
            <mat-spinner [diameter]="20"></mat-spinner>
        </mat-card-content>
        <mat-card-content *ngIf="tableSettingsLoaded" class="standard-gap-vertical">
            <div class="label-and-dropdown-button-container">
                <label class="view-table-label">
                    View Table Type <span class="field-required">*</span>
                </label>
                <mat-form-field class="type-select-field" *ngIf="tableSettingsLoaded">
                    <mat-select placeholder="Select One" [(value)]="selectedTableSettings" id="tableSettings-select" required (selectionChange)="switchTableSettings($event.value)">
                        <mat-option>--</mat-option>
                        @for (type of tableSettings; track tableSettings)
                        {
                            <mat-option [value]="type">{{type.displayName}}</mat-option>
                        }
                    </mat-select>
                </mat-form-field>
            </div>

            <div class="no-table-type-or-file-selected-container" *ngIf="!selectedTableSettings">
                <div class="icon-wrapper">
                    <img src="assets/images/BLANK-SelectFile.svg" alt="Select File Icon"/>
                </div>
                <p class="title">No Table Type Selected</p>
                <p class="details">
                  Make a selection from the <strong>View Table Type</strong>
                  dropdown to view data associated with it and other actions.
                </p>
            </div>

            <div class="no-table-type-or-file-selected-container" *ngIf="(!tenantTableSettingItems || tenantTableSettingItems.length === 0)  && selectedTableSettings">
                <div class="icon-wrapper">
                    <img src="assets/images/BLANK-Upload.svg" alt="Upload File Icon"/>
                </div>
                <p class="title">No File Selected</p>
                <p class="details">
                  Click <strong>Add</strong> or <strong>Bulk Upload</strong> 
                  to view and manage records related to your table settings.
                </p>
            </div>

            <div class="button-row-container" *ngIf="selectedTableSettings" [ngClass]="{'center-align': (!tenantTableSettingItems || tenantTableSettingItems.length === 0)}">
                <button class="plus-icon-button add-button" mat-flat-button (click)="addRecord()">
                    <span class="button-text">Add</span>
                </button>
                <button mat-flat-button (click)="bulkUpload()">
                    <span class="button-text">Bulk Upload</span>
                </button>
            </div>
            <div *ngIf="selectedTableSettings">
                <app-table id="tablesettings-table" [tableData]="tenantTableSettingItems"
                    [displayedColumns]="displayedColumns" [dataColumns]="dataColumns" [(isPageLoading)]="tenantTableSettingItemsLoaded" [isNextPageLoading]="isNextPageLoading"
                    [displayActions]="true" [actionsList]="actionsList" [onScrollEvent]="onScroll.bind(this)" [infiniteScrollDistance]="0"
                    [infiniteScrollThrottle]="50" [disableSort]="true" (deleteIconClicked)="deleteTableSettings($event)" (editIconClicked)="editTableSettings($event)">
                </app-table>
            </div>
        </mat-card-content>
    </mat-card>
</div>