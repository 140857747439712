<div>
    <mat-card *ngIf="!loading" class="section-container-card">
        <mat-card-header class="section-container-header">
            <mat-card-title class="section-container-title">Audit</mat-card-title>
        </mat-card-header>
        <form [formGroup]="formGroup" (ngSubmit)="onSubmit(formDirective)" #formDirective="ngForm">
            <mat-card-content>
                <div class="tab-content-header">
                    <div>
                        Specify the Audit Type and Match Type values to run an audit check for record alignment. When
                        complete, you’ll be redirected to another screen where you can export the file and investigate
                        further. For any issues with mismatching data, contact Paychex at <a class="link mailto"
                            href="mailto: {{helpSupportUrl}}"> {{helpSupportUrl}}</a>.
                    </div>
                </div>
                <div class="label-and-dropdown-button-container">
                    <label
                        class="view-table-label"
                        [class.view-table-label-disabled]="formGroup.get('auditType')?.disabled">
                        Audit Type <span class="field-required">*</span>
                    </label>
                    <div class="dropdown-container">
                        <mat-form-field class="type-select-field spinner-container-offset">
                            <mat-select
                                formControlName="auditType"
                                (selectionChange)="onAuditTypeChange($event.value)"
                                placeholder="Select One">
                            <mat-option *ngFor="let option of auditTypes" [value]="option">
                                {{option.auditType}}
                            </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <label
                        class="view-table-label">
                        Match Type <span class="field-required">*</span>
                    </label>
                    <div class="match-type-container">
                        <mat-form-field class="type-select-field">
                            <mat-select
                                formControlName="matchType"
                                [disabled]="!matchTypes || matchTypeLoading"
                                placeholder="Select One">
                                <mat-option *ngFor="let option of matchTypes" [value]="option.value">
                                    {{option.label}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                        <div class="spinner-container">
                            <mat-spinner class="match-type-spinner" [diameter]="20" *ngIf="matchTypeLoading"></mat-spinner>
                        </div>
                    </div>
                </div>
            </mat-card-content>
            <mat-card-actions>
                <div class="flex-start">
                    <button mat-flat-button color="primary" id="save-btn" type="submit"
                        [disabled]="formGroup.invalid">
                        Run Audit
                    </button>
                    <mat-spinner class="mdc-card__actions-spinner" [diameter]="20" id="save-spinner" *ngIf="this.submittingForm"></mat-spinner>
                </div>
            </mat-card-actions>
        </form>
    </mat-card>
    <mat-spinner class="loading-spinner" *ngIf="loading" [diameter]="60"></mat-spinner>
</div>