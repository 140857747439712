import { EventEmitter, Injectable } from "@angular/core";
import { MipHttpService } from "./api.mipHttpService";
import { CognitoService } from "./cognito.service";
import { HttpClient } from "@angular/common/http";
import { Observable, tap } from "rxjs";
import { emailSettings } from "../Pages/email-notification-settings/models/emailSettings.model";
import { EmailSettingsUpdate } from "./models/emailSettingsUpdate.model";

@Injectable({
    providedIn: 'root',
})
export class EmailNotificationService extends MipHttpService {

    private emailSettingsEventEmitter: EventEmitter<EmailSettingsUpdate>;
    public readonly emailSettingsUpdate: Observable<EmailSettingsUpdate>;

    constructor(cognitoService: CognitoService, httpClient: HttpClient) {
        super(cognitoService, httpClient);
        this.emailSettingsEventEmitter = new EventEmitter<EmailSettingsUpdate>();
        this.emailSettingsUpdate = this.emailSettingsEventEmitter.asObservable();
    }

    public getEmailNotificationSettings(category: string): Observable<emailSettings> {
        return this.get<emailSettings>(`notifications/settings/${category}`);
    }

    public postEmailNotificationSettings(category: string, emailSettingsRequest: emailSettings): Observable<void> {
        return this.put<void>(`notifications/settings/${category}`, JSON.stringify(emailSettingsRequest)).pipe(
            tap(() => {
                this.emailSettingsEventEmitter.emit({
                    category: category,
                    emailSettings: emailSettingsRequest
                })
            })
        );
    }

    public postGenerateNewNotificationSeed(category: string): Observable<void> {
        return this.post<void>(`notifications/settings/${category}/seed`, null);
    }
}