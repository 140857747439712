import { Component, EventEmitter, Inject, Output } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MAT_DIALOG_DATA, MatDialogActions, MatDialogContent, MatDialogModule, MatDialogRef, MatDialog, MatDialogConfig, MatDialogTitle } from '@angular/material/dialog';


@Component({
    selector: 'app-confirmation-dialog',
    standalone: true,
    imports: [MatDialogActions, MatDialogContent, MatDialogModule, MatButtonModule, MatDialogTitle],
    templateUrl: './confirmation-dialog.component.html',
    styleUrl: './confirmation-dialog.component.scss'
})
export class ConfirmationDialogComponent {
    title: string = "Confirm";
    text: string = "Do you want to proceed?";
    subtitle: string | null;
    okText: string = "Ok";
    cancelText: string = "Cancel";

    @Output() acceptEvent = new EventEmitter<boolean>();

    constructor(
        public dialogRef: MatDialogRef<ConfirmationDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: DialogData
    ) {
        var width = '250px';
        var height = '250px';

        if (data) {
            this.title = data.title ?? this.title;
            this.subtitle = data.subtitle ?? this.subtitle;
            this.text = data.text ?? this.text;
            this.okText = data.okText ?? this.okText;
            this.cancelText = data.cancelText ?? this.cancelText;
            width = data.width ?? width;
            height = data.height ?? height;
        }

        dialogRef.updateSize(width, height);
    }

    accept(): void {
        this.acceptEvent.emit(true);
        this.dialogRef.close(true);
    }
}

export function openDialog(dialog: MatDialog, data?: DialogData): MatDialogRef<ConfirmationDialogComponent> {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.width = '250px';
    dialogConfig.height = '250px';

    const ref = dialog.open(ConfirmationDialogComponent, {
        data: data,
    });

    return ref;
}

interface DialogData {
    title: string;
    subtitle?: string;
    text: string;
    okText?: string;
    cancelText?: string;
    width?: string;
    height?: string;
}