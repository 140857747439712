<div class="bulk-upload dialog-container-common">
    <mat-card-header>
        <mat-card-title class="dialog-title-common">Bulk Upload</mat-card-title>
    </mat-card-header>
    
    <mat-dialog-content class="dialog-content-common">
        <div *ngIf="canSelectFile() && !selectedFile && !activeJob">
            <div class="upload-icon">
                <img src="assets/images/BLANK-Transfer.svg" alt="File Selection Icon"/>
            </div>

            <p class="no-file-selected">
                No File Selected
            </p>
            <p class="upload-status-message">
                Once you start the upload, the Job ID and status will display here.
            </p>
        </div>

        <div *ngIf="canUpload() && !activeJob">
            <div class="upload-icon">
                <img src="assets/images/BLANK-StartUpload.svg" alt="Start Upload Icon"/>
            </div>

            <p class="no-file-selected">
                Start Upload
            </p>
            <p class="upload-status-message">
                Make sure the selected file is correct. You won't be able to cancel once it starts.
            </p>
        </div>

        <div *ngIf="activeJob">
            <div class="status-icon-container">
                <span *ngIf="isJobActive()"> <mat-spinner [diameter]="90"></mat-spinner></span>
                <span *ngIf="!isJobActive()">
                    <img
                        class="icon-display"
                        [src]="getIconSrc(activeJob)"
                        [matTooltip]="getIconFont(activeJob)"
                        [alt]="getIconFont(activeJob)"
                    />
                </span>  

            </div>
            <div class="status-text-container">
               <span class="title"> Status: </span>
               <span class="value">{{activeJob.jobStatus ?? "N/A"}}</span>
            </div>
            <div class="job-id-text-container">
                <span class="title">Job ID: </span>
                <span class="value">{{activeJob.jobId ?? "N/A"}}</span>
            </div>
        </div>

        <div class="file-upload-container">
            <div class="file-select-container">
                <input type="file" class="file-input" (change)="onFileSelected($event)" #fileUpload id="fileInput">
                <button mat-flat-button class="select-file-button" [disabled]="!canSelectFile()" (click)="fileUpload.click()">
                    Select File
                </button>
                <span class="file-name-text" *ngIf="fileName">{{ fileName }}</span>
            </div>
            <button mat-flat-button class="select-file-button" [disabled]="!canUpload()" (click)="uploadFile()">
                Upload
            </button>
        </div>
        
    </mat-dialog-content>
  
    <hr class="footer-divider" />
  
    <mat-dialog-actions class="dialog-actions-common">
        <button class="close-button" mat-button [disabled]="!canCancel()" (click)="cancel()">Close</button>
    </mat-dialog-actions>
</div>
  