import { Component } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { MatTabsModule } from '@angular/material/tabs';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { CommonModule } from '@angular/common';
import { IntegrationComponent } from '../integration/integration.component';
import { tenantFlow, tenantFlowOffering } from './models/offering.model';
import { OfferingApiService } from '../../Services/api.offering.service';
import { TenantOfferingLinkGetRequest } from '../../Models/Class-Interfaces/Offerings/TenantOfferingLinkGetRequest.model';
import { TenantOfferingFlowLinkListRequest } from '../../Models/Class-Interfaces/Offerings/TenantOfferingFlowLinkListRequest.model';
import { MatOption, MatSelectModule } from '@angular/material/select';
import { FormsModule } from '@angular/forms';
import { CompanyIconComponent } from '../../Shared/company-icon/company-icon.component';
import { RouteContextService } from '../../Services/route-context.service';

@Component({
  selector: 'app-offering',
  standalone: true,
  imports: [
    CommonModule,
    CompanyIconComponent,
    FormsModule,
    MatOption,
    MatProgressSpinnerModule,
    MatTabsModule,
    MatSelectModule,
    MatSlideToggleModule,
    IntegrationComponent,
  ],
  templateUrl: './offering.component.html',
  styleUrl: './offering.component.scss'
})
export class OfferingComponent {
  offeringCode: string;
  flowCode: string | null;
  offeringLoading: boolean = true;
  offering: tenantFlowOffering | null = null;
  offeringFlowsLoading: boolean = true;
  offeringFlows: tenantFlow[] | null = null;
  offeringFlowSelected?: tenantFlow;

  loading: boolean = false;

  constructor(
    private routeContext: RouteContextService,
    private offeringApiService: OfferingApiService,
    private router: Router,) {
  }

  ngOnInit() {
    this.routeContext.params$.subscribe(params => {
      var offeringCode = params['offeringCode'];
      this.onOfferingCodeChange(offeringCode);

      var flowCode = params['flowCode'];
      this.onFlowCodeChange(flowCode);

    });

  }

  onOfferingCodeChange(offeringCode: string) {
    if (offeringCode === this.offeringCode) {
      console.debug(`Same offering code '${offeringCode}', skipping update`);
      return;
    }

    console.debug(`Offering Flow Code '${this.offeringCode}' -> '${offeringCode}'`)
    this.offeringCode = offeringCode;
    this.loadOffering();
    this.loadOfferingFlows();
  }

  onFlowCodeChange(flowCode: string | null) {
    if (flowCode === this.flowCode) {
      console.debug(`Same flow code '${flowCode}', skipping update`);
      return;
    }

    console.debug(`Offering Flow Code '${this.flowCode}' -> '${flowCode}'`)
    this.flowCode = flowCode;
    this.updateSelectedFlow();
  }

  loadOffering() {
    this.offeringLoading = true;
    this.offering = null;
    if (!this.offeringCode) {
      return;
    }
    var request: TenantOfferingLinkGetRequest = {
      offeringCode: this.offeringCode,
    };
    this.offeringApiService.getTenantOfferingLink(request)
      .subscribe({
        next: (result) => {
          this.offering = {
            offeringCode: result.offeringCode,
            offeringName: result.offeringName,
            offeringDescription: result.offeringDescription,
            offeringPrice: result.offeringPrice,
          }
          this.offeringLoading = false;
        },
        error: (error: any) => {
          this.offering = null;
          this.offeringLoading = false;
          console.error(`Error fetching Tenant Offering Link '${this.offeringCode}': ${error}`)
        }
      });
  }

  loadOfferingFlows() {
    this.offeringFlowsLoading = true;
    this.offeringFlows = null;
    if (!this.offeringCode) {
      return;
    }
    var request: TenantOfferingFlowLinkListRequest = {
      OfferingCode: this.offeringCode,
    };
    this.offeringApiService.listTenantOfferingFlowLink(request)
      .subscribe({
        next: (result) => {
          this.offeringFlows = result.map((flow) => {
            return {
              flowCode: flow.flowCode,
              flowName: flow.flowName,
              flowType: flow.flowType,
            }
          }).sort((x, y) => x.flowName.localeCompare(y.flowName) );
          this.updateSelectedFlow();

        },
        error: (error: any) => {
          this.offeringFlows = null;
          this.offeringFlowsLoading = false;
          console.error(`Error fetching Tenant Offering Flow Links '${this.offeringCode}': ${error}`)
        }
      });
  }

  updateSelectedFlow() {
    this.offeringFlowSelected = this.offeringFlows?.find(x => x.flowCode === this.flowCode);
    this.offeringFlowsLoading = false;
    if (!this.offeringFlowSelected) {
      this.loadDefaultFlow();
    }
  }

  loadDefaultFlow() {
    if (this.flowCode) {
      // Flow Code already set, skip loading default.
      return;
    }
    if (!this.offeringFlows || this.offeringFlows.length === 0) {
      // No Flows, skip loading default.
      return;
    }

    this.offeringFlowSelected = this.offeringFlows[0];
    this.changeFlowToSelected();
  }

  changeFlowToSelected() : void {
    if(this.offeringFlowSelected) {
      this.flowCode = this.offeringFlowSelected.flowCode;
      this.router.navigate(['/offering', this.offeringCode, 'integration', this.offeringFlowSelected.flowCode]);
    }
    else {
      this.flowCode = null;
      this.router.navigate(['/offering', this.offeringCode]);
    }
  }

}