import { Component, EventEmitter, Inject, Output } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MAT_DIALOG_DATA, MatDialogActions, MatDialogContent, MatDialogModule, MatDialogRef, MatDialog, MatDialogConfig, MatDialogTitle } from '@angular/material/dialog';


@Component({
    selector: 'app-confirmation-dialog',
    standalone: true,
    imports: [MatDialogActions, MatDialogContent, MatDialogModule, MatButtonModule, MatDialogTitle],
    templateUrl: './confirmation-dialog.component.html',
    styleUrl: './confirmation-dialog.component.scss'
})
export class ConfirmationDialogComponent {
    title: string = "Confirm";
    text: string = "Do you want to proceed?";

    @Output() acceptEvent = new EventEmitter<boolean>();

    constructor(
        public dialogRef: MatDialogRef<ConfirmationDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: { title: string, text: string }
    ) {
        dialogRef.updateSize('250px');
        if (data) {
            this.title = data.title ?? this.title;
            this.text = data.text ?? this.text;
        }
    }

    accept(): void {
        this.acceptEvent.emit(true);
        this.dialogRef.close(true);
    }
}

export function openDialog(dialog: MatDialog, data?: { title: string, text: string }): MatDialogRef<ConfirmationDialogComponent> {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.width = '250px';
    dialogConfig.height = '250px';

    const ref = dialog.open(ConfirmationDialogComponent, {
        data: data,
    });

    return ref;
}
