import { Component, Input, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';

import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatIconModule } from '@angular/material/icon';
import { Observable, Subscription, interval, map, timer } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { MatCardModule } from '@angular/material/card';
import { TenantAPIService } from '../../../Services/api.tenantApi.service';
import { FormsModule } from '@angular/forms';
import { exportResult, job } from '../models/job.model';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatPaginator, MatPaginatorModule, PageEvent } from '@angular/material/paginator';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { ExportableFilesComponent } from '../../../Shared/exportable-files/exportable-files.component';

@Component({
  selector: 'app-job-info-card',
  standalone: true,
  imports: [CommonModule, MatProgressSpinnerModule, MatIconModule, FormsModule, MatCardModule, MatTooltipModule, MatTableModule, MatPaginatorModule, ExportableFilesComponent],
  templateUrl: './job-info-card.component.html',
  styleUrl: './job-info-card.component.scss'
})
export class JobInfoCardComponent {

  @Input("noItemSelectedText") noItemSelectedText: string = "No Job Selected";
  @Input("noItemSelectedMessage") noItemSelectedMessage: string = "Once you start the job, the Job ID and status will display here";
  @Input("jobId") jobId: string = "";
  @Input("pollForActivity") pollActivity: boolean = false;
  @Input("showExportGrid") showExportGrid: boolean = true;
  @Input("activeJobEvent") activeJobEvent: Observable<string> = null!;

  activeJob: job | null = null;

  timer: Subscription = new Subscription();
  loaded: boolean = false;

  constructor(private jobsAPI: TenantAPIService) {
  }

  pollForJobStatus = (id: string): void => {

    if (!this.jobId || !this.activeJob || (this.activeJob.jobStatus != "New" && this.activeJob.jobStatus != "Active")) {
      this.timer.unsubscribe();
    }
    else {

      this.getJobById(this.jobId);
    }
  }

  ngOnInit() {
    this.activeJobEvent.subscribe((id) => id && this.beginGetJobId(id));
  }
  ngOnDestroy() {
    this.timer.unsubscribe();
  }
  
  beginGetJobId = (id: string) => {
    this.jobId = id;
    this.getJobById(id);
    if (this.pollActivity) {
      this.timer = interval(15000).pipe(takeUntil(timer(300000))).subscribe((x => {
        this.pollForJobStatus(this.jobId ?? "-1");
      }));
    }
  }

  getJobById = (id: string): void => {
    this.loaded = false;
    this.jobsAPI.getJobById(id).subscribe({
      next: (data: job) => {
        this.activeJob = data;

      },
      error: (result: any) => { console.log(result) },
      complete: () => this.loaded = true
    });
  }

  getIconFont = (job?: job): string => {
    switch (job?.jobStatus) {
      case "New":
      case "Active":
        return 'progress_activity';
      case "Complete":
        return 'check_circle'
      case "Error":
        return 'error'
      default:
        return 'pause'
    }
  }
  getIconClass = (job?: job): string => {
    switch (job?.jobStatus) {
      case "New":
      case "Active":
        return 'icon-color-primary';
      case "Complete":
        return 'icon-color-success'
      case "Error":
        return 'icon-color-error'
      default:
        return 'icon-color-primary';
    }
  }

  isJobActive(job?:job):boolean{
    return job != null && (job.jobStatus === "New" || job.jobStatus === "Active" )
  }
}
