import { Component, Inject } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MAT_SNACK_BAR_DATA, MatSnackBarRef } from '@angular/material/snack-bar';
import { MIPNotificationType } from '../Notifications/mip-notification-type.enum';
import { CommonModule } from '@angular/common';
import { MIPNotification } from '../Notifications/notifications.service';
@Component({
  selector: 'app-custom-snackbar',
  imports: [MatIconModule, CommonModule],
  standalone: true,
  templateUrl: './custom-snackbar.component.html',
  styleUrl: './custom-snackbar.component.scss'
})
export class CustomSnackbarComponent {
  public MIPNotificationType = MIPNotificationType;
  constructor(@Inject(MAT_SNACK_BAR_DATA) public notificationData: MIPNotification, private snackBarRef: MatSnackBarRef<CustomSnackbarComponent>) {}

  public closeSnack(): void {
    this.snackBarRef.dismiss();
  }
}
